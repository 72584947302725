/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import DesktopHeader from "../desktopHeader";
import backArrow from "../../images/arrow-box.svg";
import axios from "axios";
import profile from "../../images/company.png";
import iMedal from "../../images/icons/medal-ribbon.svg";
import "./applicant.css";
import "./jobs.css";
import CloseIcon from "../../images/CloseIcon.svg";
import { IoIosArrowForward } from "react-icons/io";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MdOutlineLogout } from "react-icons/md";
import { BsCreditCard2Front } from "react-icons/bs";
import Common from "../../utils/common";
import { Amplify, Auth } from "aws-amplify";
import Spinner from "react-bootstrap/Spinner";
import star from "../../images/icons/star.svg";

const Button11 = styled.div`
  background-color: #362a41;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;
const Button10 = styled.div`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: #362a41;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: #362a41;
    color: white;
    border: 1px solid #362a41;
  }
`;
const Wrapper3 = styled.div`
  @media (max-width: 576px) {
    margin: 10px 0;
  }
  margin: 10px 0;
  padding: 5px;
`;
const QuestionWrapper = styled.div`
  display: flex;
  gap: 5px;
`;
const JobTitle = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: normal;
  color: #4f4953;
  margin: 5px 0px 0 0px;
  padding: 5px 0px;
  // margin-top: -10px;

  @media (max-width: 500px) {
    font-size: 14px;
    line-height: 22px;
    margin: -15px 0 0 0;
  }
`;

const JobTitles = styled.div`
  margin-left: 40px;
  color: #362a41;
  font-size: 14px;
  font-weight: normal;
  font-family: Inter-Medium, sans-serif;
  margin-top: 10px;
  margin-left: 36px;
`;

const Button3 = styled.div``;
const HeaderLayout = styled.div``;

const HeaderDownLayout = styled.div``;
const CenterLayout = styled.div``;
const DateWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
const Text = styled.p`
  font-family: Inter-Medium;
  font-size: 24px;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0em;
  position: relative;
  text-align: center;
`;
const Name = styled.div`
  margin-top: 13px;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #362a41;
`;

const Card = styled.div``;

// Create a styled component for the columns
const Column = styled.div``;
const NameImageContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img``;

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      //  redirectSignIn: "http://localhost:3000/",
      //     redirectSignOut: "http://localhost:3000/",,
      redirectSignIn: "https://qa.thexakal.com/login",
      redirectSignOut: "https://qa.thexakal.com/login",

      responseType: "token",
    },
  },
});
const Applicants = () => {
  const [user, setUsers] = useState("");
  const [success, setSuccess] = useState(false);
  const logout = () => {
    Common.logout();
  };
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        setUsers(null);
      });
  }, []);
  function onSubmit() {
    setSuccess(true);
  }
  const history = useHistory();
  const { id } = useParams();

  const [input, setInput] = useState({});
  const [input2, setInput2] = useState([]);
  const [loading, setLoading] = useState(false);
  const updateOne = (id) => {
    setLoading(true);
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-view-applicant/${id}`)
      .then((response) => {
        if (response.data.success) {
          setInput(response.data.updateJob);
          const questionList = response.data.updateJob.questionList;

          const filteredQuestions = questionList.filter(
            (question) =>
              (question.isStarred && !question.isMandatory) ||
              (question.isStarred && question.isMandatory)
          );

          // Sort the filtered questions with starred questions first
          const sortedQuestionList = filteredQuestions.sort((a, b) => {
            return b.isStarred - a.isStarred;
          });

          setInput2(sortedQuestionList);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    updateOne(id);
  }, [id]);
  const [open4, setOpen4] = useState(false);
  const [userForStarredQuestions, setUserForStarredQuestions] = useState(null);
  const handleViewStarredQuestions = (user) => {
    setUserForStarredQuestions(user);
    setOpen4(true);
  };
  const [openSetting, setOpenSetting] = useState(false);
  const handleInputChange = (event) => {};
  const searchedKey = () => {};
  return (
    <div>
      <HeaderLayout className="header-layout">
        {/* Start of Mobile Header  */}
        <div className="sub-header o-mobile p-3">
          <div
            className="arrow-box"
            onClick={() => history.push("/portal/jobs")}
          >
            <img src={backArrow} className="back-arrow-img" alt="XAKAL" />
          </div>
          <h4 className="textpass font-face-gm mb-0">Active Jobs</h4>
        </div>
        {/* End of Mobile Header  */}
        {/* Start of desktop Header  */}
        <DesktopHeader
          title={"Manage Jobs"}
          searchedKey={searchedKey}
          onFieldChange={handleInputChange}
        />
        {/* End of desktop Header  */}
        <HeaderDownLayout className="o-desk">
          <ul className="breadcrumbs">
            <li>
              <a
                onClick={() => history.push("/portal/manage-jobs")}
                style={{ cursor: "pointer" }}
              >
                Manage Job
              </a>
            </li>
            <li>
              <IoIosArrowForward />
            </li>
            <li style={{ cursor: "pointer" }}>View Applicants</li>
          </ul>
        </HeaderDownLayout>
      </HeaderLayout>
      <div className="in-content-wrapper">
        <CenterLayout className="ap-cl">
          {loading ? (
            <Spinner
              style={{
                display: "flex",
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            />
          ) : (
            input &&
            input.applicants &&
            input.applicants.map((i) => {
              return (
                <Card className="ap-card">
                  <Column className="ap-image">
                    <NameImageContainer>
                      {i.avatar && i.avatar ? (
                        <Image
                          src={i.avatar}
                          alt="Image Alt Text"
                          className="profile-pic"
                        />
                      ) : (
                        <Image
                          src={profile}
                          alt="Image Alt Text"
                          className="profile-pic"
                        />
                      )}
                      {i.firstName && i.lastName ? (
                        <div>
                          <div className="star-main">
                            <Name className="name">{`${i.firstName} ${i.lastName}`}</Name>
                            {i?.aboutId?.totalAverageRating !== undefined &&
                            i?.aboutId?.totalAverageRating !== null &&
                            i?.aboutId?.totalAverageRating !== 0 &&
                            i?.aboutId?.totalAverageRating !== 0.5 ? (
                              <div>
                                <img
                                  src={star}
                                  className="star-people"
                                  alt=""
                                />
                                <span className="star-average">
                                  {i?.aboutId.totalAverageRating.toFixed(1)}
                                </span>
                              </div>
                            ) : null}
                          </div>
                          {i &&
                          i.experienceIds &&
                          i.experienceIds.length > 0 ? (
                            i &&
                            i.experienceIds
                              .filter(
                                (exp) =>
                                  !exp.endDate && exp.timestamps.createdAt
                              )
                              .sort(
                                (exp1, exp2) =>
                                  new Date(exp2.timestamps.createdAt) -
                                  new Date(exp1.timestamps.createdAt)
                              )
                              .slice(0, 1)
                              .map((a) => (
                                <p className="ap-pos">{a.designation}</p>
                              ))
                          ) : (
                            <p className="ap-pos">-</p>
                          )}
                        </div>
                      ) : (
                        <Name className="name">No Name is Provided</Name>
                      )}
                    </NameImageContainer>
                  </Column>
                  <Column className="ap-deg">
                    {i && i.educationIds && i.educationIds.length > 0 ? (
                      i &&
                      i.educationIds
                        .filter((exp) => exp.timestamps.createdAt)
                        .sort(
                          (exp1, exp2) =>
                            new Date(exp2.timestamps.createdAt) -
                            new Date(exp1.timestamps.createdAt)
                        )
                        .slice(0, 1)
                        .map((a) => (
                          <div>
                            <Name style={{ marginTop: "0px" }}>{a.degree}</Name>
                            <p className="ap-pos">{a.specialization}</p>
                          </div>
                        ))
                    ) : (
                      <Name style={{ marginTop: "0px" }} className="deg">
                        No Degree Provided
                      </Name>
                    )}
                  </Column>

                  <Column className="ap-ques">
                    <Name
                      style={{ cursor: "pointer" }}
                      onClick={() => handleViewStarredQuestions(i)}
                    >
                      <img src={iMedal} alt="" />
                      <span className="t-ques"> View Starred Question</span>
                    </Name>
                  </Column>
                  <Column className="ap-applicant">
                    <Button3
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        history.push(
                          `/portal/applicant-details/${i.userId}/${id}`
                        )
                      }
                      className="btn btn-outline"
                    >
                      Applicant Details
                    </Button3>
                  </Column>
                </Card>
              );
            })
          )}
        </CenterLayout>
      </div>
      <Dialog
        open={open4}
        PaperProps={{
          style: {
            borderRadius: "20px",
            width: "427px",
            height: "auto",
            maxWidth: "430px",
          },
        }}
      >
        <div
          className="modalHeader"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
            gap: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            {userForStarredQuestions?.aboutId?.avatar ? (
              <div>
                <img
                  src={userForStarredQuestions?.aboutId.avatar}
                  alt="uiio"
                  style={{
                    width: "50px",
                    height: "48px",
                    borderRadius: "30px",
                  }}
                />
              </div>
            ) : (
              <div>
                <img
                  src={profile}
                  alt="uiio"
                  style={{
                    width: "50px",
                    height: "48px",
                    borderRadius: "30px",
                  }}
                />
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "70%",
              }}
              className="vp-m-header"
            >
              <div className="vp-1">
                <Name
                  style={{ marginLeft: "0px" }}
                >{`${userForStarredQuestions?.firstName} ${userForStarredQuestions?.lastName}`}</Name>
                <div>
                  {userForStarredQuestions &&
                  userForStarredQuestions.experienceIds &&
                  userForStarredQuestions.experienceIds.length > 0 ? (
                    userForStarredQuestions &&
                    userForStarredQuestions.experienceIds
                      .filter((exp) => !exp.endDate && exp.timestamps.createdAt)
                      .sort(
                        (exp1, exp2) =>
                          new Date(exp2.timestamps.createdAt) -
                          new Date(exp1.timestamps.createdAt)
                      )
                      .slice(0, 1)
                      .map((a) => <p className="ap-pos">{a.designation}</p>)
                  ) : (
                    <p className="ap-pos"></p>
                  )}
                </div>
              </div>
              <div className="vp-2">
                {userForStarredQuestions &&
                userForStarredQuestions.educationIds &&
                userForStarredQuestions.educationIds.length > 0 ? (
                  userForStarredQuestions &&
                  userForStarredQuestions.educationIds
                    .filter((exp) => exp.timestamps.createdAt)
                    .sort(
                      (exp1, exp2) =>
                        new Date(exp2.timestamps.createdAt) -
                        new Date(exp1.timestamps.createdAt)
                    )
                    .slice(0, 1)
                    .map((a) => (
                      <div>
                        <Name className="deg">{a.degree}</Name>
                        <p className="ap-pos">{a.specialization}</p>
                      </div>
                    ))
                ) : (
                  <Name className="deg"></Name>
                )}
              </div>
            </div>
          </div>

          {/* <AiOutlineCloseCircle onClick={() => setOpen4(false)} /> */}
          <img src={CloseIcon} alt="img" onClick={() => setOpen4(false)} />
        </div>
        <DialogContent className="jp-diag-body">
          <div
            className="qa-holder"
            style={{
              position: "relative",
              background: "#f5f5f5",
              borderRadius: "16px",
              padding: "4px",
              // width: "420px",
              margin: "20px",
              // height: "50px",
            }}
          >
            {input2 &&
              input2.map((question, index) => {
                const userAnswers = question.answers.filter(
                  (answer) => answer.userId === userForStarredQuestions?.userId
                );

                // Check if there are any user answers
                if (userAnswers.length > 0) {
                  const lastUserAnswer = userAnswers[userAnswers.length - 1];

                  return (
                    <Wrapper3 key={index}>
                      <QuestionWrapper>
                        <JobTitle
                          style={{ marginLeft: "20px" }}
                          className="apd-ques"
                        >
                          {`${index + 1}.`}
                        </JobTitle>
                        <JobTitle> {`${question.questionName}`}</JobTitle>
                      </QuestionWrapper>
                      <div>
                        <JobTitles className="apd-ans">{` ${
                          lastUserAnswer.answer || "No answer provided"
                        }`}</JobTitles>
                      </div>
                    </Wrapper3>
                  );
                }
              })}
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSetting}
        PaperProps={{
          style: {
            width: "347px",
            height: "200px",
            top: "50px",
            right: "35px",
            borderRadius: "24px",
            position: "fixed",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
            position: "relative",
          }}
        >
          <span
            style={{
              width: "347px",
              height: "50px",
              padding: "19px, 16px, 19px, 25px",
              border: "0px, 0px, 1px, 0px",
              borderBottom: "1px solid #F4F4F6",
            }}
          >
            Setting
          </span>
          <AiOutlineCloseCircle onClick={() => setOpenSetting(false)} />
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <BsCreditCard2Front size={20} />
            <p onClick={() => history.push("/portal/changepassword")}>
              Change Password
            </p>
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            {/* <CiUser size={20} />
            <p onClick={() => history.push("/portal/my-profile")}>
              My Profile
            </p> */}
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium",
              gap: "10px",
              cursor: "pointer",
            }}
            onClick={onSubmit}
          >
            <MdOutlineLogout size={20} style={{ color: "#FF5252" }} />
            <p style={{ color: "#FF5252" }}> Logout</p>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        ></DialogTitle>
        <DialogContent>
          <Text>Are you sure you want to logout?</Text>

          <DateWrapperButton>
            <Button10 onClick={() => setSuccess(false)}>No</Button10>
            {user ? (
              <Button11
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </Button11>
            ) : (
              <Button11 onClick={logout}>Yes</Button11>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Applicants;
