import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import "./promo.css";
import Select from "react-select";

const EditPromo = ({ getAll, promoId, close }) => {
  const [codeName, setCodeName] = useState("");
  const [percentage, setPercentage] = useState("");
  const [userArray, setUserArray] = useState([]);
  const [userSelect, setUserSelect] = useState([]);
  const [id, setId] = useState();
  const [loading, setLoading] = useState(false);

  const updatePromo = () => {
    debugger
    const userIds = userSelect.map((user) => user.value);
    const userIdSelect = userSelect.map((user) => ({
      value: user.value,
      label: user.label,
    }));
    const payload = {
      codeName: codeName,
      percentage: percentage,
      applicableUser: userIds,
      userDetail: userIdSelect,
    };
    setLoading(true);
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/update-promo/${id}`, payload)
      .then(() => {
        setCodeName("");
        setPercentage("");
        setUserArray([""]);
        getAll();
        close();
        toast.success("Promo code update successfully");
        setLoading(false);
      })
      .catch(() => {});
  };
  const getOnePromo = (promoId) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/find-one-promo/${promoId}`)
      .then((response) => {
        setId(response.data.promo._id);
        setCodeName(response.data.promo.codeName);
        setPercentage(response.data.promo.percentage);
        const userIds = response.data.promo.userDetail.map((user) => ({
          value: user.value,
          label: user.label,
        }));
        setUserSelect(userIds || []);
      })
      .catch(() => {});
  };
  useEffect(() => {
    getOnePromo(promoId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-all-user-list`)
      .then((res) => {
        const userData = res.data.user.map((user) => ({
          value: user.userId,
          label: `${user.firstName} ${user.lastName}`,
        }));
        setUserArray(userData);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <div>
      <div className="modalBody" style={{ padding: "20px 0px" }}>
        <div>
          <label className="label-name">
            Code Name<span>*</span>
          </label>
          <InputGroup className="footer-header">
            <Form.Control
              type="text"
              name="codeName"
              onChange={(e) => setCodeName(e.target.value)}
              value={codeName}
              aria-describedby="basic-addon1"
              style={{
                width: "50%",
                fontSize: "12px",
              }}
              className="footer-header"
            />
          </InputGroup>
        </div>
        <div>
          <label className="label-name">
            percentage<span>*</span>
          </label>
          <InputGroup className="footer-header">
            <Form.Control
              type="text"
              name="percentage"
              onChange={(e) => setPercentage(e.target.value)}
              value={percentage}
              aria-describedby="basic-addon1"
              style={{
                width: "50%",
                fontSize: "12px",
              }}
              className="footer-header"
            />
          </InputGroup>
        </div>
        <label className="label-name">User</label>
        <div className="user-input-container">
          <div className="user-input-container">
            <Select
              options={userArray}
              isMulti
              isClearable
              value={userSelect}
              onChange={(selectedOptions) => setUserSelect(selectedOptions)}
            />
          </div>
        </div>
        <div className="open-button">
          <button className="button5" onClick={close}>
            Cancel
          </button>

          <button className="button3" onClick={updatePromo}>
            {loading ? <Spinner /> : "Update"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditPromo;
