import React, { useEffect, useState, useCallback, useRef } from "react";
import Form from "react-bootstrap/Form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import InputGroup from "react-bootstrap/InputGroup";
import axios from "axios";
import { components } from "react-select";
import Common from "../../utils/common";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";
import { getYear } from "date-fns";
import CreatableSelect from "react-select/creatable";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import CloseIcon from "../../images/CloseIcon.svg";
import DeleteImg from "../../images/solar_shield-warning-bold-duotone.svg";

const JobTitle = styled.div`
  width: auto;
  // height: 22px;
  top: 24px;
  left: 0px;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #40444f;
  margin-left: 10px;

  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 16px;
    // margin: -15px 0 0 0;
  }
`;
const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;

  @media (max-width: 600px) {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    gap: 4px;
  }
`;
const Button4 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: Inter-Medium, sans-serif;
  color: #2d3139;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
  font-weight: normal;
  font-size: 14px;
  color: #2d3139;
  border: 1px solid #1213171a;
  padding: 12px;
  width: 94px;
  height: 40px;
  // position: relative;
  // top: 3px;
  cursor: pointer;
  @media (max-width: 576px) {
    width: 65px;
    height: 30px;
    padding: 6px;
    font-size: 12px;
  }
`;
const Button6 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fd2145;
  // position: relative;
  // top: 3px;
  font-family: Inter-Medium, sans-serif;
  border-radius: 10px;
  font-weight: normal;
  font-size: 14px;
  color: white;
  padding: 12px 16px;

  cursor: pointer;
  @media (max-width: 576px) {
    width: 85px;
    height: 30px;
    padding: 6px;
    font-size: 12px;
  }
`;
const customStyle = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    height: "48px",
    border: "none",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    fontFamily: "Inter-Medium,sans-serif",
    color: "#4F4953",
    fontweight: "normal",
  }),
  menu: (provided) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    "&:hover": {
      color: "#362A41", // Add hover text color
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 6px)",
      marginLeft: "5px",
    },
  }),
  // Add more styles as needed
};
const Wrapper = styled.div`
  width: 50%;
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const ProjectModal = ({ onClose, proId }) => {
  const [project, setProject] = useState({
    projectTitle: "",
    description: "",
    // startDate: "",
    // endDate: "",
  });
  const [tool, setTool] = useState([]);
  const [skill, setSkill] = useState([]);
  console.log(skill.length);
  const [closemodal, setCloseModal] = useState(false);
  const [popupopen, setPopupOpen] = useState(false);
  // handle onChange event of the dropdown
  const handleToolChange = (e) => {
    const formattedSkills = e.map((e) => ({
      skillIds: e.skillIds,
      label: e.label,
      value: e.value,
    }));
    setTool(formattedSkills);
    setDirty(true);
  };
  const handleSkillChnage = (selectedOptions) => {
    const formattedSkills = selectedOptions.map((e) => ({
      skillIds: e.skillIds,
      label: e.label,
      value: e.value,
      ratings: e.ratings,
      averageSkillRating: e.averageSkillRating,
      candidateId: Common.loggedIn(),
    }));

    setSkill(formattedSkills);
    setSkillCreate("");
    setOptionsOne([]);
    setDirty(true); // Not sure where setDirty comes from in your code
  };

  const [error2, setError2] = useState({
    projectTitle: "",

    description: "",
  });
  const [dirty, setDirty] = useState(false);
  const [textAreaCount, setTextAreaCount] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [textProjectArea, setTextProjectArea] = useState(0);
  const handleChange = (event) => {
    const { name, value } = event.target;

    const truncatedValue = value.slice(0, 250);

    setProject((prevProps) => ({
      ...prevProps,
      [name]: truncatedValue,
    }));

    setDirty(true);
    setTextProjectArea(truncatedValue.length);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError2((prev) => {
      const stateObj = { ...prev, [name]: "" };
      console.log(project.projectTitle);
      switch (name) {
        case "projectTitle":
          // const regex1 = /^[a-zA-Z\s]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter the project title";
          }
          break;

        case "description":
          if (value === "") {
            stateObj[name] = "Please enter the project description";
          }

          break;

        case "startDate":
          if (value === "") {
            stateObj[name] = "Please enter the start date";
          }
          break;

        case "endDate":
          if (project.endDate < project.startDate) {
            stateObj[name] = "End date can't be earlier than start date";
          }
          break;
        case "skill":
          if (skill.length > 100) {
            stateObj[name] = "Skill can be added upto 100";
          }
          break;
        default:
          break;
      }

      console.log(stateObj[name]);

      return stateObj;
    });
  };

  const prefillValue = (id) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-project-detail/${id}`)
      .then((response) => {
        console.log(response, "response");
        if (response && response.data && response.data.success) {
          if (
            response.data.projectOne.skill &&
            response.data.projectOne.skill.length > 0
          ) {
            setSkill(response.data.projectOne.skill);
          }
          if (
            response.data.projectOne.tool &&
            response.data.projectOne.tool.length > 0
          ) {
            setTool(response.data.projectOne.tool);
          }
          setProject({
            ...project,
            id: response.data.projectOne._id,
            userId: response.data.projectOne.userId,
            projectTitle: response.data.projectOne.projectTitle,
            startDate: response.data.projectOne.startDate,
            endDate: response.data.projectOne.endDate,
            tool: response.data.projectOne.tool,
            skill: response.data.projectOne.skill,
            description: response.data.projectOne.description,
          });
        }
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    if (proId) {
      prefillValue(proId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proId]);

  const [loading, setIsLoading] = useState(false);

  const projectCreate = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const errors2 = {};

    if (!project.projectTitle)
      errors2.projectTitle = "Please enter the project title";
    if (!project.description)
      errors2.description = "Please enter the project description";
    if (project.endDate <= project.startDate)
      errors2.endDate = "End Date Should be Greater than Start Date";
    if (Object.keys(errors2).length === 0) {
      try {
        const data = {
          description: project.description,
          projectTitle: project.projectTitle,
          startDate: project.startDate,
          endDate: project.endDate,
          tool: tool,
          skill: skill,
          deleteFlag: "N",
          userId: Common.loggedIn(),
        };

        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/project-create`,
          data
        );

        if (response.data.success) {
          console.log(response, "add form");
          setProject({
            ...project,
            projectTitle: "",
            startDate: "",
            description: "",
            endDate: "",
          });
          onClose();
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    } else {
      setError2(errors2);
      setIsLoading(false); // Set loading to false if there are errors
    }
  };
  const projectUpdate = async (e) => {
    debugger;
    e.preventDefault();
    setIsLoading(true);

    try {
      const updatedProject = {
        ...project,
        projectTitle: project.projectTitle,
        description: project.description,
        startDate: project.startDate,
        endDate: project.endDate,
        tool: tool,
        skill: skill,
        deleteFlag: "N",
        userId: Common.loggedIn(),
      };

      const errors2 = {};

      if (!updatedProject.projectTitle) {
        errors2.projectTitle = "Please enter the project title";
      }

      // Check if the description is empty
      const descriptionIsEmpty =
        !updatedProject.description ||
        !updatedProject.description.replace(/<\/?[^>]+(>|$)/g, "").trim();
      if (descriptionIsEmpty) {
        errors2.description = "Please enter the project description";
      }

      if (updatedProject.endDate <= updatedProject.startDate) {
        errors2.endDate = "End Date Should be Greater than Start Date";
      }

      if (Object.keys(errors2).length === 0) {
        const response = await axios.put(
          `${process.env.PUBLIC_URL}/xakal/update-project/${project.id}`,
          updatedProject
        );

        if (response.data.success) {
          console.log(response, "update successful");
          setProject({
            ...project,
            projectTitle: "",
            startDate: "",
            description: "",
            endDate: "",
          });
          onClose();
        }
      } else {
        setError2(errors2);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const DiscardChanges = () => {
    setProject({
      ...project,
      projectTitle: "",
      description: "",
      startDate: "",
      endDate: "",
    });

    setSkill("");
    setTool([...tool]);
    setSkill([...skill]);
    onClose();
  };
  const CloseModalClear = () => {
    if (!dirty) {
      onClose(); // No modifications, directly close the modal
    } else {
      setCloseModal(true); // Show the discard popup
      setPopupOpen(true);
    }
  };
  const quillRef = useRef();

  const handleJobDescChange = useCallback(
    (content, delta, source, editor) => {
      const value = editor.getText(); // Get the plain text content

      if (value.length <= 1000) {
        setProject({ ...project, description: content });
        setDirty(true);
        setTextAreaCount(value.length);
        // setError2({ ...error2, description: "" }); // Clear previous error
      } else {
        console.error("Input exceeds 1000 characters");
        setError2({
          ...error2,
          description: "Description exceeds 1000 characters",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project.description, error2]
  );

  const handleDescriptionBlur = () => {
    const trimmedDescription = project.description;

    if (trimmedDescription === "") {
      setError2({
        ...error2,
        description: "Please enter the project description",
      });
    } else {
      setError2({
        ...error2,
        description: "", // Clear the error if the description is not empty
      });
    }
  };

  useEffect(() => {
    const quill = quillRef.current.getEditor();
    if (quill) {
      const updateTextAreaCount = () => {
        setTextAreaCount(quill.getLength());
      };
      quill.on("text-change", updateTextAreaCount);
      return () => {
        quill.off("text-change", updateTextAreaCount);
      };
    }
  }, []);
  const [skillData, setSkillData] = useState([]);
  const [skillcreate, setSkillCreate] = useState("");

  const optionsOne = skillData.map((skill) => ({
    value: skill,
    label: skill,
  }));

  const getSkill = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/skills`)
      .then((response) => {
        console.log(JSON.stringify(response), "skilldata");
        const companyValue = response.data.map((e) => ({
          skillIds: e.skillId,
          label: e.label,
          value: e.value,
          ratings: e.ratings,
          averageSkillRating: e.averageSkillRating,
        }));

        setSkillData(companyValue);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const createSkill = () => {
    const payload = {
      name: skillcreate,
    };
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/skill`, payload)
      .then((response) => {
        console.log(JSON.stringify(response.data), "skilldata");
        setSkillCreate("");
        setOptionsOne([]);
        getSkill();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [optionData, setOptionsOne] = useState([]);
  const [toolOption, setToolOption] = useState([]);
  console.log(project.userId, "projectUserId");
  useEffect(() => {
    if (skillcreate.length > 2) {
      setOptionsOne(
        skillData.map((e) => ({
          skillIds: e.skillIds,
          label: e.label,
          value: e.value,
          ratings: e.ratings,
          averageSkillRating: e.averageSkillRating,
          candidateId: Common.loggedIn(),
        }))
      );
    } else {
      setOptionsOne([]);
    }
  }, [skillData, skillcreate]);

  const handleCreatable = async (e) => {
    console.log("creaable", e);
    if (e[e?.length - 1]?.__isNew__) {
      await createSkill();
    }
    handleSkillChnage(e);
    console.log("creatable options", optionsOne);
    // getSkill();
    // setSkillCreate(e.value);
  };

  const [toolData, setToolData] = useState([]);
  const [toolCreate, setToolCreate] = useState("");
  const getToolSkill = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/tools`)
      .then((response) => {
        const companyValue = response.data.map((e) => ({
          skillIds: e.skillId,
          label: e.label,
          value: e.value,
        }));

        setToolData(companyValue);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const createToolSkill = () => {
    const payload = {
      name: toolCreate,
    };
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/tools-create`, payload)
      .then((response) => {
        console.log(JSON.stringify(response.data), "skilldata");
        setToolCreate("");
        getToolSkill();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleToolCreatable = async (e) => {
    console.log("creaable", e);
    if (e[e?.length - 1]?.__isNew__) {
      await createToolSkill();
    }
    handleToolChange(e);
    console.log("creatable options", optionsOne);
    // getSkill();
    // setSkillCreate(e.value);
  };
  useEffect(() => {
    getSkill();
    getToolSkill();
  }, []);
  useEffect(() => {
    setToolOption(
      toolData.map((e) => ({
        skillIds: e.skillId,
        label: e.label,
        value: e.value,
      }))
    );
  }, [toolData]);

  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <></>
      </components.DropdownIndicator>
    );
  };
  useEffect(() => {
    if (popupopen === false) {
      document.body.style.overflow = "hidden";
    } else document.body.style.overflow = "auto";
  }, [popupopen]);

  return (
    <div className="">
      {popupopen ? (
        <Dialog
          open={closemodal}
          PaperProps={{
            style: {
              borderRadius: "16px",
              top: "10px",
              transform: "translateY(0%)",
              position: "absolute",
              backgroundColor: "#FFDAE0",
              padding: "5px 8px",
              height: "fit-content",
            },
          }}
          halfWidth
          maxWidth="md"
          className="def"
        >
          <DialogContent
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontFamily: "Inter-Medium",
              position: "relative",
              left: "5px",
            }}
          >
            <IconWrapper>
              <img
                src={DeleteImg}
                alt="img"
                style={{ width: "24px", height: "24px" }}
              />
            </IconWrapper>
            <JobTitle>
              Are you sure you want to discard the changes you made?
            </JobTitle>
            <DialogActions>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  gap: "10px",
                  marginLeft: "2rem",
                }}
              >
                <Button4
                  onClick={() => {
                    setCloseModal(false);
                    setPopupOpen(false);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Go Back
                </Button4>
                <Button6 onClick={DiscardChanges}>Yes, Discard</Button6>
              </div>
            </DialogActions>
          </DialogContent>
        </Dialog>
      ) : (
        <div>
          <div className="modalHeader">
            <h1 className="mTitle">Add Project</h1>
            <img src={CloseIcon} alt="img" onClick={CloseModalClear} />
          </div>
          <div className="modalBody">
            <div className="Edu-Popup-wrapper">
              <Wrapper>
                <label className="label-name">
                  Project Title<span>*</span>
                </label>
                <InputGroup className="footer-header mb-1 ">
                  <Form.Control
                    type="text"
                    placeholder="Enter your project title"
                    aria-label="Project Title"
                    aria-describedby="basic-addon1"
                    name="projectTitle"
                    value={project.projectTitle}
                    onBlur={validateInput}
                    onChange={handleChange}
                    max={250}
                    className="footer-header"
                    // autoFocus={true}
                  />
                  <span
                    style={{
                      fontSize: "10px",
                      color: "#C6CAD2",
                      fontFamily: "Inter-Medium,sans-serif",
                      padding: "20px 10px 10px 0",
                      position: "absolute",
                      right: "0",
                      top: "12px",
                    }}
                  >
                    Max 250 characters
                  </span>
                </InputGroup>
                {error2.projectTitle && (
                  <span className="text-danger mt-1 f12">
                    {error2.projectTitle}
                  </span>
                )}
              </Wrapper>
              <div
                className="myprofile-content-head input-space"
                style={{ marginTop: "0" }}
              >
                <div className="pro-fr">
                  <label className="label-name">From</label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="date"
                      placeholder="YYYY"
                      aria-label="YYYY"
                      value={project.startDate}
                      max={`${getYear(new Date())}-12`}
                      min="1990-01"
                      aria-describedby="basic-addon1"
                      onChange={handleChange}
                      name="startDate"
                      onBlur={validateInput}
                      className="footer-header"
                    />
                  </InputGroup>
                  {error2.startDate && (
                    <span className="f12">{error2.startDate}</span>
                  )}
                </div>

                <div className="pro-fr">
                  <label className="label-name">To</label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="date"
                      name="endDate"
                      placeholder="YYYY"
                      aria-label="YYYY"
                      max={`${getYear(new Date())}-12`}
                      min="1990-01"
                      value={project.endDate}
                      onChange={handleChange}
                      onBlur={validateInput}
                      aria-describedby="basic-addon1"
                      className="footer-header"
                    />
                    {error2.endDate && (
                      <span className="text-danger f12">{error2.endDate}</span>
                    )}
                  </InputGroup>
                </div>
              </div>
            </div>
            <div className="Edu-Popup-wrapper">
              <div className="input-space">
                <label className="label-name">Tools / Framework</label>
                <div>
                  <CreatableSelect
                    isMulti
                    styles={customStyle}
                    components={{ Placeholder, DropdownIndicator }}
                    className="footer-header"
                    placeholder="Select"
                    name="tool"
                    style={{
                      color: "rgba(198, 202, 210)",
                      fontweight: "normal",
                    }}
                    onChange={(e) => handleToolCreatable(e)}
                    onInputChange={(e) => setToolCreate(e)}
                    options={toolOption}
                    value={tool}
                  />
                </div>
              </div>
              <div className="input-space space">
                <label className="label-name">Skills</label>
                <div>
                  <CreatableSelect
                    isMulti
                    components={{ Placeholder, DropdownIndicator }}
                    styles={customStyle}
                    name="skill"
                    placeholder="Select"
                    noOptionsMessage={() => null}
                    isValidNewOption={() => optionData.length}
                    style={{
                      color: "rgba(198, 202, 210)",
                      fontweight: "normal",
                    }}
                    onChange={(e) => handleCreatable(e)}
                    onInputChange={(e) => setSkillCreate(e)}
                    options={optionData}
                    value={skill}
                  />
                </div>
                <span className="text-danger">{error2.skill}</span>
              </div>
            </div>

            <div class="">
              <label
                for="exampleFormControlTextarea1"
                className="form-label label-name"
              >
                Description<span>*</span>
              </label>

              <ReactQuill
                theme="snow" // You can choose different themes like 'snow', 'bubble', etc.
                value={project.description}
                onChange={handleJobDescChange}
                placeholder="Type description"
                className="f12 quill"
                style={{ color: "rgba(198, 202, 210)", fontweight: "normal" }}
                ref={quillRef}
                onBlur={handleDescriptionBlur}
              />

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    fontSize: "12px",
                    fontFamily: "Inter-Medium,sans-serif",
                    marginTop: "10px",
                  }}
                >
                  {" "}
                  {`${Math.max(textAreaCount - 1, 0)}/1000 characters`}
                </div>
              </div>
              {error2.description && (
                <span
                  className="text-danger f12"
                  style={{ marginTop: "-15px" }}
                >
                  {error2.description}
                </span>
              )}
            </div>
          </div>
          <div className="modalFooter" style={{ marginTop: "15px" }}>
            <button className="button5" onClick={CloseModalClear}>
              Cancel
            </button>
            {proId ? (
              <button className="button3" onClick={projectUpdate}>
                {loading ? <Spinner /> : "Update"}
              </button>
            ) : (
              <button className="button3" onClick={projectCreate}>
                {loading ? <Spinner /> : "Save"}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectModal;
