import React, { useEffect, useState } from "react";
import WebsiteImg from "../../images/Users Group Two Rounded.png";
import Building from "../../images/Buildings 2.svg";
import ColName from "../../images/Buildings.png";
import EmpCount from "../../images/Globals.png";
import Email from "../../images/AboutLanding/Letter.svg";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Phone from "../../images/Phone Rounded.png";
import Deact from "../../images/Deact.png";
import Domains from "../../images/Domain.png";
import Admins from "../../images/Admin.png";
import "./company.css";
import axios from "axios";
import Share from "../../images/Share.png";
import styled from "styled-components";
import Edits from "../../images/Pen 2.svg";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import AboutPopupModal from "./aboutpopupCompany";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogActions } from "@mui/material";
import AboutContactModal from "./aboutContactPopup";
import CompanyJob from "./companyJobs";
import DesktopHeader from "../desktopHeader";
import avatar1 from "../../images/company.png";
import { ToastContainer, toast } from "react-toastify";
import CloseIcon from "../../images/CloseIcon.svg";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { Country, State } from "country-state-city";
import star from "../../images/icons/star.svg";

const MainLayout = styled.div`
  margin: 40px 100px 10px 120px;
  overflow-x: hidden;
  // padding-bottom: 30px;
`;
const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  // padding: 30px;
  margin: 0px 0rem;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const AboutDiv = styled.div`
  position: relative;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  //   margin: 1rem 1rem 1rem 3rem;
  color: #000;
  font-family: var(--pf-medium);
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
`;
const Label = styled.div`
  color: #362a41;
  font-family: var(--pf-medium);
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
  margin: 20px 0 10px 0;
`;
const Descriptions = styled.div`
  font-family: var(--pf-medium);
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  color: #4f4953;
`;
const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  margin: 10px 0;
`;
const Wrappers = styled.div`
  background: #f4f4f6;
  border-radius: 8px;
  padding: 10px 20px;
`;
const WebsiteContent = styled.div`
  margin-top: 5px;
  font-size: 12px;
  font-weight: normal;
  text-transform: capitalize;
`;

const Button2 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: var(--pf-medium);
  color: white;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
  font-weight: normal;
  font-size: 14px;
  background: #fd2145;
  border: 1px solid #1213171a;
  padding: 12px 22px;
  height: 43px;
  position: relative;
  cursor: pointer;
  &:hover {
    border: 1px solid #764f90;
  }
`;
const Website = styled.div`
  color: #4f4953;
  font-family: var(--pf-medium);
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: lowercase;
  display: flex;
  gap: 10px;
`;

const AboutSubDiv = styled.div`
  position: relative;
  margin: 1rem 1rem 1rem 2.5rem;
  top: -27px;
`;
const JobTitle = styled.div`
  position: relative;
  color: #8653b6;
  font-family: var(--pf-medium);
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
`;
const CompanyTitle = styled.div`
  position: relative;
  top: 8px;
  font-family: var(--pf-medium);
  font-size: 14px;
  color: #4f4953;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
`;
const ViewJob = styled.div`
  font-family: var(--pf-medium);
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(149, 92, 202, 1);
  padding: 0px 20px 0 0;
  margin-top: -5px;
  cursor: pointer;
`;
const PostedBy = styled.div`
  position: relative;
  font-family: var(--pf-medium);
  font-size: 12px;
  color: rgba(167, 161, 172, 1);
  font-weight: normal;
  font-style: italic;
  margin-top: 10px;
  img {
    cursor: pointer;
  }
`;

const Description = styled.div`
  color: rgba(79, 73, 83, 1);
  font-family: var(--pf-medium);
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  // text-transform: capitalize;
  margin-left: 220px;
  margin-top: 5px;
  display: flex;
  gap: 10px;
`;
const TogWrapper = styled.div`
  background: white;
  border-radius: 16px;
  box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 0 20px;
`;
const TogContentWrapper = styled.div`
  display: flex;
  gap: 0px;
  border-bottom: 1px solid #f4f4f6;
  img {
    width: 18px;
    height: 18px;
    margin-top: 15px;
  }
`;
const TogContent = styled.div`
  color: #362a41;
  font-family: var(--pf-medium);
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px; /* 100% */
  letter-spacing: 0.32px;
  text-transform: capitalize;
  padding: 15px;
  cursor: pointer;
`;
const DeActHead = styled.div`
  color: #362a41;
  font-family: var(--pf-medium);
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 160%; /* 25.6px */
  text-transform: capitalize;
  // margin-left: 2px;
  bottom: 10px;
  position: relative;
`;
const DeActContent = styled.div`
  color: #4f4953;
  font-family: var(--pf-medium);
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px; /* 25.6px */

  margin-left: 2px;
  padding: -50px;
  width: 384px;
`;

const CompanyEditView = ({ onClose }) => {
  const [isListVisible, setListVisible] = useState(null);
  const [openPage, setOpenPage] = useState(false);
  const { id } = useParams();
  const toggleList = (companyId) => {
    setOpenPage(!openPage);
    if (isListVisible === companyId) {
      setListVisible(null); // Close the menu if it's already open
    } else {
      setListVisible(companyId); // Open the menu for the selected company
    }
  };
  const [open, setPopupOpen] = useState(false);
  const [openTwo, setPopupOpenTwo] = useState(false);
  const [success, setSuccess] = useState(false);
  const history = useHistory();
  const openPopup = (id, oneId) => {
    setPopupOpen(true);
    setSelectedId(id);
    setOneId(oneId);
  };
  const openPopupTwo = (id) => {
    setPopupOpenTwo(true);
    setSelectedId(id);
  };
  const [selectedId, setSelectedId] = useState(null);
  const [oneId, setOneId] = useState(null);
  const [data, setData] = useState();
  const [datas, setDatas] = useState([]);

  const getOneCompanydetail = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/company/details/${id}`)
      .then((response) => {
        setData(response.data.data);
        setOneId(response.data.data._id);
        setSelectedId(response.data.data.companyId);
      })
      .catch((err) => err.error);
  };
  const [userDetail, setUserDetail] = useState();
  const getOneCompanydetailCompany = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-company/${id}`)
      .then((response) => {
        setDatas(response.data.data);
        const one = response.data.userDetail;
        setUserDetail(one);
      })
      .catch((err) => err.error);
  };

  useEffect(() => {
    getOneCompanydetail();
    getOneCompanydetailCompany();
    getUser();
    GetAllCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openClose = () => {
    setPopupOpen(false);
    getOneCompanydetail();
  };
  const openCloseDates = () => {
    setPopupOpenTwo(false);
    getOneCompanydetail();
  };
  // eslint-disable-next-line no-unused-vars
  const [company, setCompany] = useState();
  const GetAllCompany = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getusercompany`)
      .then((response) => {
        setCompany(response.data.company);
      })
      .catch((err) => err.error);
  };
  const location = useLocation();
  const [loginId, setLoginId] = useState();
  const getUser = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuser`)
      .then((response) => {
        setLoginId(response.data?.userId);
      })
      .catch((err) => err.error);
  };
  const deactivateCompany = () => {
    const checkbox = document.getElementById("confirmationCheckbox");
    if (!checkbox.checked) {
      toast.error("Please confirm deactivation by checking the checkbox");

      return;
    }
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/deactivate-company/${selectedId}`)
      .then((response) => {
        setSuccess(false);
        GetAllCompany();
        toast.success("Company is Successfully Deactivated");
      })
      .catch((err) => {
        console.error(err); // Log any errors to the console
      });
  };

  const [openJob, setOpenJob] = useState(false);
  const [selectedJobData, setSelectedJobData] = useState(null);
  const handleOpen = (id) => {
    setSuccess(true);
    setSelectedId(id);
  };
  const handleOpens = (jobData) => {
    setOpenJob(true);
    setSelectedJobData(jobData);
  };
  const isAdmin = data?.companyDomain?.some(
    (domain) => domain.userId === loginId && domain.role.superAdmin
  );
  const isAdminRole = data?.companyDomain?.some(
    (domain) => domain.userId === loginId && domain.role.admin
  );
  const BackToCompany = () => {
    history.push("/portal/view-company");
  };

  const [employees, setEmployees] = useState([]);
  const getCurrentEmployees = async (website) => {
    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/manage/employee`,
        { website }
      );
      setEmployees(response?.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const value = location.state?.website; // Access the website value from location.state
    if (value) {
      getCurrentEmployees(value);
    }
  }, [location.state]);
  const getcn = (cn) => {
    if (cn && cn.length === 2) {
      let a = Country.getCountryByCode(cn);
      if (a && a.name) {
        return a.name;
      }
    } else {
      return cn;
    }
  };
  const getst = (cn, st) => {
    if (cn && st && cn.length === 2 && st.length === 2) {
      let a = State.getStateByCodeAndCountry(st, cn);
      if (a && a.name) {
        return a.name;
      }
    } else {
      return cn;
    }
  };
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const searchedKey = () => {};
  return (
    <div>
      <ToastContainer />
      <DesktopHeader
        back={BackToCompany}
        searchedKey={searchedKey}
        title={data && data.companyName}
        backbtn
      />
      <div style={{ marginTop: "0px" }}>
        <MainLayout className="edit-company-pro-wrapper">
          <div className="Myprofile-container" style={{ margin: "0" }}>
            <div className="Myprofile-topcontainer" style={{ height: "175px" }}>
              <div className="myp-avatar" style={{ top: "100px" }}>
                {data && data.companyLogo ? (
                  <>
                    <div>
                      <img
                        src={data && data.companyLogo}
                        alt="img"
                        style={{
                          width: "160px",
                          height: "160px",
                          borderRadius: "50%",
                          margin: "12px",
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div>
                    <img
                      src={avatar1}
                      alt="img"
                      style={{
                        width: "160px",
                        height: "160px",
                        borderRadius: "50%",
                        margin: "12px",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div
              className="myp-wrapper"
              style={{
                display: "block",
                height: "max-content",
                paddingTop: "10px",
              }}
            >
              <div
                className="company"
                style={{ marginLeft: "220px" }}
                onClick={() =>
                  history.push(
                    `/portal/edit-companyview/${data && data.companyId}`
                  )
                }
              >
                {data && data.companyName}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Description>{data && data.companyTagline}</Description>
                  {/* <Description style={{ marginLeft: "40px" }}>
                    {data && data.companyTagline}
                  </Description> */}
                  {/* <Description> */}
                  {/* <div style={{ margin: "3px 0" }}>
                      {data && data.organisationType}
                    </div>
                    | */}
                  <div>
                    {data &&
                      data.additionalAddress.map((x) => {
                        return (
                          <Description
                            style={{ color: "rgba(168, 161, 172, 1)" }}
                          >
                            {x.city}, {x.state}, {x.country}
                          </Description>
                        );
                      })}
                  </div>
                  <Description>
                    {data && data.primaryAddress ? (
                      <>
                        {`${data && data.primaryAddress?.city},
                    ${getst(
                      data && data.primaryAddress?.country,
                      data && data.primaryAddress?.state
                    )},${getcn(data && data.primaryAddress?.country)}
                    `}
                      </>
                    ) : (
                      ""
                    )}
                  </Description>
                </div>
                {isAdmin && (
                  <img
                    src={Share}
                    alt="img"
                    style={{
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                      marginTop: "40px",
                    }}
                    onClick={() => toggleList(data && data.companyId)}
                  />
                )}
                {isAdminRole && (
                  <img
                    src={Share}
                    alt="img"
                    style={{
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                      marginTop: "40px",
                    }}
                    onClick={() => toggleList(data && data.companyId)}
                  />
                )}
              </div>
              {openPage ? (
                <div className="DeActWrapper">
                  <div></div>

                  <TogWrapper style={{ marginTop: "10px" }}>
                    {isAdmin && (
                      <>
                        <TogContentWrapper>
                          <img src={Admins} alt="img" />
                          <TogContent
                            onClick={() =>
                              history.push({
                                pathname: "/portal/manageAdmin",
                                state: {
                                  website: data && data.website,
                                  companyId: data && data.companyId,
                                },
                              })
                            }
                          >
                            Manage Admin
                          </TogContent>
                        </TogContentWrapper>
                        <TogContentWrapper>
                          <img src={Domains} alt="img" />
                          <TogContent
                            // onClick={() =>
                            //   handleSubmitTwo(data && data.companyId)
                            // }
                            onClick={() =>
                              history.push({
                                pathname: `/portal/company-domain/${data.companyId}`,
                                state: {
                                  website: data && data.website,
                                  companyId: data && data.companyId,
                                },
                              })
                            }
                          >
                            Manage Domains
                          </TogContent>
                        </TogContentWrapper>

                        <>
                          <TogContentWrapper>
                            <>
                              <img src={Deact} alt="img" />
                              <TogContent
                                className=""
                                onClick={() => handleOpen(data && data._id)}
                              >
                                Deactivate Page
                              </TogContent>
                            </>
                          </TogContentWrapper>
                        </>
                      </>
                    )}
                    {isAdminRole && (
                      <>
                        <TogContentWrapper>
                          <img src={Admins} alt="img" />
                          <TogContent
                            onClick={() =>
                              history.push({
                                pathname: "/portal/manageAdmin",
                                state: {
                                  website: data && data.website,
                                  companyId: data && data.companyId,
                                },
                              })
                            }
                          >
                            Manage Admin
                          </TogContent>
                        </TogContentWrapper>
                        <TogContentWrapper>
                          <img src={Domains} alt="img" />
                          <TogContent
                            onClick={() =>
                              history.push({
                                pathname: `/portal/company-domain/${data.companyId}`,
                                state: {
                                  website: data && data.website,
                                  companyId: data && data.companyId,
                                },
                              })
                            }
                          >
                            Manage Domains
                          </TogContent>
                        </TogContentWrapper>
                      </>
                    )}
                  </TogWrapper>
                </div>
              ) : (
                ""
              )}
              {isAdmin &&
                ""
                // <div className="Edu-data-wrapper3 social-container">
                //   <div className="social-wrapper">
                //     <div className="social-btn">
                //       <img src={Edit} alt="img" className="social-img" />
                //     </div>
                //     <div
                //       className="social-btn"
                //       onClick={() =>
                //         history.push(
                //           `/portal/edit-company/${data && data.companyId}`
                //         )
                //       }
                //     >
                //       Edit Page
                //     </div>
                //   </div>
                //   <div className="social-wrapper">
                //     <div className="social-btn">
                //       <img src={Admin} alt="img" className="social-img" />
                //     </div>
                //     <div
                //       className="social-btn"
                //       onClick={() =>
                //         history.push({
                //           pathname: "/portal/manageAdmin",
                //           state: {
                //             website: data && data.website,
                //             companyId: data && data.companyId,
                //           },
                //         })
                //       }
                //     >
                //       Manage Admin
                //     </div>
                //   </div>
                //   <div className="social-wrapper">
                //     <div className="social-btn">
                //       <img src={Domain} alt="img" className="social-img" />
                //     </div>
                //     <div
                //       className="social-btn"
                //       onClick={() => handleSubmitTwo(data && data.companyId)}
                //     >
                //       Manage Domains
                //     </div>
                //   </div>
                //   <div className="social-wrapper">
                //     <div className="social-btn">
                //       <img src={Employee} alt="img" className="social-img" />
                //     </div>
                //     <div
                //       className="social-btn"
                //       onClick={() =>
                //         history.push({
                //           pathname: "/portal/manage-employees",
                //           state: {
                //             companyId: data && data.companyId,
                //             website: data && data.website,
                //           },
                //         })
                //       }
                //     >
                //       Manage Employees
                //     </div>
                //   </div>
                // </div>
              }
              {/* {isAdminRole && (
                <div className="Edu-data-wrapper3 social-container">
                  <div className="social-wrapper">
                    <div className="social-btn">
                      <img src={Edit} alt="img" className="social-img" />
                    </div>
                    <div
                      className="social-btn"
                      onClick={() =>
                        history.push(
                          `/portal/edit-company/${data && data.companyId}`
                        )
                      }
                    >
                      Edit Page
                    </div>
                  </div>
                  <div className="social-wrapper">
                    <div className="social-btn">
                      <img src={Admin} alt="img" className="social-img" />
                    </div>
                    <div
                      className="social-btn"
                      onClick={() =>
                        history.push({
                          pathname: "/portal/manageAdmin",
                          state: {
                            website: data && data?.website,
                            companyId: data && data.companyId,
                          },
                        })
                      }
                    >
                      Manage Admin
                    </div>
                  </div>
                  <div className="social-wrapper">
                    <div className="social-btn">
                      <img src={Domain} alt="img" className="social-img" />
                    </div>
                    <div
                      className="social-btn"
                      onClick={() => handleSubmitTwo(data && data.companyId)}
                    >
                      Manage Domains
                    </div>
                  </div>
                  <div className="social-wrapper">
                    <div className="social-btn">
                      <img src={Employee} alt="img" className="social-img" />
                    </div>
                    <div
                      className="social-btn"
                      onClick={() =>
                        history.push({
                          pathname: "/portal/manage-employees",
                          state: {
                            companyId: data && data.companyId,
                            website: data && data.website,
                          },
                        })
                      }
                    >
                      Manage Employees
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </MainLayout>
      </div>
      <MainLayout style={{ marginTop: "20px", paddingBottom: "20px" }}>
        {/* <div className="Edit-wrapper">
          <div>
            <Link
              to="abouts"
              spy={true}
              smooth={true}
              offset={-180}
              duration={1000}
            >
              About
            </Link>
          </div>
          <div>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-180}
              duration={1000}
            >
              Contact Details
            </Link>
          </div>
          <div>
            <Link
              to="jobPost"
              spy={true}
              smooth={true}
              offset={-180}
              duration={1000}
            >
              Job Posts
            </Link>
          </div>
        </div> */}
        <div style={{ display: "flex", gap: "20px", margin: "20px 0" }}>
          <PageWrapper style={{ width: "70%" }}>
            <div id="abouts">
              <AboutDiv>
                <div className="company" style={{ color: "#362A41" }}>
                  About
                </div>
                {isAdmin && (
                  <img
                    src={Edits}
                    alt="img"
                    onClick={() => openPopup(selectedId, oneId)}
                    style={{
                      cursor: "pointer",
                      border: "1px solid #DFDFE4",
                      borderRadius: "8px",
                      padding: "5px",
                    }}
                  />
                )}
                {isAdminRole && (
                  <img
                    src={Edits}
                    alt="img"
                    onClick={() => openPopup(selectedId, oneId)}
                    style={{
                      cursor: "pointer",
                      border: "1px solid #DFDFE4",
                      borderRadius: "8px",
                      padding: "5px",
                    }}
                  />
                )}
                {/* <FaRegEdit
                size={30}
                color="black"
                style={{ position: "relative", marginTop: "30px" }}
                onClick={() => openPopup(selectedId)}
              /> */}
              </AboutDiv>
              <AboutSubDiv>
                <Label>{data && data.companyName}</Label>
                {/* <Label>Website</Label> */}
                <Website>{data && data.website}</Website>
                <Wrapper>
                  <Website>
                    <img src={ColName} alt="img" />
                    <WebsiteContent>{data && data.industry}</WebsiteContent>
                  </Website>
                  <Website>
                    <img src={WebsiteImg} alt="img" />
                    <WebsiteContent>
                      {data && data.organisationSize}
                    </WebsiteContent>
                  </Website>
                  <Website>
                    <img src={Building} alt="img" />
                    <WebsiteContent>
                      {data && data.organisationType}
                    </WebsiteContent>
                  </Website>
                </Wrapper>
                <Wrappers>
                  <Website>
                    {data && data.companyDescription ? (
                      <>
                        {showFullDescription
                          ? data.companyDescription
                          : `${data.companyDescription.substring(0, 250)}`}
                        <span
                          onClick={toggleDescription}
                          style={{ color: "#6262F5", cursor: "pointer" }}
                        >
                          {showFullDescription ? " Read less..." : "Read more..."}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </Website>
                </Wrappers>
              </AboutSubDiv>
            </div>
          </PageWrapper>
          <PageWrapper style={{ width: "35%", padding: "20px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="company" style={{ color: "#362A41" }}>
                People
              </div>
              <Label
                style={{ margin: "0px", cursor: "pointer" }}
                onClick={() =>
                  history.push({
                    pathname: "/portal/manage-employees",
                    state: {
                      companyId: data && data.companyId,
                      website: data && data.website,
                    },
                  })
                }
              >
                View All
              </Label>
            </div>
            {employees &&
              employees.slice(0, 3).map((emp, i) => {
                return (
                  <div
                    style={{ display: "flex", gap: "20px", margin: "20px 0" }}
                  >
                    {emp?.user?.avatar ? (
                      <img
                        src={emp?.user?.avatar}
                        alt="img"
                        style={{
                          width: "48px",
                          height: "48px",
                          borderRadius: "50px",
                        }}
                      />
                    ) : (
                      <img
                        src={avatar1}
                        alt="img"
                        style={{ width: "48px", height: "48px" }}
                      />
                    )}

                    <div>
                     <div className="star-main">
                     <Label style={{ margin: "5px 0" }}>{`${
                        emp.user.firstName
                      } ${emp.user.middleName ? emp.user.middleName : ""} ${
                        emp.user.lastName
                      }`}</Label>
                      {emp?.user?.totalAverageRating !== undefined &&
                      emp?.user?.totalAverageRating !== null &&
                      emp?.user?.totalAverageRating !== 0 &&
                      emp?.user?.totalAverageRating !== 0.5 ? (
                        <div>
                          <img src={star} className="star-people" alt="" />
                          <span className="star-average">
                            {emp.user.totalAverageRating.toFixed(1)}
                          </span>
                        </div>
                      ) : null}
                     </div>
                      <div>
                        {emp && emp.experience && emp.experience.length > 0 ? (
                          emp &&
                          emp.experience
                            .filter(
                              (exp) => !exp.endDate && exp.timestamps.createdAt
                            )
                            .sort(
                              (exp1, exp2) =>
                                new Date(exp2.timestamps.createdAt) -
                                new Date(exp1.timestamps.createdAt)
                            )
                            .slice(0, 1)
                            .map((a) => (
                              <Descriptions>{a.designation}</Descriptions>
                            ))
                        ) : (
                          <p className="ap-pos"></p>
                        )}
                      </div>
                    </div>
                    {/* <Descriptions style={{ fontSize: "12px", marginTop: "15px" }}>
                  3 years ago
                </Descriptions> */}
                  </div>
                );
              })}

            <hr />
          </PageWrapper>
        </div>
        <PageWrapper>
          <div id="contact">
            <AboutDiv>
              <div className="company" style={{ color: "#362A41" }}>
                Contact Details
              </div>
              {isAdmin && (
                <img
                  src={Edits}
                  alt="img"
                  onClick={() => openPopupTwo(selectedId)}
                  style={{
                    cursor: "pointer",
                    border: "1px solid #DFDFE4",
                    borderRadius: "8px",
                    padding: "5px",
                  }}
                />
              )}
              {isAdminRole && (
                <img
                  src={Edits}
                  alt="img"
                  onClick={() => openPopupTwo(selectedId)}
                  style={{
                    cursor: "pointer",
                    border: "1px solid #DFDFE4",
                    borderRadius: "8px",
                    padding: "5px",
                  }}
                />
              )}
              {/* <FaRegEdit
                size={30}
                color="black"
                style={{ position: "relative", marginTop: "30px" }}
                onClick={() => openPopupTwo(selectedId)}
              /> */}
            </AboutDiv>
            <AboutSubDiv>
              {/* <Label>ContactNumber</Label> */}
              <Wrapper>
                <Website>
                  <img src={Phone} alt="img" />
                  <WebsiteContent>
                    {data && data.contactNumber ? (
                      <>{data && `+${data.contactNumber}`}</>
                    ) : (
                      "-"
                    )}
                  </WebsiteContent>
                </Website>
                <Website>
                  <img src={Email} alt="img" />
                  <WebsiteContent>{data && data.contactEmailId}</WebsiteContent>
                </Website>
                <Website>
                  <img src={EmpCount} alt="img" />
                  <WebsiteContent>
                    {data &&
                      data.workMode?.map((a) => {
                        return <>{a}</>;
                      })}
                  </WebsiteContent>
                </Website>
              </Wrapper>
              {/* <Label>Location</Label> */}
              <Wrappers>
                <Website>
                  {data && data?.primaryAddress ? (
                    <>
                      {`${data && data.primaryAddress.addressOne} ${
                        data && data.primaryAddress.addressTwo
                      } ${data && data.primaryAddress.city},
                    ${getst(
                      data && data.primaryAddress.country,
                      data && data.primaryAddress.state
                    )},${getcn(data && data.primaryAddress.country)}
                    `}
                    </>
                  ) : (
                    "-"
                  )}
                </Website>
              </Wrappers>
              {/* <Label>Workplace modes</Label> */}
            </AboutSubDiv>
          </div>
        </PageWrapper>
        <PageWrapper>
          <div id="jobPost">
            <AboutDiv>
              <div className="company" style={{ color: "#362A41" }}>
                Posted Job
              </div>
            </AboutDiv>
            {/* <AboutSubDiv>
            <Label>Business Analyst</Label>
            </AboutSubDiv> */}
            {Array.isArray(datas) &&
              datas.map((a) => {
                return (
                  <AboutSubDiv style={{ margin: "1.5rem 1rem 0rem 3.5rem" }}>
                    <JobTitle>
                      {a.jobTitle}
                      {/* <BsEye
                        size={30}
                        style={{ marginTop: "10px" }}
                        onClick={() => handleOpens(a)}
                      /> */}
                    </JobTitle>
                    <CompanyTitle>
                      {a.companyName.value}
                      <ViewJob onClick={() => handleOpens(a)}>View Job</ViewJob>
                    </CompanyTitle>

                    <PostedBy className="postedby">
                      Posted By{" "}
                      {userDetail && userDetail.avatar ? (
                        <img
                          src={userDetail && userDetail.avatar}
                          alt="img"
                          style={{ width: "24px", height: "24px" }}
                        />
                      ) : (
                        <img
                          src={avatar1}
                          alt="img"
                          style={{ width: "24px", height: "24px" }}
                        />
                      )}
                      {/* <Name className="name">{`${a.createdBy.firstName} ${a.createdBy.lastName}`}</Name> */}
                    </PostedBy>
                    <hr />
                  </AboutSubDiv>
                );
              })}
          </div>
        </PageWrapper>
      </MainLayout>

      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <div className="modalHeader">
          <span className="mTitle">Edit About</span>
          {/* <AiOutlineCloseCircle onClick={() => setPopupOpen(false)} /> */}
          <img src={CloseIcon} alt="img" onClick={() => setPopupOpen(false)} />
        </div>
        <DialogContent>
          <AboutPopupModal
            id={selectedId}
            mainId={oneId}
            onClosfun={openClose}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: { borderRadius: "20px", margin: "0px", width: "auto" },
        }}
      >
        <div className="modalHeader mheader"
        >
          <div className="mTitle">Deactivate Page</div>
          <img src={CloseIcon} alt="img" onClick={() => setSuccess(false)} />
        </div>

        <DialogContent>
          <div style={{ padding: "30px 30px 20px 30px" }}>
            <DeActHead>We're really sorry for your decision</DeActHead>
            <DeActContent>
              Deactivation will remove the page entirely from Xakal and your
              company admins will no longer will have the access to the page
            </DeActContent>
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: "20px",
              position: "relative",
              marginBottom: "32px",
              gap: "10px",
              padding: "0px 10px",
            }}
          >
            <FormControlLabel
              label="I hereby confirm that I deactivate the Company page"
              control={<Checkbox id="confirmationCheckbox" />}
            />
          </div>
        </DialogContent>
        <DialogActions
          style={{ boxShadow: "0px -4px 16px 0px rgba(0, 0, 0, 0.08)" }}
        >
          <div
            style={{
              position: "relative",
              display: " flex",
              justifyContent: "right",
              gap: "20px",
              margin: "20px",
              marginRight: "16px",
            }}
          >
            <button onClick={() => setSuccess(false)} className="button5">
              Cancel
            </button>
            <Button2 onClick={() => deactivateCompany(selectedId)}>
              Deactivate
            </Button2>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openTwo}
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <div className="modalHeader">
          <span className="mTitle">Edit Contact</span>
          {/* <AiOutlineCloseCircle onClick={() => setPopupOpenTwo(false)} /> */}
          <img
            src={CloseIcon}
            alt="img"
            onClick={() => setPopupOpenTwo(false)}
          />
        </div>
        <DialogContent>
          <AboutContactModal id={selectedId} onClose={openCloseDates} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openJob}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px", maxWidth: "650px" } }}
      >
        <div
          className="modalHeader"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        >
          <div className="mTitle">Job Details</div>
          <img src={CloseIcon} alt="img" onClick={() => setOpenJob(false)} />
        </div>
        <DialogContent>
          <CompanyJob jobData={selectedJobData} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CompanyEditView;
