import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "./service.Popup.css";
import { BsShareFill, BsWhatsapp } from "react-icons/bs";
import { MdCheck, MdContentCopy } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";
import { CiLinkedin } from "react-icons/ci";
import { Dialog, DialogContent } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CloseIcon from "../../images/CloseIcon.svg";
import Back from "../../images/Back.svg";
import Alaram from "../../images/Alarm.svg";
import companyLogo from "../../images/company.png";
import parse from "html-react-parser";
import Clock from "../../images/clock-circle.svg";
import Suit from "../../images/suitcase.svg";
import moment from "moment";
import iBookmark from "../../images/icons/bookmark.svg";
import iBookmarkActive from "../../images/icons/bookmark-active.svg";
import axios from "axios";
import Readmore from "../interview/readMores";

const Preference = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #362a41;
  // position: relative;
  // bottom: 5px;
`;
const Designation = styled.div`
  font-family:
    General Sans,
    sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #a8a1ac;
  margin-top: 5px;
`;
const DesignationList = styled.div`
  font-family:
    General Sans,
    sans-serif;
  font-size: 10px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
  background: #f4e8ff;
  padding: 5px 10px;
  border-radius: 8px;
  // width:fit-content;
  display: flex;
  // margin-left:10px;
  // gap:10px;
  flex-wrap: wrap;
`;
const Description = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 17px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
  margin-top: 5px;
`;
const PreferenceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 15px 0;
  // margin: 20px 0;

  span {
    position: relative;
    top: 0px;
    cursor: pointer;
  }
`;

const MainFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;
const PopUpTitle = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #362a41;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InterviewPopup = ({
  onPopupData,
  onClose,
  openDateForm,
  inputId,
  onClosse,
  callAll,
  callAlls,
  trues,
  closeButton,
}) => {
  
  const [social, setOpenSocial] = useState(false);
  const handleWhatsAppShare = () => {
    const jobName = onPopupData.interviewTitle;
    const jobId = onPopupData._id;

    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;
    const message = `${jobName} (Job ID: ${jobId}) - Click the link to view the job details:`;

    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      `${message} ${jobUrl}`
    )}`;

    window.open(whatsappUrl, "_blank");
  };

  const handleEmailShare = () => {
    const jobId = onPopupData._id;
    const jobTitle = onPopupData.interviewTitle;

    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;

    const subject = `Job Opportunity: ${jobTitle} (Job ID: ${jobId})`;
    const body = `Check out this job opportunity: ${jobUrl}\n\nJob Title: ${jobTitle}\nJob ID: ${jobId}`;

    const emailUrl = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.open(emailUrl, "_blank");
  };
  const handleLinkedInShare = () => {
    const jobTitle = onPopupData.interviewTitle;
    const jobId = onPopupData._id;
    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;

    const message = `${jobTitle} (Job ID: ${jobId}) - Click the link to view the job details: ${jobUrl}`;

    const encodedMessage = encodeURIComponent(message);
    const encodedUrl = encodeURIComponent(jobUrl);

    const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}&title=${encodedMessage}`;

    window.open(linkedinUrl, "_blank");
  };

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = () => {
    const jobId = onPopupData._id;
    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;
    navigator.clipboard.writeText(jobUrl);

    navigator.clipboard.writeText(jobUrl).then(() => {
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    });
  };

  const [userId, setUser] = useState({});
  const getUser = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuser`)
      .then((response) => {
        setUser(response.data);
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    getUser();
  }, []);
  const [data, setData] = useState();

  useEffect(() => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-one-interview/${onPopupData?._id}`
      )
      .then((res) => {
        setData(res.data?.interview);
        const saved = res.data?.interview.savedBy.find(
          (fav) => fav.userId === userId.userId
        );
        const savedApplied = saved && saved.status === "saved" ? true : false;
        setSavedApplied(savedApplied);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [onPopupData?._id, userId.userId]);
  const [savedInterviews, setSavedInterviews] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const array = [
    "http://localhost:3000/portal/search",
    "https://test-staging.thexakal.com/portal/search",
    "https://thexakal.com/portal/search",
    "https://qa.thexakal.com/portal/search",
  ];
  const updateToUnsavedInterview = async (savedId) => {
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/unsaved-interview/${savedId}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedInterviews((prevSavedInterviews) => {
          const index = prevSavedInterviews.indexOf(savedId);
          if (index !== -1) {
            return prevSavedInterviews.filter(
              (interviewId) => interviewId !== savedId
            );
          } else {
            return [...prevSavedInterviews, savedId];
          }
        });
        onClosse();
        callAlls();
      } else {
        console.error("Failed to toggle favorite status");
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  const [showMore, setShowMore] = useState(false);
  const [savedApplied, setSavedApplied] = useState(false);

  const updateToSavedInterview = async (savedId) => {
    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/update-saved-interview/${savedId}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedInterviews((prevSavedInterviews) => [
          ...prevSavedInterviews,
          savedId,
        ]);
      }
      onClosse();
      callAlls();
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  const updateToSavedsInterview = (savedId) => {
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/unsaved-interview/${savedId}`)
      .then((res) => {
        onClose();
      })
      .catch((err) => {});
  };

  const id = onPopupData?.createdBy?.userId;
  const [expereinceDetail, setExperiencedetails] = useState([]);
  const [about, setAbout] = useState();

  const getallexpdetails = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-experience-detail-all/${id}`)
      .then((response) => {
        if (response && response.data && response.data?.success) {
          setExperiencedetails(response.data?.experience);
        }
      })
      .catch((err) => err.error);
  };
  const getAllAbout = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-about-detail/${id}`)
      .then((res) => {
        setAbout(res.data.aboutOne.about);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getallexpdetails();
    getAllAbout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="modalHeader mheader int-prev-wrap">
        <div className="main-part-head">
          <div className="close-icon-image">
            <img src={Back} alt="img" onClick={onClose} />
          </div>
          <div>
            <Preference>{data?.interviewTitle}</Preference>
            <div className="timeago">
              {" "}
              {/* <TimeAgo timestamp={data?.timestamps.createdAt} /> */}
            </div>
          </div>
        </div>
        <div className="interview-exps">
          {closeButton ? (
            ""
          ) : (
            <button
              type="button"
              className="btn job-btn bs-btn"
              onClick={() => {
                openDateForm(data?._id, true);
                inputId(data?.createdBy?.userId);
              }}
            >
              Book Slot
            </button>
          )}

          <div className="interview-exp">
            <div>
              <BsShareFill
                className="header-name-close"
                onClick={() => setOpenSocial(true)}
              />
            </div>
            {trues ? (
              <div onClick={() => updateToSavedsInterview(onPopupData?._id)}>
                {savedApplied ? (
                  <img
                    src={iBookmarkActive}
                    className="bookmark-icon"
                    alt="icon"
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              <>
                {savedApplied || savedInterviews.includes(data?._id) ? (
                  <div onClick={() => updateToUnsavedInterview(data?._id)}>
                    <img
                      src={iBookmarkActive}
                      className="bookmark-icon"
                      alt="icon"
                    />
                  </div>
                ) : (
                  <div onClick={() => updateToSavedInterview(data?._id)}>
                    <img src={iBookmark} className="bookmark-icon" alt="icon" />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <MainFlex>
        <div className="modalBody mBody int-prev-cont">
          <div className="top-exp">
            <div className="int-heads">Interview Description</div>
            <Description>
              {" "}
              <Readmore text={data?.description} maxLength={90} />
            </Description>
          </div>
          <div>
            <PreferenceWrapper>
              <Designation>Designation </Designation>
              {data?.designation.map((item, index) => (
                <DesignationList key={index}>{item}</DesignationList>
              ))}
            </PreferenceWrapper>
          </div>
          <div>
            <PreferenceWrapper>
              <Designation>Skills that'll be evaluated </Designation>
              {data?.skill.map((item, index) => (
                <DesignationList key={index}>{item.label}</DesignationList>
              ))}
            </PreferenceWrapper>
          </div>

          <div className="top-exp">
            <div className="int-heads">Meet the Interviewer</div>
            <div>
              <img
                src={data?.createdBy.logo ? data?.createdBy.logo : companyLogo}
                className="image-shrink"
                alt="imgss"
              />
              <div className="int-heads">
                <p>
                  {data?.createdBy.firstName} {data?.createdBy.lastName}
                </p>
              </div>
            </div>
          </div>
          <div className="top-exp">
            <div className="int-heads">About The interviewer</div>
            <Description>
              {about && about.length > 0 && about !== "<p><br></p>" ? (
                <div className="mp-c-card">
                  <p className="mp-c">
                    <div className="read-more">
                      {about.length > 90 ? (
                        !showMore ? (
                          parse(`${about.substring(0, 90)}`)
                        ) : (
                          <div className="mp-c">{parse(about)}</div>
                        )
                      ) : (
                        parse(about)
                      )}
                      <span
                        className="read-more"
                        onClick={() => setShowMore(!showMore)}
                      >
                        {showMore
                          ? "...Read less"
                          : about.length > 90
                            ? "...Read more"
                            : ""}
                      </span>
                    </div>
                  </p>
                </div>
              ) : (
                ""
              )}
            </Description>
          </div>
          <div className="top-exp">
            <div className="int-heads">Experience</div>
            {expereinceDetail.map((expDetail) => (
              <div className="card-border" key={expDetail._id}>
                <div className="interview-exp">
                  <img
                    src={
                      expDetail.company?.companyLogo
                        ? expDetail.company?.companyLogo
                        : companyLogo
                    }
                    alt="Company Logo"
                    className="card-image-company"
                  />
                  <div>
                    <p>
                      {expDetail?.designation ? expDetail?.designation : "-"}
                    </p>
                    <p>
                      {expDetail?.company?.label
                        ? expDetail?.company?.label
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="interview-expss">
                  <div class="icon-title">
                    <img src={Clock} className="jt-icon" alt="icon" />
                    <span>
                      {expDetail?.employeeType ? expDetail?.employeeType : "-"}
                    </span>
                  </div>
                  <div class="icon-title">
                    <img src={Suit} className="jt-icon" alt="icon" />
                    <span>
                      {" "}
                      {moment(expDetail?.startDate).format("MMM YYYY")}
                      {expDetail.endDate === ""
                        ? " - Present"
                        : ` - ${moment(expDetail?.endDate).format("MMM YYYY")}`}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            className="modalFooter"
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "#F3EEF8",
            }}
          >
            <div className="duration-box">
              <img src={Alaram} alt="img" />
              {data?.duration}
            </div>
            <div style={{ color: "#362A41", fontSize: "24px" }}>
              {data?.inrService ? (
                <button>Free</button>
              ) : (
                <button className="">
                  INR
                  {data && new Intl.NumberFormat("en-IN", {}).format(data?.inr)}
                </button>
              )}
            </div>
          </div>
        </div>
      </MainFlex>

      <Dialog
        open={social}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px", maxWidth: "540px" } }}
      >
        <div className="modalHeader mheader">
          <PopUpTitle>Share</PopUpTitle>
          <img src={CloseIcon} alt="img" onClick={() => setOpenSocial(false)} />
        </div>
        <DialogContent>
          <Wrapper className="modalBody">
            <div>
              <BsWhatsapp
                size={24}
                onClick={handleWhatsAppShare}
                className="what-grid"
              />
              <h4 className="sb-text"> Whats App</h4>
            </div>
            <div className="icon-grid">
              <HiOutlineMail size={24} onClick={handleEmailShare} />
              <h4 className="sb-text"> E-Mail</h4>
            </div>
            <div className="icon-grid">
              <CiLinkedin size={24} onClick={handleLinkedInShare} />
              <h4 className="sb-text"> Linkedin</h4>
            </div>
            <div className="icon-grid">
              {isCopied ? (
                <MdCheck size={30} style={{ color: "green" }} />
              ) : (
                <MdContentCopy size={24} onClick={handleCopyToClipboard} />
              )}
              <h4 className="sb-text">{isCopied ? "Copied" : "Copy Link"}</h4>
            </div>
          </Wrapper>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InterviewPopup;
