import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Common from "../utils/common";
import { Amplify, Auth } from "aws-amplify";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import iSearch from "../images/icons/search.svg";
import iSettings from "../images/icons/settings.svg";
import "../styles/dh-style.css";
import Back from "../images/Back.svg";
import { useLocation } from "react-router-dom";
import ProfileHeader from "./profileHeader";

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  img {
    cursor: pointer;
    margin-top: -5px;
  }
`;
Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      //  redirectSignIn: "http://localhost:3000/",
      //     redirectSignOut: "http://localhost:3000/",,
      redirectSignIn: "https://qa.thexakal.com/login",
      redirectSignOut: "https://qa.thexakal.com/login",

      responseType: "token",
    },
  },
});

const DesktopHeader = ({
  title,
  onFieldChange,
  isSearchActive = true,
  backbtn,
  back,
  searchVal,
  searchedKey,
  onNavaigation,
  onFalse,
}) => {
  const history = useHistory();
  const [user, setUsers] = useState("");
  const [success, setSuccess] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      } else {
        setDropdown(true);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        setUsers(null);
      });
  }, []);

  useEffect(() => {}, [searchValue]);

  const logout = () => {
    Common.logout();
    localStorage.removeItem("bannerOpened");
  };
  const location = useLocation();

  const onInputChange = (event) => {
    setSearchValue(event.target.value);
    searchedKey(event.target.value);
  };


  const handleKeyPress = (event) => {
    const alphanumericRegex = /^[a-zA-Z0-9]$/;

    // Check if Ctrl + F (or Command + F on macOS) is pressed
    if ((event.ctrlKey || event.metaKey) && event.key === "f") {
      // Prevent default browser behavior (opening find dialog)
      event.preventDefault();
      return;
    }

    // Check if input is empty and pathname is not /portal/search
    if (
      location.pathname !== "/portal/search" &&
      alphanumericRegex.test(event.key) &&
      !searchValue.trim()
    ) {
      // Check if input value is not empty
      history.push({ pathname: "/portal/search" });
    }
  };

  function onSubmit2() {
    setSuccess(true);
  }
  
  return (
    <>
      {/* Start of desktop Header  */}

      <div className="desk-header o-desk" onClick={onNavaigation}>
        <Wrapper>
          {backbtn ? <img src={Back} alt="img" onClick={back}></img> : ""}
          <div className="main-header-text">{title}</div>
        </Wrapper>

        <div className="search-section-container">
          {isSearchActive ? (
            <div className="search-section">
              <input
                autoFocus
                color="#fff"
                placeholder={"Search"}
                onChange={onInputChange}
                value={searchValue}
                onKeyDownCapture={handleKeyPress}
                className="inputTaster"
                style={{
                  paddingRight: "40px",
                  background: "rgba(54, 42, 65, 0.04)",
                  fontFamily: "Inter-Medium,sans-serif",
                  fontSize: "12px",
                }} // Add right padding to make space for the icon
              />
              <img src={iSearch} className="t-i-search" alt="" />
            </div>
          ) : (
            <></>
          )}
        </div>
        <ProfileHeader onFalse={onFalse} onNavaigation={onNavaigation} />
        {onFalse ? (
          <div className="dh-setting-container">
            <div class="dropdown" ref={ref}>
              <div
                className="dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-expanded="false"
                onClick={onNavaigation}
              >
                <img src={iSettings} className="desk-setting-icon" alt="" />
              </div>

              <div className="dropdown-menu">
                <div
                  className="dropdown-item iCpass"
                  onClick={() => history.push("/portal/changepassword")}
                >
                  Change Password
                </div>
                <div
                  className="dropdown-item iCbank"
                  onClick={() => history.push("/portal/accountdetails")}
                >
                  Bank Details
                </div>
                {/* <div
                className="dropdown-item iCbank"
                onClick={() => history.push("/portal/myRequests")}
              >
                My Request
              </div>
              <div
                className="dropdown-item iCbank"
                onClick={() => history.push("/portal/pendingRequest")}
              >
                Pending Request
              </div> */}
                <div
                  className="dropdown-item iCbank"
                  onClick={() => history.push("/portal/transaction")}
                >
                  Bank Transaction
                </div>
                <div
                  className="dropdown-item iChelp"
                  onClick={() =>
                    (window.location = "mailto:support@thexakal.com")
                  }
                >
                  Help & Support
                </div>
                <div className="dropdown-item iLogout" onClick={onSubmit2}>
                  {" "}
                  Logout
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="dh-setting-container">
            <div class="dropdown" ref={ref}>
              <div
                className="dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-expanded="false"
                onClick={() => setDropdown(true)}
              >
                <img src={iSettings} className="desk-setting-icon" alt="" />
              </div>

              <div
                className={dropdown ? "dropdown-menu show" : "dropdown-menu"}
              >
                <div
                  className="dropdown-item iCpass"
                  onClick={() => history.push("/portal/changepassword")}
                >
                  Change Password
                </div>
                <div
                  className="dropdown-item iCbank"
                  onClick={() => history.push("/portal/accountdetails")}
                >
                  Bank Details
                </div>
                {/* <div
                className="dropdown-item iCbank"
                onClick={() => history.push("/portal/myRequests")}
              >
                My Request
              </div>
              <div
                className="dropdown-item iCbank"
                onClick={() => history.push("/portal/pendingRequest")}
              >
                Pending Request
              </div> */}
                <div
                  className="dropdown-item iCbank"
                  onClick={() => history.push("/portal/transaction")}
                >
                  Bank Transaction
                </div>
                <div
                  className="dropdown-item iChelp"
                  onClick={() =>
                    (window.location = "mailto:support@thexakal.com")
                  }
                >
                  Help & Support
                </div>
                <div className="dropdown-item iLogout" onClick={onSubmit2}>
                  {" "}
                  Logout
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* End of desktop Header  */}

      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        className="dh-modal"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <div className="modalHeader">
          <h1 className="mTitle">Logging Out</h1>
        </div>
        <div className="modalBody">
          <p>Are you sure you want to log out?</p>
        </div>
        <div className="modalFooter">
          <button className="btn btn-cancel" onClick={() => setSuccess(false)}>
            No
          </button>
          {user ? (
            <button
              onClick={() => {
                Auth.signOut();
                Common.logout();
              }}
              className="btn btn-logout"
            >
              Log out
            </button>
          ) : (
            <button onClick={logout} className="btn btn-logout">
              Log out
            </button>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default DesktopHeader;
