/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import Select, { components } from "react-select";
import styled from "styled-components";
import "../../styles/myprofile.css";
import axios from "axios";
import DownArrow from "../../images/Downarrow.svg";
import CloseIcon from "../../images/CloseIcon.svg";
import Back from "../../images/Back.svg";
const customStyle = {
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    height: "48px",
    border: "none",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    fontFamily: "Inter-Medium,sans-serif",
    Zindex: "2",
  }),
  menu: (provided, state) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    zIndex: "9999",
    "&:hover": {
      color: "#362A41", // Add hover text color
      padding: "5px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 6px)",
      marginLeft: "5px",
    },
  }),
  // Add more styles as needed
};
const Title = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
  margin-bottom:5px;
`;
const ExploreTimeZone = ({ id, interviewId, open, onClose }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDatePickerOpen, setDatePickerOpen] = useState(true);
  const [selectedDay, setSelectedDay] = useState(null);
  const [data, setData] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [des, setDesi] = useState("");
  const [allAvailableTimeSlots, setAllAvailableTimeSlots] = useState([]);
  const [timer, setTimer] = useState(300); // 5 minutes in seconds
  const [timerActive, setTimerActive] = useState(false);
  const handleTimeButtonClick = (time) => {
    const istTime = moment.tz(time, "h:mm A", "Asia/Kolkata");

    const unselectedTimes = getAvailableTimeSlots().filter((availableTime) =>
      istTime.isBefore(moment.tz(availableTime, "h:mm A", "Asia/Kolkata"))
    );

    setSelectedTime(istTime.format("h:mm A"));
    setAllAvailableTimeSlots([...allAvailableTimeSlots, ...unselectedTimes]);
  };
  const handleDateChange = (date) => {
    if (des === "") setError({ des: "Please select designation" });
    else setSelectedDate(date);
    setSelectedDay(date ? moment(date).format("ddd") : null);
    setSelectedTime(null);
    const formattedDate = date
      ? date.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        })
      : null;
  };

  const handleChange = (selectedOptions) => {
    const selectedValue = selectedOptions.value;
    setDesi(selectedValue);
    setError({ des: "" });
  };

  const getAvailableTimeSlots = () => {
    const selectedDayFullName = moment(selectedDay, "ddd").format("dddd");

    const selectedDayAvailability = data[0]?.daysAvailability?.find(
      (availability) => availability.day === selectedDayFullName
    );

    if (selectedDayAvailability) {
      if (selectedDayAvailability.hours) {
        const startHour = 0;
        const allSlots = [];

        for (let hour = startHour; hour < startHour + 24; hour++) {
          const currentTime = moment().startOf("day").add(hour, "hours");

          // Round the minutes to the nearest 30 minutes
          const roundedMinutes = Math.ceil(currentTime.minutes() / 30) * 30;
          currentTime.minutes(roundedMinutes);

          allSlots.push(currentTime.format("h:mm A"));
        }

        return allSlots;
      }

      const { dateInterview } = selectedDayAvailability;

      const availableSlots = dateInterview.filter(
        (slot) => slot.fromTime !== "" && slot.toTime !== ""
      );

      const formattedSlots = availableSlots.reduce((acc, slot) => {
        const fromTime = moment(slot.fromTime, "HH:mm");
        const toTime = moment(slot.toTime, "HH:mm");

        for (
          let current = fromTime.clone();
          current.isBefore(toTime);
          current.add(30, "minutes")
        ) {
          // Round the minutes to the nearest 30 minutes
          const roundedMinutes = Math.ceil(current.minutes() / 30) * 30;
          current.minutes(roundedMinutes);

          acc.push(current.format("h:mm A"));
        }

        return acc;
      }, []);

      return [...new Set(formattedSlots)].sort();
    }

    return [];
  };

  const getAllAvialabilityService = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-all-availability-user/${id}`)
      .then((response) => {
        setData(response.data.interview);
      })
      .catch((err) => {});
  };
  const [vacationDates, setVacationDates] = useState([]);

  const getAllVaccationService = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-all-vaccation-user/${id}`)
      .then((response) => {
        const vacations = response.data.vaccation[0].vacation; // Assuming data is in response.data[0].vacation
        const disabledDates = vacations.map((vacation) => new Date(vacation));
        setVacationDates(disabledDates);
      })
      .catch((err) => {});
  };
  const [designation, setDesignation] = useState([]);
  const [price, setPrice] = useState("");
  const [duration, setDuration] = useState("");
  const [serviceTitle, setServiceTitle] = useState("");
  const [candidateDetail, setCandidateDetail] = useState();
  const [free, setFree] = useState(false);
  const [interviewCode, setInterviewCode] = useState(null);

  const [userId, setUser] = useState({});
  const getUser = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuser`)
      .then((response) => {
        setUser(response.data);
      })
      .catch((err) => err.error);
  };
  const [input, setInput] = useState();
  const prefillValue = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-about-detail/${id}`)
      .then((response) => {
        if (response && response.data && response.data.success) {
          setInput({
            ...input,
            userId: response.data.aboutOne.userId,
            id: response.data.aboutOne._id,
            firstName: response.data.aboutOne.firstName,
            lastName: response.data.aboutOne.lastName,
            middleName: response.data.aboutOne.middleName,
            email: response.data.aboutOne.email,
            bio: response.data.aboutOne.bio,
            city: response.data.aboutOne.city,
            state: response.data.aboutOne.state,
            avatar: response.data.aboutOne.avatar,
            country: response.data.aboutOne.country,
            language: response.data.aboutOne.language,
            proficiency: response.data.aboutOne.proficiency,
            about: response.data.aboutOne.about,
            gender: response.data.aboutOne.gender,
            workProfile: response.data.aboutOne.workProfile,
            urlLink: response.data.aboutOne.urlLink,
            phoneNumber: response.data.aboutOne.phoneNumber,
            inputList: response.data.aboutOne.inputList,
            inputList2: response.data.aboutOne.inputList2,
            inputList3: response.data.aboutOne.inputList3,
            createdDate: response.data.aboutOne.createdDate,
          });
        }
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    prefillValue();
    getAllAvialabilityService();
    getAllVaccationService();

    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-one-interview/${interviewId}`)
      .then((response) => {
        if (response.data && response.data.interview.designation.length > 0) {
          setDesignation(response.data.interview.designation);
        }
        if (
          response.data &&
          response.data.interview.candidateDetail.length > 0
        ) {
          const bookingDetails = response.data.interview.candidateDetail.map(
            (item) => ({
              date: item.bookingService.date,
              time: item.bookingService.time,
              hold: item.bookingService.hold,
              paid: item.bookingService.paid,
              timeHold: item.bookingService.timeHold,
            })
          );
          setCandidateDetail(bookingDetails);
        }
        if (response.data && response.data.interview) {
          const inr = response.data && response.data.interview?.inr;
          setPrice(inr);
          setDuration(response.data.interview?.duration);
          setServiceTitle(response.data.interview?.interviewTitle);
          setFree(response.data.interview?.inrService);
          setInterviewCode(response.data.interview?.interviewCodeId);
          setTimerActive(true);
        }
      })
      .catch((err) => {});
  }, [interviewId]);
  const optionsOne = designation.map((desc) => ({
    value: desc,
    label: desc,
  }));
  const [errors, setError] = useState({ des: "" });
  const dateHold = new Date();

  // Format the date to a readable string
  const formattedDate = dateHold.toLocaleString("en-IN", {
    weekday: "short", // 'Mon'
    year: "numeric", // '2024'
    month: "short", // 'Aug'
    day: "2-digit", // '05'
    hour: "2-digit", // '12'
    minute: "2-digit", // '06'
    second: "2-digit", // '02'
  });
  const handleSubmit = () => {
    const error = {};
    if (!des) error.des = "Please enter the designation";
    if (Object.keys(error).length === 0) {
      const payload = {
        interviewId: id,
        candidateId: userId.userId,
        serviceId: interviewId,
        designation: des,
        date: selectedDate,
        time: selectedTime,
        price: price,
        isFree: free,
        unselectedTimeSlots: adjustTimeAndFormat(selectedTime),
        duration: duration,
        serviceName: serviceTitle,
        interviewCodeId: interviewCode,
        heldBy: userId.userId,
        hold: "hold",
        timeHold: formattedDate,
      };
      axios
        .post(`${process.env.PUBLIC_URL}/xakal/create-booking-slot`, payload)
        .then((response) => {
          const data = response.data.bookingSlot._id;
          open(data.toString());
        })
        .catch((err) => {});
    } else {
      setError(error);
    }
  };
  const CaretDownIcon = () => {
    return (
      <img
        src={DownArrow}
        alt="img"
        style={{ width: "30px", background: "#F4F4F6", padding: "0" }}
      />
    );
  };
  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };
  const selectedDayAvailability = data[0]?.daysAvailability;

  const unavailableDays = selectedDayAvailability
    ? selectedDayAvailability
        .map((day) => {
          const currentDate = new Date();
          const dayIndex = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ].indexOf(day.day);

          const shouldDisableDay = day.dateInterview.some(
            (interview) =>
              (interview.fromTime.trim() !== "" &&
                interview.toTime.trim() === "") ||
              (interview.fromTime.trim() === "" &&
                interview.toTime.trim() !== "") ||
              (interview.fromTime.trim() === "" &&
                interview.toTime.trim() === "")
          );

          if (shouldDisableDay) {
            const datesToDisable = [];
            for (let month = 0; month < 12; month++) {
              const lastDayOfMonth = new Date(
                currentDate.getFullYear(),
                month + 1,
                0
              ).getDate();
              for (let i = 1; i <= lastDayOfMonth; i++) {
                const nextOccurrenceDate = new Date(
                  currentDate.getFullYear(),
                  month,
                  i
                );
                if (nextOccurrenceDate.getDay() === dayIndex) {
                  const isoDate = nextOccurrenceDate.toISOString();
                  datesToDisable.push(
                    isoDate.substring(0, isoDate.indexOf("T") + 1) +
                      "18:30:00.000Z"
                  );
                }
              }
            }

            return datesToDisable;
          }

          return null; // Return null for days that should not be disabled
        })
        .flat() // Flatten the array of dates
        .filter((date) => date !== null) // Filter out null values
    : [];

  const combinedExcludedDates = [...unavailableDays, ...vacationDates];
  const adjustTimeAndFormat = (timeString) => {
    const [time, period] = timeString.split(" ");
    const [hours, minutes] = time.split(":").map(Number);

    let adjustedHours = hours;
    if (period === "PM" && hours !== 12) {
      adjustedHours += 12;
    } else if (period === "AM" && hours === 12) {
      adjustedHours = 0; // Midnight
    }

    let adjustedMinutes = minutes + 30;
    if (adjustedMinutes >= 60) {
      adjustedHours += 1;
      adjustedMinutes -= 60;
    }

    const adjustedPeriod = adjustedHours >= 12 ? "PM" : "AM";
    const formattedHours = adjustedHours % 12 || 12; // Convert hours from 24-hour to 12-hour format

    const formattedTime = `${formattedHours}:${
      adjustedMinutes < 10 ? "0" : ""
    }${adjustedMinutes} ${adjustedPeriod}`;

    return formattedTime;
  };

  const currentDatesss = new Date(); // Get current date/time

  // Extract hours and minutes
  let hours = currentDatesss.getHours();
  let minutes = currentDatesss.getMinutes();

  // Determine AM/PM and adjust hours
  const period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert hours from 24-hour to 12-hour format

  // Format the time as "hh:mm AM/PM"
  const formattedTime = `${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes} ${period}`;
  const formatDates = (date) => {
    const currentDate = new Date(date).toLocaleDateString("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
    });
    return currentDate;
  };
  const currentDates = new Date().toLocaleDateString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });
  useEffect(() => {
    // Retrieve stored timer from local storage
    const storedTimer = localStorage.getItem("timer");
    if (storedTimer) {
      setTimer(JSON.parse(storedTimer));
      setTimerActive(true);
    }
  }, []);

  useEffect(() => {
    if (timerActive && timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          const newTimer = prevTimer - 1;
          localStorage.setItem("timer", JSON.stringify(newTimer));
          return newTimer;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [timerActive, timer]);

  useEffect(() => {
    if (timer <= 0) {
      setTimerActive(false);
      localStorage.removeItem("timer"); // Clear timer from local storage when done
    }
  }, [timer]);
  const handleClose = () => {
    localStorage.removeItem("timer");
    onClose()
  };
  return (
    <div style={{ maxWidth: selectedDate != null ? "580px" : "580px" }}>
      <div className="modalHeader" style={{ padding: "10px 20px" }}>
        <div className="mTitle">
          <img src={Back} alt="back" onClick={onClose} className="int-back" />
          {`${input?.firstName ? input?.firstName : ""} ${
            input?.middleName ? input?.middleName : ""
          } ${input?.lastName ? input?.lastName : ""}`}
          <span className="int-host">Interviewer</span>
        </div>
        <img
          src={CloseIcon}
          alt="img"
          onClick={handleClose}
          style={{ position: "absolute", top: "15px", right: "20px" }}
        />
      </div>
      <div
        className="modalBody"
        style={{ minHeight: "450px", padding: "10px 20px" }}
      >
        <div className={selectedDate ? "pos-fixed" : "pos-static"}>
          <div className="slot-update-time">
            <Title>
              Select the designation you want to be interviewed for{" "}
              <span className="text-danger">*</span>
            </Title>
            <div className="slot-update-times">
              <Title>Timer</Title>
              <p>{`${Math.floor(timer / 60)}:${timer % 60 < 10 ? "0" : ""}${
                timer % 60
              }`}</p>
            </div>
          </div>
          <Select
            value={optionsOne.find((option) => option.value === des)}
            options={optionsOne}
            onChange={handleChange}
            styles={customStyle}
            // menuPlacement="top"
            components={{ Placeholder, DropdownIndicator }}
          />
          {errors.des && <div className="error-message">{errors.des}</div>}

          <Title style={{ marginTop: "10px" }}>Select date & time</Title>
        </div>
        <div style={{ zIndex: "0" }}>
          <div>
            <DatePicker
              papperProps={{
                positionFixed: true, // use this to make the popper position: fixed
              }}
              open={isDatePickerOpen}
              onClose={() => setDatePickerOpen(false)}
              onCalendarOpen={() => setDatePickerOpen(true)}
              onChange={handleDateChange}
              selected={selectedDate}
              showTimeSelect={false}
              excludeDates={combinedExcludedDates.map(
                (isoDate) => new Date(isoDate)
              )}
              // style={{ transform: "translate3d(20px, 172.8px, 0px)",zIndex:"2" }}
              minDate={new Date()}
            />
          </div>
        </div>

        {selectedDate && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              left: "48px",
              margin: "100px 0 0 370px",
            }}
          >
            {getAvailableTimeSlots().map((time, index) => {
              // Find the booking detail for the current slot
              const bookingDetail = candidateDetail?.find(
                (detail) =>
                  moment(detail.date).isSame(selectedDate, "day") &&
                  detail.time === time
              );

              // Extract relevant details
              const paidStatus = bookingDetail?.paid;
              const timeHold = bookingDetail?.timeHold
                ? moment(bookingDetail.timeHold)
                : null;

              // Determine if the hold is active
              const isHoldActive =
                paidStatus === "unpaid" &&
                bookingDetail?.hold === "hold" &&
                timeHold &&
                moment().isBefore(timeHold.add(6, "minutes"));

              // Determine if the slot is disabled (not clickable) if hold is active
              const isDisabled = isHoldActive;

              // Determine if the hold should be released and the button should be enabled
              const shouldReleaseHold =
                paidStatus === "paid" &&
                timeHold &&
                moment().isAfter(timeHold.add(6, "minutes"));

              // Condition to hide the time slot
              const shouldHide =
                currentDates >= formatDates(selectedDate) &&
                formattedTime >= time;

              // Determine the button text
              const buttonText = shouldReleaseHold
                ? `${time} Slot booked`
                : isDisabled
                  ? `${time} Hold`
                  : time;

              return (
                <button
                  className={`button10 ${
                    selectedDate && selectedTime === time ? "active" : ""
                  } ${isDisabled ? "booked" : ""}`} // Apply classes based on status
                  key={index}
                  onClick={() =>
                    !isDisabled &&
                    !shouldReleaseHold &&
                    handleTimeButtonClick(time)
                  } // Handle click if not disabled
                  disabled={shouldHide || isDisabled || shouldReleaseHold} // Disable based on conditions
                  style={{
                    opacity:
                      shouldHide || isDisabled || shouldReleaseHold ? 0.5 : 1,
                  }}
                >
                  {buttonText}
                </button>
              );
            })}
          </div>
        )}
      </div>

      <div
        className="modalFooter"
        style={{
          position: "absolute",
          bottom: "0px",
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "0px",
          padding: "10px 20px",
        }}
      >
        <button
          className="button3"
          onClick={handleSubmit}
          disabled={!selectedTime}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ExploreTimeZone;
