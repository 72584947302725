import React from "react";
import DesktopHeader from "../desktopHeader";
import PdfViewer from "../pdfViwer";

const FilePreview = () => {
  const searchedKey = () => { }
  return (
    <div>
      <DesktopHeader title={"File Preview"} searchedKey={searchedKey} />
      <h4>Document Submit</h4>
      <PdfViewer pdfUrl="https://qa.thexakal.com/xakal/files/getfile/1708665160576-599478608.pdf" />
    </div>
  );
};

export default FilePreview;
