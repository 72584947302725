/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import DesktopHeader from "../desktopHeader";
import "../Jobs/jobs.css";
import "../../styles/myprofile.css";
import { toast } from "react-toastify";
import PlayIcon from "../../images/PlayIcon.svg";
import axios from "axios";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Common from "../../utils/common";
import ViewFeedback from "../payment/viewFeedback";
import styled from "styled-components";
import avatar from "../../images/company.png";
import Alaram from "../../images/Alarms.svg";
import { FaRupeeSign } from "react-icons/fa";

const Preference = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #362a41;
  font-weight: 700;
`;
const InterviewText = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
`;
const InterviewTexts = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
  margin: 10px;
`;
const Duration = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #362a41;
  margin: 20px 0 10px 0;
  display: flex;
  gap: 5px;
  width: 100px;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin: 10px;
`;
const Wrappers = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 10px;
  img {
    width: 48px;
    height: 48px;
  }
`;
const Container = styled.div`
  margin-top: 10px;
`;
const Button = styled.div`
  border-radius: 6px;
  background: #f4e8ff;
  width: auto;
  color: #8653b6;
  font-family: Inter-Medium, sans-serif;
  padding: 8px;
  display: flex;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
const DesignationTitle = styled.div`
  font-family:
    General Sans,
    sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #aba1ac;
  margin-top: 5px;
`;
const Designation = styled.div`
  font-family:
    General Sans,
    sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(168, 161, 172, 1);
  background: rgba(244, 232, 255, 1);
  border-radius: 8px;
  padding: 5px 10px;
`;

const RepoDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const [isVidActive, setIsVidActive] = useState(false);
  const [value, setValue] = useState({});
  const [otherProfile, setOtherProfile] = useState(false);
  const [userType, setUserType] = useState([]);

  const handleDownload = (id, price) => {
    if (otherProfile) {
      if (userType && userType.includes("admin")) {
        setIsVidActive(true);
      } else {
        axios
          .post(
            `${
              process.env.PUBLIC_URL
            }/xakal/about/getpayment/${Common.loggedIn()}`,
            { link: id }
          )
          .then((response) => {
            if (
              response.data &&
              response.data.payment &&
              response.data.payment.paid &&
              response.data.payment.paid === "paid"
            ) {
              setIsVidActive(true);
            } else {
              checkForCredit(id, price);
            }
          });
      }
    } else {
      setIsVidActive(true);
    }
  };

  const checkForCredit = (id, price) => {
    if (companyData && companyData.availableVideoCredit > 0) {
      const payload = {
        availableVideoCredit: --companyData.availableVideoCredit,
        totalVideoUsed: ++companyData.totalVideoUsed,
        totalCredit: ++companyData.totalCredit,
      };
      axios
        .put(
          `${process.env.PUBLIC_URL}/xakal/update-company/update/${companyData._id}`,
          payload
        )
        .then((companyResponse) => {
          if (companyResponse.data.success) {
            axios
              .post(
                `${
                  process.env.PUBLIC_URL
                }/xakal/about/payment/${Common.loggedIn()}`,
                { link: id }
              )
              .then((paymentResponse) => {
                if (paymentResponse.data.success) {
                  setIsVidActive(true);
                }
              })
              .catch((err) => err.error);
          }
        })
        .catch((err) => err.error);
    } else {
      proceedToPay(id, price);
    }
  };

  const proceedToPay = (id, price) => {
    const amount = Math.round(parseFloat(price) * 100);

    const currency = "INR";

    const payload = {
      amount,
      currency,
      receipt: "qwerr1",
    };

    axios
      .post(`${process.env.PUBLIC_URL}/xakal/payment`, payload)
      .then((response) => {
        const data = response.data.order.id;

        var options = {
          key: "rzp_test_COGUtktXGWRh99",
          amount,
          currency,
          name: "Xakal ",
          description: "Transaction",
          image: "https://imagesjob.s3.ap-south-1.amazonaws.com/logopng.png",
          order_id: data,
          handler: function (response) {
            const body = {
              ...response,
            };
            axios
              .post(`${process.env.PUBLIC_URL}/xakal/payment/validate`, body)
              .then((res) => {
                axios
                  .post(
                    `${
                      process.env.PUBLIC_URL
                    }/xakal/about/payment/${Common.loggedIn()}`,
                    { link: id }
                  )
                  .then((updateResponse) => {
                    // Show toaster message
                    toast.success("Payment is successfully completed");
                    // Additional axios.put request
                    setIsVidActive(true);
                  })
                  .catch((updateErr) => {
                    toast.error("Payment Failed");
                  });
              })
              .catch((err) => {});
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };

        var rzp1 = new window.Razorpay(options);

        rzp1.on("payment.failed", function (response) {
          alert(response.error.code);
          alert(response.error.description);
          alert(response.error.source);
          alert(response.error.step);
          alert(response.error.reason);
          alert(response.error.metadata.order_id);
          alert(response.error.metadata.payment_id);
        });
        rzp1.open();
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getCompanyDataOnUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [companyData, setCompanyData] = useState([]);
  const getCompanyDataOnUser = () => {
    axios
      .get(
        `${
          process.env.PUBLIC_URL
        }/xakal/get-company-by-user/${Common.loggedIn()}`
      )
      .then((response) => {
        if (response && response.data && response.data.success) {
          setCompanyData(response.data.company[0]);
        }
      })
      .catch((err) => err.error);
  };

  const getAboutDetails = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      )
      .then((response) => {
        if (response && response.data && response.data.success) {
          setUserType(response.data.aboutOne.userType);
        }
      })
      .catch((err) => err.error);
  };
  const [values, setValues] = useState();
  const [valueBookingId, setValuesBookingId] = useState();

  useEffect(() => {
    // Extracting value and bookingId from URL
    const searchParams = new URLSearchParams(location.search);
    const extractedValue = searchParams.get("value");
    const extractedBookingId = searchParams.get("bookingId");

    // Set state values
    setValues(extractedValue);
    setValuesBookingId(extractedBookingId);
    getAboutDetails();
  }, [location]);
  useEffect(() => {
    setValue(location?.state?.state?.recording);
    setOtherProfile(location?.state?.state?.otherProfile);
    getAboutDetails();
  }, [location]);

  const handleClose = () => {
    const pathname = otherProfile
      ? `/portal/other-profile/${location?.state?.state?.id}`
      : "/portal/my-profile";

    const state = otherProfile
      ? {
          goBack: true,
          fromOtherProfile: true,
          profileId: location?.state?.state?.id,
        }
      : { goBack: true };

    history.push({
      pathname: pathname,
      state: state,
    });
  };

  const [serviceId, setServiceId] = useState();
  const [candidateId, setCandidateId] = useState();
  const [data, setData] = useState();
  const getOneValue = (id) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-one-booking/${id}`)
      .then((response) => {
        const bookingSlot = response.data.bookingSlot;
        setServiceId(bookingSlot.serviceId);
        setCandidateId(bookingSlot?.candidateId);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    if (valueBookingId) {
      getOneValue(valueBookingId);
    } else if (value?.bookingId) {
      getOneValue(value?.bookingId);
    }
  }, [value?.bookingId, valueBookingId]);
  const getOneService = (id) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-one-interview/${id}`)
      .then((res) => {
        setData(res.data.interview);
      })
      .catch(() => {});
  };
  useEffect(() => {
    if (serviceId) {
      getOneService(serviceId);
    }
  }, [serviceId]);
  const TimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState("");

    useEffect(() => {
      const calculateTimeAgo = () => {
        const currentTime = new Date();
        const postedTime = new Date(timestamp);
        const timeDifference = currentTime - postedTime;

        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));

        if (minutes < 60) {
          setTimeAgo(`${minutes} minute${minutes === 1 ? "" : "s"} ago`);
        } else if (hours < 24) {
          setTimeAgo(`${hours} hour${hours === 1 ? "" : "s"} ago`);
        } else if (days < 30) {
          setTimeAgo(`${days} day${days === 1 ? "" : "s"} ago`);
        } else if (months < 12) {
          setTimeAgo(`${months} month${months === 1 ? "" : "s"} ago`);
        } else {
          setTimeAgo(`${years} year${years === 1 ? "" : "s"} ago`);
        }
      };

      calculateTimeAgo();
    }, [timestamp]);

    return <span>{timeAgo}</span>;
  };
  return (
    <div>
      <div className="header-layout">
        {/* Start of desktop Header  */}
        <DesktopHeader
          title={"Repository"}
          isSearchActive={false}
          backbtn
          back={handleClose}
        />
        {/* End of desktop Header  */}
      </div>
      {/* {searchInputVal?.length == 0 ? ( */}
      <>
        <div className="in-content-wrapper">
          <div className="rd-wrapper repo-pos">
            <div className="rd-card">
              <Wrapper>
                <Wrappers>
                  <img
                    src={data?.createdBy?.logo ? data?.createdBy?.logo : avatar}
                    alt="uterrs"
                    className="img-card-div"
                  />
                  <Container>
                    <Preference>{data?.interviewTitle}</Preference>
                    <InterviewText>Interviewer</InterviewText>
                  </Container>
                </Wrappers>
                <Button>
                  <FaRupeeSign />

                  {data?.inr ? data?.inr : "Free"}
                </Button>
              </Wrapper>
              <div
                className={
                  isVidActive
                    ? "rd-video-container active"
                    : "rd-video-container"
                }
              >
                {isVidActive ? (
                  <video
                    src={values ? values : value?.recording?.recordingUrl}
                    type="video/mp4"
                    controlsList="nodownload"
                    className="rd-video"
                    controls
                  ></video>
                ) : (
                  <video
                    src={values ? values : value?.recording?.recordingUrl}
                    type="video/mp4"
                    className="rd-video"
                  ></video>
                )}
              </div>
              {isVidActive ? (
                <></>
              ) : (
                <div
                  onClick={() =>
                    handleDownload(
                      values ? values : value?.recording?.recordingUrl,
                      2000
                    )
                  }
                  className="play-icons"
                >
                  <img src={PlayIcon} alt="img" />
                  <div className="play-icons-text">Play</div>
                </div>
              )}
              <Wrapper>
                <div>
                  <Preference>{data?.interviewTitle} Interview</Preference>
                  <TimeAgo timestamp={data?.timestamps.createdAt} />
                  <DesignationTitle></DesignationTitle>
                </div>
                <Duration>
                  <img src={Alaram} alt="img" />
                  {data?.duration}
                </Duration>
              </Wrapper>
              <InterviewTexts>
                {data && data?.description ? data?.description : "-"}
              </InterviewTexts>
              <Wrappers>
                <DesignationTitle>Designation</DesignationTitle>
                {data?.designation.map((a) => {
                  return (
                    <>
                      <Designation>{a}</Designation>
                    </>
                  );
                })}
              </Wrappers>

              <Wrappers>
                <DesignationTitle>Skills To be Evaluated</DesignationTitle>
                {data?.skill.map((a) => {
                  return (
                    <>
                      <Designation>{a?.value}</Designation>
                    </>
                  );
                })}
              </Wrappers>
            </div>
            <div>
              <ViewFeedback
                bookingId={valueBookingId ? valueBookingId : value?.bookingId}
                values={data?.createdBy}
                candidateId={candidateId}
              />
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default RepoDetails;
