/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import ServiceImage from "../../images/company.png";
import "../../styles/myprofile.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Select from "react-select";
import interviewLogo from "../../images/xakallogo.png";
import { FaPlayCircle, FaRupeeSign } from "react-icons/fa";
import DeleteImg from "../../images/solar_shield-warning-bold-duotone.svg";
import CloseIcon from "../../images/CloseIcon.svg";
import Decline from "../../images/solar_close-circle-bold-duotone (1).svg";
import "./service.css";
import Pause from "../../images/solar--pause-bold.svg";
import Verified from "../../images/Tick.svg";
import Pending from "../../images/solar--close-circle-bold.svg";
import Download from "../../images/Download Minimalistic.svg";
import iSalary from "../../images/icons/salary.svg";
import iClock from "../../images/Alarm.png";
import Star from "../../images/StarIcon.svg";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { BsThreeDots } from "react-icons/bs";
import styled from "styled-components";
import { InputGroup, Form } from "react-bootstrap";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import ServicePopup from "./servicePopup";
import axios from "axios";
import InterviewPopup from "./interviewPopup";
import { ToastContainer, toast } from "react-toastify";
import AvailabilityTimePicker from "./availabilty";
import PreviewPopup from "./previewPopup";
import UpdateServicePopup from "./updateServicePopup";
import Slider from "react-slick";
import Plus from "../../images/Normal_State_Plus.svg";
import PlusHover from "../../images/Hover_State_Plus.svg";
import StatusPreview from "./statusPreview";
import Common from "../../utils/common";
import Reschedule from "./reschedule";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import SlotInterview from "./slotexplore";
import ExploreTimeZone from "./timeExploreInterview";

const customStyle = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    height: "48px",
    border: "none",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    fontFamily: "Inter-Medium,sans-serif",
    fontweight: "normal",
    color: "#4F4953",
  }),
  menu: (provided) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    "&:hover": {
      color: "#362A41", // Add hover text color
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 6px)",
      marginLeft: "5px",
    },
  }),
  // Add more styles as needed
};

const JobPreference = styled.div`
  // color: black;
  background: #f4e8ff;
  padding: 5px 10px;
  height: 30px;
  border-radius: 5px;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
  margin-top: 12px;
  width: fit-content;
`;
const PreferenceWrapper = styled.div`
  display: flex;
  gap: 20px;
  border-top: 1px solid grey;
  span {
    position: relative;
    top: 0px;
    cursor: pointer;
  }
  @media (max-width: 600px) {
    display: block;
  }
`;
const Preference = styled.div`
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: capitalize;
  position: relative;
  top: 5px;
`;
const DropdownContent = styled.div`
  font-size: 14px;
  color: #4f4953;
  font-weight: normal;
  font-family: Inter-Medium, sans-serif;
  // margin-top: 10px;
`;

const Title = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 23px;
  letter-spacing: 0em;
  // text-align: center;
  color: #362a41;
  margin-top: 2px;
`;
const Dropdown = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: max-content;
  // padding:10px 0px;
`;

const DropdownList = styled.div`
  list-style: none;
  padding: 0;
  margin: 0;
  // width: 160px;
`;

const DropdownItem = styled.li`
  padding: 0px 16px;
  cursor: pointer;
  background: #fff;
  border-radius: 8px;
  &:hover {
    background-color: #f0f0f0;
  }
`;
const DropdownItems = styled.li`
  padding: 8px 16px;
  cursor: pointer;
  background: #fff;
  border-radius: 8px;
  &:hover {
    background-color: rgba(253, 33, 69, 0.1);
  }
`;

const ImageContainer = styled.div`
  position: relative;
`;
const ImageWrapper = styled.div`
  position: relative;
  width: 150px;
  height: 168px;
  bottom: 2px;
  left: 2px;
  border-radius: 16px;
  overflow: hidden;
  @media (min-width: 601px) and (max-width: 1200px) {
    width: 140px;
    height: 168px;
  }
`;
const Dots = styled.div`
  position: absolute;
  top: 35px;
  right: 20px;
  background: #f6f6f7;
  border-radius: 50%;
  padding: 5px;
  // height: fit-content;
`;
const Button5 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: Inter-Medium, sans-serif;
  border-radius: 10px;
  font-weight: normal;
  font-size: 14px;
  color: #2d3139;
  border: 1px solid #1213171a;
  padding: 12px 22px;
  width: 94px;
  height: 40px;
  position: relative;
  // top: 3px;
  cursor: pointer;
  // &:hover {
  //   background: black;
  //   color: #764f90;
  //   border: 1px solid #764f90;
  // }
`;
const Button6 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fd2145;

  font-family: Inter-Medium, sans-serif;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  color: white;
  padding: 7px 10px;
  /* margin: 15px 0; */
  cursor: pointer;
  // &:hover {
  //   background: black;
  //   color: #764f90;
  //   border: 1px solid #764f90;
  // }
`;
const JobTitle = styled.div`
  width: auto;
  // height: 22px;
  // top: 24px;
  left: 0px;
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #8653b6;

  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 22px;
    margin: -15px 0 0 0;
  }
`;
const Time = styled.div`
  margin-left: auto;
  font-size: 14px;
  margin-top: 5px;
`;

const Description = styled.div`
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  line-height: 17px;
  font-style: normal;
  font-weight: normal;
  line-height: 140%; /* 16.8px */
  margin-bottom: 10px;
  margin-top: 10px;
  span {
    color: #8653b6;
    cursor: pointer;
  }
  @media (max-width: 600px) {
    margin-top: 10px;
  }
`;
const Duration = styled.div`
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  position: absolute;
  right: 30px;
  // display:flex;
  // justify-content:end;
  @media (max-width: 600px) {
    position: relative;
    right: 0px;
  }
`;
const Designation = styled.div`
  color: #a8a1ac;
  font-family:
    General Sans,
    sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
  margin: 20px 0 0px 0;
`;
const DesignationList = styled.div`
  color: #a8a1ac;
  font-family:
    General Sans,
    sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
  margin: 15px 0 0px 0;
  background: rgba(244, 232, 255, 1);
  padding: 5px;
  height: fit-content;
  border-radius: 8px;
  @media (max-width: 600px) {
    display: inline-block;
    margin-right: 10px;
  }
`;
const reasonOptions = [
  {
    value: "Not Acceptable",
    label: "Not Acceptable",
  },
  {
    value: "Not enough Skill",
    label: "Not enough Skill",
  },
];
const Service = ({
  input,
  experience,
  education,
  accountDetail,
  handleSection,
  checkActive = "active",
  handleSkill,
  enableService,
  inactives,
  opens,
}) => {
  const [openService, setOpenService] = useState(false);
  const [data, setData] = useState([]);
  const [editedData, setEditedData] = useState(null);
  const [resetPopup, setResetPopup] = useState(false);
  const [openServiceUpdate, setOpenServiceUpdate] = useState(false);
  const location = useLocation();
  const openCreateService = () => {
    setOpenService(true);
    setEditedData(null);
    setResetPopup(!resetPopup);
  };
  const closeServiceUpdate = () => {
    setOpenServiceUpdate(false);
  };
  const closeCreateService = () => {
    setOpenService(false);
  };
  const getAllInterviewService = () => {
    let url = `${process.env.PUBLIC_URL}/xakal/get-all-interview`;
    switch (value) {
      case "active":
        url = `${process.env.PUBLIC_URL}/xakal/get-all-interview`;
        break;
      case "archived":
        url = `${process.env.PUBLIC_URL}/xakal/get-all-archived`;
        break;
      case "inactive":
        url = `${process.env.PUBLIC_URL}/xakal/get-all-inactive-interview`;
        break;
      default:
        url = `${process.env.PUBLIC_URL}/xakal/get-all-interview`;
        break;
    }
    axios
      .get(url)
      .then((response) => {
        setData(response.data.interview);
      })
      .catch((err) => {});
  };
  const [requestData, setRequestData] = useState([]);
  const [requestPass, setRequestPass] = useState(false);

  const handleRequest = async () => {
    setRequestPass(true);
    setActiveTab(null);
    getRequest(); // Wait for getRequest to complete
    setCount(null);
    setOpenForm(false);
  };
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useState();
  // eslint-disable-next-line no-unused-vars
  const [exp, setExperienceSkill] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [edu, setEducationSkill] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [aboutSkill, setAboutSkill] = useState([]);
  const [softSkill, setSoftSkill] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [cour, setCourseSkill] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [pro, setProjectSkill] = useState([]);

  const getRequest = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-all-request`)
      .then((response) => {
        setRequestData(response.data.interview);
        const dataCount = response.data.interview.map(
          (a) => a.candidateDetail.length
        );
        setCount(dataCount);
        const experienceSkills = response.data.interview.map((interview) =>
          interview.candidateDetail.map((candidate) =>
            candidate.experienceDetail.map((e) => e.skill)
          )
        );
        setExperienceSkill(experienceSkills);
        const educationSkills = response.data.interview.map((interview) =>
          interview.candidateDetail.map((candidate) =>
            candidate.educationDetail.map((e) => e.skill)
          )
        );
        setEducationSkill(educationSkills);
        const projectSkills = response.data.interview.map((interview) =>
          interview.candidateDetail.map((candidate) =>
            candidate.projectDetail.map((e) => e.skill)
          )
        );
        setProjectSkill(projectSkills);
        const courseSkills = response.data.interview.map((interview) =>
          interview.candidateDetail.map((candidate) =>
            candidate.courseDetail.map((e) => e.skill)
          )
        );
        setCourseSkill(courseSkills);
        const aboutSkills = response.data.interview.map((interview) =>
          interview.candidateDetail.map(
            (candidate) => candidate.aboutDetail.coreSkill
          )
        );

        setAboutSkill(aboutSkills);
        const softSkills = response.data.interview.map((interview) =>
          interview.candidateDetail.map(
            (candidate) => candidate.aboutDetail.softSkill
          )
        );

        setSoftSkill(softSkills);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getRequest();
  }, []);
  const TimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState("");

    useEffect(() => {
      const calculateTimeAgo = () => {
        const currentTime = new Date();
        const postedTime = new Date(timestamp);
        const timeDifference = currentTime - postedTime;

        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));

        if (minutes < 60) {
          setTimeAgo(`${minutes} minute${minutes === 1 ? "" : "s"} ago`);
        } else if (hours < 24) {
          setTimeAgo(`${hours} hour${hours === 1 ? "" : "s"} ago`);
        } else if (days < 30) {
          setTimeAgo(`${days} day${days === 1 ? "" : "s"} ago`);
        } else if (months < 12) {
          setTimeAgo(`${months} month${months === 1 ? "" : "s"} ago`);
        } else {
          setTimeAgo(`${years} year${years === 1 ? "" : "s"} ago`);
        }
      };

      calculateTimeAgo();
      const intervalId = setInterval(calculateTimeAgo, 60000);

      return () => {
        clearInterval(intervalId); // Cleanup interval on component unmount
      };
    }, [timestamp]);

    return <Time>{timeAgo}</Time>;
  };
  const [openForm, setOpenForm] = useState(false);
  const [previewForm, setPreviewForm] = useState(false);
  const [statusPreview, setStatusPreview] = useState(false);
  const [previewId, setPreviewId] = useState("");
  const [selectedData, setSelectedData] = useState(null);
  const handleOpen = (data) => {
    setOpenForm(true);
    setSelectedData(data);
  };

  const handleClose = () => {
    setStatusPreview(false);
  };
  const closeForm = () => {
    setOpenForm(false);
  };

  const previewFormOpen = (id) => {
    setPreviewForm(true);
    setPreviewId(id);
  };
  const previewFormId = () => {
    setPreviewForm(true);
  };

  const [accountDetails, setAccountDetail] = useState(accountDetail);
  const getOneAccount = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/account-get/${Common.loggedIn()}`)
      .then((response) => {
        setAccountDetail(response.data.account);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getOneAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const previewClose = () => {
    if (
      input?.inputList.length &&
      input?.inputList[0].proficiency !== "" &&
      input?.inputList2.length &&
      input?.inputList3[0]?.resume &&
      experience.length &&
      education.length &&
      accountDetail?.length
    ) {
      setPreviewForm(false);
    } else {
      setPreviewForm(false);
      setStatusPreview(true);
    }
  };
  // eslint-disable-next-line no-unused-vars
  const [openDropdown, setOpenDropdown] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedInterviewId, setSelectedInterviewId] = useState(null);
  const [openDropdownMap, setOpenDropdownMap] = useState({});

  const dropdown = (interviewId) => {
    setOpenDropdownMap((prevOpenDropdownMap) => {
      const newDropdownMap = {
        [interviewId]: !prevOpenDropdownMap[interviewId],
      };

      // Close all other dropdowns
      Object.keys(prevOpenDropdownMap).forEach((key) => {
        if (key !== interviewId) {
          newDropdownMap[key] = false;
        }
      });

      return newDropdownMap;
    });
    setSelectedInterviewId(interviewId);
  };
  const [activeTab, setActiveTab] = useState("active");
  const [tabs, setTabs] = useState("");
  useEffect(() => {
    if (location.state && location.state) {
      setOpen(location.state.open);
      setTabs(location.state.inactive);
    }
  }, [location.state]);
  const [open, setOpen] = useState();
  useEffect(() => {
    if (tabs) {
      setActiveTab(tabs);
      setOpen(open);
    }
  }, []);
  const switchTab = (tab) => {
    setActiveTab(tab);

    setOpenDropdown(false);
    setSelectedInterviewId(null);
    getAllInterviewService(tab);
    setRequestPass(false);
  };
  useEffect(() => {
    getAllInterviewService(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);
  const updateArchiveInterviewStatus = async (interviewId) => {
    try {
      // Make a request to your backend to update the interview status
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/get-archive-interview/${interviewId}`
      );

      // Check if the update was successful
      if (response.data.job) {
        // Update the local state to reflect the change
        const updatedData = data.map((interview) => {
          if (interview._id === interviewId) {
            return { ...interview, status: "archived" };
          }
          return interview;
        });
        getAllInterviewService();
        setData(updatedData);
        toast.success("Interview Archived Successfully");
        setOpenDropdownMap((prevOpenDropdownMap) => ({
          ...prevOpenDropdownMap,
          [interviewId]: false, // Close the dropdown after status update
        }));
      } else {
        // Handle the case where the update was not successful
        console.error("Failed to update interview status.");
      }
    } catch (error) {
      console.error("Error updating interview status:", error);
    }
  };
  const updateInActiveStatus = async (interviewId) => {
    try {
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/get-inactive-interview/${interviewId}`
      );

      // Check if the update was successful
      if (response.data.job) {
        const updatedData = data.map((interview) => {
          if (interview._id === interviewId) {
            return { ...interview, status: "pending" };
          }
          return interview;
        });
        getAllInterviewService();
        setData(updatedData);
        toast.success("Interview Archived Successfully");
        setOpenDropdownMap((prevOpenDropdownMap) => ({
          ...prevOpenDropdownMap,
          [interviewId]: false, // Close the dropdown after status update
        }));
      } else {
        // Handle the case where the update was not successful
        console.error("Failed to update interview status.");
      }
    } catch (error) {
      console.error("Error updating interview status:", error);
    }
  };
  const editOpen = (id) => {
    setOpenServiceUpdate(true);
    setEditedData(id);
  };
  const [open4, setOpen4] = useState(false);
  const [deleteId, setDeleteId] = useState(false);

  const handleDelete = (id) => {
    setOpen4(true);
    setDeleteId(id);
  };
  const deleteInterviewJob = async (jobId) => {
    try {
      // Make a DELETE request to the delete service endpoint
      const response = await axios.delete(
        `${process.env.PUBLIC_URL}/xakal/get-delete-interview/${jobId}`
      );

      // Update the state to reflect the changes (remove the deleted job from the list)
      setData(data.filter((item) => item._id !== jobId));
      setOpen4(false);
      toast.success("Interview Deleted Successfully...");
    } catch (error) {
      console.error("Error deleting archived job:", error);
    }
  };
  const settings = {
    autoplay: true,
    arrows: true, // Set arrows to true to make them visible
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
  };
  const [openAvailability, setAvailability] = useState(false);
  const [isAvailableButtonClicked, setAvailableButtonClicked] = useState(false);

  const availabiltyCancel = () => {
    setAvailability(false);
    if (!isAvailableButtonClicked) {
      openCreateService();
    }
    setAvailableButtonClicked(false);
  };
  const goToService = (id) => {
    setOpenServiceUpdate(true);
    setPreviewForm(false);
    setPreviewId(id);
  };
  const [availabilityData, setAvailabilityData] = useState([]);
  const [editId, setEditId] = useState(null);

  const handleAvailabilityButtonClick = (isButtonClicked = false) => {
    setAvailableButtonClicked(isButtonClicked);
    if (Array.isArray(availabilityData) && availabilityData.length === 0) {
      // Open create popup
      setEditId(null);
      setAvailability(true);
    } else {
      const idToEdit = availabilityData[0]?._id;
      setEditId(idToEdit);
      setAvailability(true);
    }
  };
  const getAllAvialability = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-availability`)
      .then((response) => {
        setAvailabilityData(response.data.interview);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getAllAvialability();
  }, []);
  const formatDate = (dateString) => {
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      // weekday: "long",
    };

    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };
  const formatDates = (dateString) => {
    const options = {
      // month: "short",
      // day: "numeric",
      // year: "numeric",
      weekday: "long",
    };

    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };
  const [declineDescription, setDeclineDescription] = useState("");
  const [reason, setReason] = useState("");
  const handleDecline = (id) => {
    const payload = {
      declineDescription: declineDescription,
      declineReason: reason,
    };
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/get-update-decline/${id}`, payload)
      .then((response) => {
        toast.success("Slot  Declined Successfully");
        getRequest();
        axios
          .post(`${process.env.PUBLIC_URL}/xakal/remove/event`, {
            bookingId: id,
          })
          .then(() => {});
        setDeclineOpen(false);
      })
      .catch((err) => {});
  };
  const [declineOpen, setDeclineOpen] = useState(false);
  const handleReason = (selectedOptions) => {
    const selectedOption = selectedOptions.value;
    setReason(selectedOption);
  };
  const [declineId, setIdDecline] = useState();
  const openDecline = (id) => {
    setDeclineOpen(true);
    setIdDecline(id);
  };
  function isExpired(bookingDate, bookingTime) {
    const bookingDateTime = new Date(bookingDate);
    const [hours, minutes] = bookingTime.split(":");
    bookingDateTime.setHours(parseInt(hours, 10), parseInt(minutes, 10));

    const currentDate = new Date();

    return currentDate >= bookingDateTime;
  }
  const closeAvail = () => {
    setAvailability(false);
  };
  const handleDownload = async (resumeUrl, aboutDetail) => {
    try {
      // Fetch the PDF content from the URL
      const response = await fetch(resumeUrl);
      const pdfArrayBuffer = await response.arrayBuffer();

      // Check if pdfArrayBuffer is valid

      if (!pdfArrayBuffer) {
        console.error("Error: PDF Array Buffer is null or invalid.");
        return;
      }

      const pdfBlob = new Blob([pdfArrayBuffer], { type: "application/pdf" });

      const fileName = `${aboutDetail?.firstName} ${aboutDetail?.middleName} ${aboutDetail?.lastName}_Resume.pdf`;

      const a = document.createElement("a");

      if (typeof URL.createObjectURL !== "function") {
        console.error("Error: createObjectURL is not supported.");
        return;
      }

      // Create a download link for the fetched PDF
      a.href = URL.createObjectURL(pdfBlob);
      a.download = fileName;
      a.click();
    } catch (error) {
      console.error("Error fetching or downloading PDF:", error);
    }
  };

  // ...
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = (index) => {
    const video = document.querySelector(`#video-${index}`);
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  const [value, setValue] = useState(checkActive);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const availableSkills = [
    "Sql",
    "UI Design",
    "UX Design",
    "Web Development",
    "Wireless Communications and Networks",
    "React JS",
    "Recruiter",
    "Sales",
    "Scaled Agile",
    "Scrum",
    "SEO",
    "snowflake",
    "Spring Boot",
    "Spring Framework",
    "NLP",
    "Node js",
    "NumPy",
    "Object-Oriented Programming (OOP)",
    "PCB Design",
    "Persuasion",
    "Postgre Sql",
    "Prompt tuning",
    "Python",
    "Hardware Development",
    "Hardware Product Development",
    "HTML",
    "Internet of Things",
    "Java Script",
    "Java",
    "Machine Learning",
    "Marketing",
    "MERN",
    "Negotiation",
    "net",
    "Docker Products and Business Requirements",
    "Electronic Circuit Design",
    "Electronics Hardware Design",
    "Embedded Systems",
    "ETL",
    "Figma",
    "finance",
    "Graphic Designing",
    "Azure DevOps",
    "Big Data",
    "Circuit Design",
    "Circuit Designer Entry Level",
    "Coaching",
    "Communication",
    "Core Java",
    "CSS",
    "Data Analytics",
    "Data Engineer",
    "Data Modeling",
    "Design",
    "Accounting",
    "Adobe",
    "Agile Methodologies",
    "AI",
    "Angular",
    "AWS",
  ];
  const [userRole, setUserRole] = useState(false);
  const prefill = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      )
      .then((response) => {
        if (response && response.data && response.data.aboutOne) {
          setUserRole(response.data.aboutOne.userType.includes("interviewer"));
        }
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    prefill();
  }, []);
  const [openReschedule, setOpenReschedule] = useState(false);
  const openReschedules = () => {
    setRequestPass(false);
    setOpenReschedule(true);
  };
  const openReschedulesClose = () => {
    setRequestPass(true);
    setOpenReschedule(false);
  };
  const [rescheduleId, setRescheduleId] = useState();
  function isExpiredOne(bookingDate, bookingTime) {
    const bookingDateTime = new Date(bookingDate);

    const [hours, minutes] = bookingTime.split(":");

    let bookingHour = parseInt(hours, 10);
    if (bookingTime.includes("PM") && bookingHour !== 12) {
      bookingHour += 12;
    }

    bookingDateTime.setHours(bookingHour, parseInt(minutes, 10));

    const currentDate = new Date();

    const thirtyMinutesBeforeBooking = new Date(bookingDateTime);
    thirtyMinutesBeforeBooking.setMinutes(
      thirtyMinutesBeforeBooking.getMinutes() - 30
    );

    return currentDate >= thirtyMinutesBeforeBooking;
  }
  const [isScrolled, setIsScrolled] = useState(false);
  const [openOne, setOpenOne] = useState(opens); // Initialize with a default boolean value

  useEffect(() => {
    if (inactives === "inactive") {
      switchTab("inactive");
      setStatusPreview(openOne); // Use opensOne directly as a boolean
    } else {
      setStatusPreview(false); // Ensure it's false when not in 'inactive' state
    }
  }, [inactives, openOne]);

  useEffect(() => {
    // Save 'open' state to localStorage whenever 'opensOne' changes
    localStorage.setItem("open", JSON.stringify(openOne)); // Store opensOne as boolean

    // Additional logic related to saving inactives state if needed
  }, [openOne]);

  const remove = () => {
    localStorage.removeItem("open");
    setStatusPreview(false); // Reset status preview
  };
  const [ids, setIds] = useState("");
  const [openDate, setOpenDate] = useState(false);

  const [inputId, setInputId] = useState();
  const openDateForm = (id) => {
    setOpenDate(true);
    setIds(id);
    setInputId(id);
  };
  const [openConfirm, setConfirmPopup] = useState(false);
  const [slotId, setSlotId] = useState("");
  const openConfirmPopup = (id) => {
    setConfirmPopup(true);
    setOpenDate(false);
    setSlotId(id);
  };
  const openFirst = (id) => {
    setOpenDate(true);
    setConfirmPopup(false);
    setSlotId(id);
  };
  const close = () => {
    setConfirmPopup(false);
  };
  const inputFormInput = (id) => {
    setInputId(id);
  };
  const [button, setButton] = useState(false);
  useEffect(() => {
    setButton(true);
  }, []);
  return (
    <>
      <ToastContainer />
      <div
        className="myp-wrapper3"
        style={{ margin: "0", borderRadius: "0 0 24px 24px" }}
      >
        <div className="int-head">
          <div className="ser-head-wrapper">
            <ToggleButtonGroup
              textColor="secondary"
              indicatorColor="secondary"
              value={value}
              onChange={handleChange}
              exclusive
            >
              <ToggleButton
                value="active"
                onClick={() => {
                  switchTab("active");
                  setOpenForm(false);
                }}
              >
                Active
              </ToggleButton>

              <ToggleButton
                value="inactive"
                onClick={() => {
                  switchTab("inactive");
                  setOpenForm(false);
                }}
              >
                Inactive
              </ToggleButton>

              <ToggleButton
                value="archived"
                onClick={() => {
                  switchTab("archived");
                  setOpenForm(false);
                }}
              >
                Archived
              </ToggleButton>
              <ToggleButton value="request" onClick={handleRequest}>
                Interview Request
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          {userRole ? (
            <div className="int-head2">
              <div>
                <button
                  className=""
                  onClick={() => handleAvailabilityButtonClick(true)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "center",
                    textDecoration: "underline",
                    textUnderlineOffset: "3px",
                  }}
                >
                  Manage Availability
                </button>
              </div>

              <button
                className="button2 create-int-btn"
                onClick={() => handleAvailabilityButtonClick()}
              >
                <img src={Plus} alt="img" className="plus-icon" />
                <img src={PlusHover} alt="img" className="plus-icon2" />

                <span>Create an Interview</span>
              </button>
            </div>
          ) : (
            ""
          )}
        </div>

        {openReschedule ? (
          <Reschedule open={openReschedulesClose} bookingId={rescheduleId} />
        ) : (
          <>
            {requestPass ? (
              <>
                {requestData.map((i) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                        justifyContent: "space-between",
                        maxWidth: "100%",
                      }}
                    >
                      {i?.candidateDetail?.map((index, idx) =>
                        index?.bookingService?.status === "confirmed" ? (
                          <>
                            <div
                              className="myp-wrapper2"
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                padding: "10px",
                                border: "1px solid #E3E2E6",
                                width: "49%",
                                marginTop: "10px",
                                position: "relative",
                              }}
                            >
                              <div key={idx} style={{ display: "flex" }}>
                                <img
                                  src={
                                    index.aboutDetail?.avatar
                                      ? index.aboutDetail.avatar
                                      : ServiceImage
                                  }
                                  style={{
                                    width: "60px",
                                    height: "60px",
                                    borderRadius: "50px",
                                  }}
                                  alt="uihhs"
                                />
                                <div style={{}}>
                                  <p className="req-name">
                                    {index.aboutDetail?.firstName}{" "}
                                    {index.aboutDetail?.middleName}{" "}
                                    {index.aboutDetail?.lastName}
                                  </p>

                                  {index?.experienceDetail
                                    .filter(
                                      (exp) =>
                                        !exp.endDate && exp.timestamps.createdAt
                                    )
                                    .sort(
                                      (exp1, exp2) =>
                                        new Date(exp2.timestamps.createdAt) -
                                        new Date(exp1.timestamps.createdAt)
                                    )
                                    .slice(0, 1)
                                    .map((a) => {
                                      return (
                                        <p className="req-desig">
                                          {a.designation}
                                        </p>
                                      );
                                    })}

                                  <button
                                    style={{ cursor: "pointer" }}
                                    className="req-icon"
                                    data-titles="you will unable to decline or reschedule the interview within 24 hours of the scheduled time."
                                  >
                                    i
                                  </button>
                                </div>
                                <div className="int-star-pos">
                                  <img src={Star} alt="img" />
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    0
                                  </div>
                                </div>
                              </div>

                              {/* <div>
                            <p>{index.bookingService?.description}</p>
                          </div> */}
                              <div
                                style={{
                                  display: "flex",
                                  gap: "0px",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    margin: "20px 0 0 0px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>
                                      <div
                                        style={{ display: "flex", gap: "5px" }}
                                      >
                                        <label className="Int-title">
                                          Title:
                                        </label>
                                        <div className="Int-title">
                                          {index.bookingService?.serviceName}
                                        </div>
                                      </div>
                                      <div
                                        style={{ display: "flex", gap: "5px" }}
                                      >
                                        <label className="Int-title">
                                          Designation:
                                        </label>

                                        <div className="Int-title">
                                          {index.bookingService?.designation}
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <div>
                                        <p
                                          className="req-name"
                                          style={{
                                            fontSize: "14px",
                                            margin: "5px 0",
                                          }}
                                        >
                                          {`${formatDate(
                                            index.bookingService?.date
                                          )}`}
                                        </p>
                                      </div>
                                      <div>
                                        {index.bookingService
                                          ?.unselectedTimeSlots.length > 0 ? (
                                          <p
                                            className="req-name"
                                            style={{
                                              fontSize: "14px",
                                              margin: "5px 0",
                                            }}
                                          >
                                            {`${formatDates(
                                              index.bookingService?.date
                                            )}`}{" "}
                                            {`${index.bookingService?.time}`} -{" "}
                                            {`${index.bookingService?.unselectedTimeSlots[0]}`}
                                          </p>
                                        ) : (
                                          <p>-</p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <hr
                                    style={{ marginLeft: "0px", width: "100%" }}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "10px",
                                      justifyContent: "space-between",
                                      marginLeft: "0px",
                                      width: "100%",
                                    }}
                                  >
                                    <div className="int-re-wrap">
                                      <div className="int-re-wrap-in">
                                        <img src={iClock} alt="img" />
                                        <p className="int-duration">
                                          {index.bookingService?.duration}
                                        </p>
                                      </div>
                                      {index.bookingService?.isFree ? (
                                        <div className="int-re-wrap-in">
                                          <img src={iSalary} alt="img" />
                                          <p className="int-duration">Free</p>
                                        </div>
                                      ) : (
                                        <div className="int-re-wrap-in">
                                          <img src={iSalary} alt="img" />
                                          <p className="int-duration">
                                            {index.bookingService?.price}
                                          </p>
                                        </div>
                                      )}
                                      <a
                                        href={index.bookingService?.resume}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleDownload(
                                            index.bookingService?.resume,
                                            index.aboutDetail
                                          );
                                        }}
                                        className="int-duration"
                                      >
                                        <img
                                          src={Download}
                                          alt="img"
                                          style={{ width: "16px" }}
                                        />{" "}
                                        Resume
                                      </a>
                                    </div>
                                    <div>
                                      <button
                                        className={
                                          isExpired(
                                            index.bookingService?.date,
                                            index.bookingService?.time
                                          )
                                            ? "int-exp"
                                            : "int-decline"
                                        }
                                        onClick={() =>
                                          openDecline(index.bookingService?._id)
                                        }
                                        disabled={isExpired(
                                          index.bookingService?.date,
                                          index.bookingService?.time
                                        )}
                                      >
                                        {isExpired(
                                          index.bookingService?.date,
                                          index.bookingService?.time
                                        ) ? (
                                          "Expired"
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              gap: "5px",
                                            }}
                                          >
                                            Decline{" "}
                                            <img src={Decline} alt="img" />
                                          </div>
                                        )}
                                      </button>
                                      <button
                                        className={
                                          isExpiredOne(
                                            index.bookingService?.date,
                                            index.bookingService?.time
                                          )
                                            ? "none"
                                            : "block"
                                        }
                                        onClick={() => {
                                          openReschedules();
                                          setRescheduleId(
                                            index.bookingService?._id
                                          );
                                        }}
                                        disabled={isExpiredOne(
                                          index.bookingService?.date,
                                          index.bookingService?.time
                                        )}
                                      >
                                        {isExpiredOne(
                                          index.bookingService?.date,
                                          index.bookingService?.time
                                        ) ? (
                                          ""
                                        ) : (
                                          <div className="int-res">
                                            Reschedule{" "}
                                          </div>
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {data &&
                  data.map((i, index) => {
                    const isOpen = openDropdownMap[i._id] || false;
                    return (
                      <div key={index}>
                        <div
                          className={
                            openForm
                              ? "job-card-wrap myp-int-wrap "
                              : "job-card-wrapper"
                          }
                        >
                          <div className="int-tit-save">
                            <div className="int-tit-dur-wrapper">
                              <Preference
                                style={{ cursor: "pointer" }}
                                onClick={() => handleOpen(i)}
                              >
                                {i.interviewTitle}
                              </Preference>
                              <div
                                style={{
                                  marginTop: "15px",
                                  fontSize: "12px",
                                  fontweight: "normal",
                                  color: "rgba(168, 161, 172, 1)",
                                  display: "flex",
                                }}
                              >
                                {i.inrService ? (
                                  <div className="b-price">Free</div>
                                ) : (
                                  <div className="b-price">
                                    <FaRupeeSign />
                                    {new Intl.NumberFormat("en-IN", {}).format(
                                      i?.inr
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <Dots>
                            <BsThreeDots
                              style={{
                                width: "18px",
                                height: "18px",
                                borderRadius: "50%",
                              }}
                              onClick={() => dropdown(i._id)}
                            />

                            {isOpen && (
                              <Dropdown>
                                {value === "active" ? (
                                  <DropdownList>
                                    <DropdownItem
                                      onClick={() =>
                                        updateArchiveInterviewStatus(i._id)
                                      }
                                      style={{
                                        display: "flex",
                                        borderRadius: "8px",
                                        justifyContent: "center",
                                        padding: "4px",
                                      }}
                                    >
                                      <DropdownContent>
                                        Move to archived interviews
                                      </DropdownContent>
                                    </DropdownItem>

                                    <DropdownItem
                                      onClick={() =>
                                        updateInActiveStatus(i._id)
                                      }
                                      style={{
                                        display: "flex",
                                        borderRadius: "8px",
                                        justifyContent: "center",
                                        gap: "10px",
                                        padding: "4px",
                                      }}
                                    >
                                      <DropdownContent>
                                        Move to inactive interviews
                                      </DropdownContent>
                                    </DropdownItem>
                                  </DropdownList>
                                ) : value === "archived" ? (
                                  <DropdownList>
                                    <DropdownItem
                                      onClick={() =>
                                        updateInActiveStatus(i._id)
                                      }
                                      style={{
                                        display: "flex",
                                        borderRadius: "8px",
                                        justifyContent: "center",
                                        padding: "0px 8px",
                                      }}
                                    >
                                      <DropdownContent>
                                        Move to inactive interviews
                                      </DropdownContent>
                                    </DropdownItem>
                                    <DropdownItems
                                      onClick={() => handleDelete(i._id)}
                                      style={{ display: "flex", gap: "10px" }}
                                    >
                                      <DropdownContent
                                        style={{
                                          color: "rgba(253, 33, 69, 1)",
                                        }}
                                      >
                                        Delete
                                      </DropdownContent>
                                    </DropdownItems>
                                  </DropdownList>
                                ) : (
                                  <DropdownList>
                                    <DropdownItem
                                      onClick={() => editOpen(i._id)}
                                      style={{ display: "flex", gap: "10px" }}
                                    >
                                      <DropdownContent>Edit</DropdownContent>
                                    </DropdownItem>

                                    <DropdownItem
                                      onClick={() =>
                                        updateArchiveInterviewStatus(i._id)
                                      }
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        borderRadius: "8px",
                                        justifyContent: "center",
                                        gap: "10px",
                                        padding: "8px",
                                      }}
                                    >
                                      <DropdownContent>
                                        Move to archived interviews
                                      </DropdownContent>
                                    </DropdownItem>
                                  </DropdownList>
                                )}
                              </Dropdown>
                            )}
                          </Dots>
                          <div className="">
                            <div
                              className={
                                openForm ? "doc-pend-wrap" : "doc-pend-wraps"
                              }
                            >
                              {input?.inputList?.length > 0 &&
                              input?.inputList[0].proficiency !== "" &&
                              input?.inputList2?.length > 0 &&
                              input?.inputList3[0]?.resume &&
                              experience?.length > 0 &&
                              education?.length > 0 &&
                              accountDetails?.length > 0 ? (
                                i?.status === "pending" ? (
                                  <button
                                    data-title="Interview will be posted once verified"
                                    className="button8"
                                    style={{}}
                                  >
                                    Verification In-process
                                    <img
                                      src={Pause}
                                      alt="img"
                                      className="btn-wrappers"
                                    />
                                  </button>
                                ) : i?.status === "verified" ? (
                                  <button className="button8" style={{}}>
                                    <img
                                      src={Verified}
                                      alt="img"
                                      className="btn-wrappers"
                                    />
                                    Verified
                                  </button>
                                ) : i?.status === "rejected" ? (
                                  <button
                                    data-title="Your interview has been not verified. Kindly reach out to support@thexakal.com"
                                    className="button8"
                                  >
                                    <img
                                      src={Pending}
                                      alt="img"
                                      className="btn-wrappers"
                                    />
                                    Not Verified
                                  </button>
                                ) : (
                                  <></>
                                )
                              ) : (
                                <button
                                  data-title="Click here to complete documentation"
                                  className="button8"
                                  style={{}}
                                  onClick={() => {
                                    setStatusPreview(true);
                                  }}
                                >
                                  Documentation Pending
                                  <img
                                    src={Pause}
                                    alt="img"
                                    className="btn-wrappers"
                                  />
                                </button>
                              )}

                              <div className="user_info">
                                Interview will be posted once verified
                                <button>Got it </button>
                              </div>
                            </div>
                          </div>

                          <div>
                            <PreferenceWrapper>
                              <Designation>Designation :</Designation>
                              {openForm ? (
                                <>
                                  {i.designation
                                    .slice(0, 2)
                                    .map((item, index) => (
                                      <div
                                        key={index}
                                        className={
                                          openForm
                                            ? "desig-lists"
                                            : "desig-list"
                                        }
                                      >
                                        {item}
                                      </div>
                                    ))}

                                  {i.designation.length > 2 ? (
                                    <JobPreference
                                      onClick={() => handleOpen(i)}
                                    >
                                      +{i.designation.length - 2}
                                    </JobPreference>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                <>
                                  {i.designation
                                    .slice(0, 3)
                                    .map((item, index) => (
                                      <div
                                        key={index}
                                        className={
                                          openForm
                                            ? "desig-lists"
                                            : "desig-list"
                                        }
                                      >
                                        {item}
                                      </div>
                                    ))}

                                  {i.designation.length > 3 ? (
                                    <JobPreference
                                      onClick={() => handleOpen(i)}
                                    >
                                      +{i.designation.length - 3}
                                    </JobPreference>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}
                            </PreferenceWrapper>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          ></div>
                        </div>
                      </div>
                    );
                  })}
              </>
            )}
          </>
        )}
      </div>

      <Dialog
        open={openService}
        PaperProps={{
          style: {
            width: "100%",
            // height: "800px",
            borderRadius: "24px",
            maxWidth: "415px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <div className="modalHeader">
          <div>
            <Title>Create an Interview</Title>
          </div>
          <div>
            <img
              src={CloseIcon}
              alt="img"
              onClick={() => setOpenService(false)}
            />
          </div>
        </div>

        {/* <DialogContent> */}
        <ServicePopup
          onCloseAll={previewFormId}
          onPopup={closeCreateService}
          onService={getAllInterviewService}
          previewId={previewFormOpen}
          onClick={() => handleAvailabilityButtonClick(true)}
        />
        {/* </DialogContent> */}
      </Dialog>
      {selectedData && openForm ? (
        <div
          className={
            // isScrolled ?
            "int-previews-p"
            // : "int-previews-pr"
          }
        >
          <div>
            <InterviewPopup
              onPopupData={selectedData}
              onClose={closeForm}
              openDateForm={openDateForm}
              inputId={inputFormInput}
              // onClosse={openClose}
              closeButton={button}
              callAlls={getAllInterviewService(activeTab)}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <Dialog
        open={openDate}
        PaperProps={{
          style: {
            // width: "100%",
            height: "650px",
            borderRadius: "24px",
            maxWidth: "560px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <ExploreTimeZone
            id={inputId}
            interviewId={ids}
            open={openConfirmPopup}
            onClose={() => setOpenDate(false)}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openConfirm}
        PaperProps={{
          style: {
            width: "100%",
            height: "auto",
            borderRadius: "24px",
            maxWidth: "660px",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <SlotInterview
            inputId={inputId}
            openFirst={() => openFirst(inputId)}
            slotId={slotId}
            close={close}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={open4}
        PaperProps={{
          style: {
            borderRadius: "20px",
            top: "10px",
            transform: "translateY(0%)",
            position: "absolute",
            backgroundColor: "#FFDAE0",
            // height: "56px",
            padding: "5px 8px",
          },
        }}
        fullWidth
        maxWidth="sm"
        // className="def"
      >
        <DialogContent
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
          }}
        >
          <div
            style={{
              width: "40px",
              height: "34px",
              fontFamily: "Inter-Medium,sans-serif",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: " 8px",
              background: "white",
            }}
          >
            <img src={DeleteImg} alt="img" />
          </div>
          <JobTitle
            style={{
              fontFamily: "Inter-Medium,sans-serif",
              fontSize: "14px",
              fontStyle: "normal",
              fontweight: "normal",
              gap: "10px",
              display: "flex",
              color: "black",
            }}
          >
            Are you sure you want to delete this interview?
          </JobTitle>
          <DialogActions>
            <div style={{ position: "relative", display: "flex", gap: "10px" }}>
              <Button5
                onClick={() => setOpen4(false)}
                style={{ cursor: "pointer" }}
              >
                Cancel
              </Button5>
              <Button6 onClick={() => deleteInterviewJob(deleteId)}>
                Yes, Delete
              </Button6>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openAvailability}
        PaperProps={{
          style: {
            width: "100%",
            height: "auto",
            borderRadius: "24px",
            maxWidth: "470px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <div className="modalHeader">
          <Title>Manage Availability</Title>

          <div>
            <img
              src={CloseIcon}
              alt="img"
              onClick={() => setAvailability(false)}
              style={{ marginTop: "0px" }}
            />
          </div>
        </div>
        <DialogContent>
          <AvailabilityTimePicker
            handleChange={availabiltyCancel}
            onId={editId}
            onGetAll={getAllAvialability}
            onCloseAvaial={closeAvail}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openServiceUpdate}
        PaperProps={{
          style: {
            width: "100%",
            // height: "800px",
            borderRadius: "24px",
            maxWidth: "415px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        {/* <TitleHeadar> */}
        <div className="modalHeader">
          <Title>Create an Interview</Title>
          <img
            src={CloseIcon}
            alt="img"
            onClick={() => setOpenServiceUpdate(false)}
          />
        </div>
        {/* </TitleHeadar> */}

        <DialogContent>
          <UpdateServicePopup
            onPopup={closeServiceUpdate}
            onService={getAllInterviewService}
            id={editedData ? editedData : null}
            previewId={previewFormOpen}
            onCloseAll={previewFormId}
            previewInterviewId={previewId}
            onClick={() => handleAvailabilityButtonClick(true)}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={previewForm}
        PaperProps={{
          style: {
            width: "100%",
            height: "auto",
            borderRadius: "24px",
            maxWidth: "570px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <PreviewPopup
            previewId={previewId}
            onClose={previewClose}
            onBack={goToService}
            onService={getAllInterviewService}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={statusPreview}
        PaperProps={{
          style: {
            width: "100%",
            height: "auto",
            borderRadius: "24px",
            maxWidth: "570px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <div className="modalHeader">
          <div>
            <Title>Complete your profile</Title>
          </div>
          <div>
            <img src={CloseIcon} alt="img" onClick={remove} />
          </div>
        </div>
        <DialogContent>
          <StatusPreview
            userId={input?.userId}
            handleSection={() => handleSection()}
            handleSkill={handleSkill}
            handleClose={() => handleClose()}
            input={input}
            experiencePreview={experience}
            educationPreview={education}
            accountDetailPreview={accountDetail}
            openPreview={statusPreview}
            enableService={enableService}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={declineOpen}
        PaperProps={{
          style: {
            maxWidth: "480px",
            // height: "350px",
            borderRadius: "24px",
            // maxWidth: "540px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <div className="modalBody">
          <label className="mTitle">Reason For Declining?</label>
          <Select
            options={reasonOptions}
            value={reasonOptions.find((option) => option.value === reason)}
            onChange={handleReason}
            className="footer-header font"
            styles={customStyle}
          />

          <div>
            <label style={{ color: "#4f4953", marginTop: "10px" }}>
              Description (optional)
            </label>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Enter your description"
                aria-label="Bank Name"
                name="description"
                aria-describedby="basic-addon1"
                className="font"
                style={{
                  borderRadius: "8px",
                  background: "#F4F4F6",
                  height: "100px",
                  position: "relative",
                  width: "520px",
                  // marginLeft: "20px",
                  // marginRight: "20px",
                  color: "#362A41",
                }}
                onChange={(e) => setDeclineDescription(e.target.value)}
              />
            </InputGroup>
          </div>
        </div>
        <div
          className="modalFooter"
          style={{
            position: "relative",
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
          }}
        >
          <button
            className="button5"
            onClick={() => setDeclineOpen(false)}
            style={{ cursor: "pointer" }}
          >
            Cancel
          </button>
          <button className="button3" onClick={() => handleDecline(declineId)}>
            Submit
          </button>
        </div>
      </Dialog>
    </>
  );
};

export default Service;
