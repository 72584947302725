import { useEffect, useRef, useState } from "react";
import "./message.css";
import companyLogo from "../../images/profile-image.svg";
import "./mes.css";
import axios from "axios";
import { FaRegFilePdf, FaFileExcel, FaFilePowerpoint } from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";
import InputGroup from "react-bootstrap/InputGroup";
import { LuSendHorizonal } from "react-icons/lu";
import { IoIosAttach } from "react-icons/io";
import { io } from "socket.io-client";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { GoSmiley } from "react-icons/go";
import { BiTrash } from "react-icons/bi";
import { FaReply } from "react-icons/fa";
import ImagePreviewModal from "./imagePreviewModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const MessageChat = ({
  userId,
  messageChats,
  id,
  onCallMessage,
  otherProfile,
}) => {
  const [hoveredMessageId, setHoveredMessageId] = useState(null);
  const [mainMessage, setMainMessage] = useState([]);
  const [mainArrival, setMainArrival] = useState([]);

  const handleMouseEnter = (messageId) => {
    setHoveredMessageId(messageId);
  };

  const handleMouseLeave = () => {
    setHoveredMessageId(null);
  };
  //call all messages
  const getAllMessage = (messageChats) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/message/${messageChats}`)
      .then((res) => {
        setMainMessage(res.data.message);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    if (messageChats) {
      getAllMessage(messageChats?._id);
    }
  }, [messageChats, messageChats?._id]);
  const TimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState("");

    useEffect(() => {
      const calculateTimeAgo = () => {
        const currentTime = new Date();
        const postedTime = new Date(timestamp);
        const timeDifference = currentTime - postedTime;

        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));

        if (minutes < 60) {
          setTimeAgo(`${minutes} minute${minutes === 1 ? "" : "s"} ago`);
        } else if (hours < 24) {
          setTimeAgo(`${hours} hour${hours === 1 ? "" : "s"} ago`);
        } else if (days < 30) {
          setTimeAgo(`${days} day${days === 1 ? "" : "s"} ago`);
        } else if (months < 12) {
          setTimeAgo(`${months} month${months === 1 ? "" : "s"} ago`);
        } else {
          setTimeAgo(`${years} year${years === 1 ? "" : "s"} ago`);
        }
      };

      calculateTimeAgo();
    }, [timestamp]);

    return <span>{timeAgo}</span>;
  };

  const [user, setUsers] = useState({});
  const friendIds = Array.from(new Set(mainMessage?.map((m) => m.sender)));

  const getUserChat = async () => {
    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/get-user-multiple`,
        { ids: friendIds }
      );
      if (response.data && response.data.aboutDetails) {
        const usersData = response.data.aboutDetails;
        const usersMap = usersData.reduce(
          (acc, user) => ({ ...acc, [user.userId]: user }),
          {}
        );
        setUsers(usersMap);
      } else {
        console.error("User data not found in response");
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getUserChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mainArrival && messageChats?.members?.includes(mainArrival.sender)) {
      setMainMessage((prev) => [...prev, mainArrival]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainArrival, messageChats]);

  useEffect(() => {
    // socket.current = io("ws://localhost:4000");
    socket.current = io("wss://test-staging.thexakal.com");
    socket.current.on("getMessage", (data) => {
      const timestamp = {
        createdAt: new Date(),
      };
      setMainArrival({
        sender: data.senderId,
        text: data.text,
        image: data?.image,
        timestamps: timestamp,
      });
    });
    socket.current.on("deleteMessage", (deletedMessageId) => {
      setMainMessage((prevMessages) =>
        prevMessages.filter((message) => message._id !== deletedMessageId)
      );
    });
    socket.current.emit("addUser", userId);
    socket.current.on("getUsers", (users) => {});

    return () => {
      socket.current.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    socket.current.emit("addUser", userId);
    socket.current.on("getUsers", (users) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fileImage = [".pdf", ".doc", ".docx", ".ppt", ".xls", ".xlsx", ".pptx"];
  const fileText = [".png", ".jpg", ".jpeg", ".gif"];
  const [text, setText] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedEmojis, setSelectedEmojis] = useState([]);
  const socket = useRef();
  const [isVisibleOpen, setIsVisibleOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const handleReply = (message) => {
    setSelectedMessage(message);
    setText(`Reply to: ${message.text}`);
  };
  const textareaRef = useRef(null);
  // const handleEmojiSelect = (emoji) => {
  //   const selectedEmoji = emoji.native; // Extract the native property from the emoji object
  //   setSelectedEmojis([...selectedEmojis, selectedEmoji]); // Add the selected emoji to the array
  // };
  const handleEmojiSelect = (emoji) => {
    const selectedEmoji = emoji.native; // Extract the native property from the emoji object

    // Get the current cursor position
    const cursorPosition = textareaRef.current.selectionStart;

    // Get the current textarea value
    const value = text || "";

    // Insert the selected emoji at the cursor position
    const newValue =
      value.substring(0, cursorPosition) +
      selectedEmoji +
      value.substring(cursorPosition);

    // Update the textarea value
    setText(newValue);
  };
  const sendMessage = () => {
    // Assuming text and selectedEmojis are defined elsewhere
    const messageWithEmojis =
      text + (selectedEmojis.length > 0 ? ` ${selectedEmojis.join(" ")}` : "");
    let completeMessage = messageWithEmojis;

    if (selectedMessage) {
      const replyToMessage = `${selectedMessage.text}`;
      completeMessage = `${replyToMessage}\n${messageWithEmojis}`;
    }

    const payload = {
      conversationId: messageChats?._id,
      sender: userId,
      text: completeMessage,
    };
    const receiverId = messageChats?.members?.find((m) => m !== userId);

    const sendPayload = (fileUrl = "") => {
      const completePayload = {
        ...payload,
        text: payload.text ? payload.text : "",
        image: fileUrl,
      };

      socket.current?.emit("sendMessage", {
        senderId: userId,
        receiverId,
        text: completePayload.text, // Use the text from completePayload
        image: completePayload.image,
      });

      axios
        .post(`${process.env.PUBLIC_URL}/xakal/message`, completePayload)
        .then((res) => {
          setMainMessage((prevMessages) => [...prevMessages, res.data.message]);
          setText("");
          setSelectedFile(null);
          setIsVisibleOpen(false);
        })
        .catch((err) => {
          console.error(err);
        });
    };

    if (selectedFile) {
      const formData = new FormData();
      formData.append("image", selectedFile);

      axios
        .post(`${process.env.PUBLIC_URL}/xakal/files/`, formData)
        .then((res) => {
          const fileUrl = res.data.url; // Adjust based on actual response structure
          sendPayload(fileUrl);
        })
        .catch((err) => {
          console.error(err);
          sendPayload(); // Still send the message even if file upload fails
        });
    } else {
      sendPayload();
    }
  };

  const handleSendMessage = () => {
    sendMessage();

    setSelectedEmojis([]); // Clear selected emojis after sending the message
  };
  const [filePreview, setFilePreview] = useState("");
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setFilePreview(URL.createObjectURL(file)); // Set the preview URL
  };
  const handleIconClick = () => {
    document.getElementById("fileInput").click();
  };
  const deleteMessage = (id) => {
    axios
      .delete(`${process.env.PUBLIC_URL}/xakal/delete-message/${id}`)
      .then((res) => {
        socket.current.emit("deleteMessage", id); // Emit delete event
        onCallMessage(messageChats?._id);
      })
      .catch((err) => {});
  };
  const [openImage, setOpenImage] = useState(false);
  const [imageToOpen, setImageToOpen] = useState(null);

  const handleOpenImage = (image) => {
    setImageToOpen(image);
    setOpenImage(true);
  };
  const handleDownloadFile = (fileUrl) => {
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = fileUrl;
    anchor.target = "_blank";
    anchor.click();
  };
  const history = useHistory();
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey && text.trim() !== "") {
      sendMessage();
    } else if (event.key === "Enter" && event.shiftKey) {
      setText((prevText) => prevText + "\n");
    }
  };
  const handleOnChange = (e) => {
    const inputValue = e.target.value;
    const maxCharactersPerLine = 50; 
    const lines = inputValue.split("\n");
    const truncatedLines = lines.map((line) => {
      if (line.length > maxCharactersPerLine) {
        const chunks = [];
        while (line.length > 0) {
          chunks.push(line.slice(0, maxCharactersPerLine));
          line = line.slice(maxCharactersPerLine);
        }
        return chunks.join("\n");
      } else {
        return line;
      }
    });
    // Join the truncated lines back into a single string with newline characters
    const newText = truncatedLines.join("\n");
  
    setText(newText);
  };
  
  return (
    <>
      <div className="main-chat-message">
        {mainMessage?.map((c) => {
          const isOwnMessage = c?.sender === userId;
          const messageUser = user[c?.sender];

          // Check if the message contains a link to an image
          const isImage =
            c?.image &&
            fileText.some((ext) => c?.image.toLowerCase().endsWith(ext));
          const isDocument =
            c?.image &&
            fileImage.some((ext) => c?.image.toLowerCase().endsWith(ext));
          const text = c?.text;
          return (
            <div
              className={isOwnMessage ? "message own" : "message"}
              key={c._id}
            >
              <div
                className={`messageTop ${
                  hoveredMessageId === c._id ? "hovered" : ""
                }`}
                onMouseEnter={() => handleMouseEnter(c._id)}
                onMouseLeave={handleMouseLeave}
              >
                {isOwnMessage ? (
                  <img
                    className="messageImg"
                    src={messageUser ? messageUser.avatar : companyLogo}
                    alt=""
                    onClick={() => history.push(`/portal/my-profile`)}
                  />
                ) : (
                  <img
                    className="messageImg"
                    src={messageUser ? messageUser.avatar : companyLogo}
                    alt=""
                    onClick={() =>
                      history.push(`/portal/otherProfile/${c?.sender}`)
                    }
                  />
                )}

                {text === "undefined" ? null : (
                  <span className="messageText">{text}</span>
                )}
                {hoveredMessageId === c._id && (
                  <div>
                    <span onClick={() => deleteMessage(c?._id)}>
                      <BiTrash size={20} />
                    </span>
                    <span onClick={() => handleReply(c)}>
                      <FaReply size={20} />
                    </span>
                  </div>
                )}
                {isImage && (
                  <img
                    src={c?.image}
                    alt="attached"
                    className="img-width-message messageText"
                    onClick={() => handleOpenImage(c?.image)}
                  />
                )}

                {isDocument && (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDownloadFile(c?.image)}
                  >
                    {c?.image.toLowerCase().endsWith(".pdf") ? (
                      <FaRegFilePdf size={30} />
                    ) : c?.image.toLowerCase().endsWith(".xls") ||
                      c?.image.toLowerCase().endsWith(".xlsx") ? (
                      <FaFileExcel size={30} />
                    ) : c?.image.toLowerCase().endsWith(".ppt") ||
                      c?.image.toLowerCase().endsWith(".pptx") ? (
                      <FaFilePowerpoint size={30} />
                    ) : (
                      <IoDocumentTextOutline size={30} />
                    )}
                  </div>
                )}
              </div>
              <div className="messageBottom">
                <TimeAgo timestamp={c?.timestamps?.createdAt} />
              </div>
            </div>
          );
        })}
      </div>
      <>
        <div className="chatBoxBottom">
          <InputGroup className="footer-header mb-1">
            <GoSmiley
              size={20}
              className="chat-smile"
              onClick={() => setIsVisibleOpen(!isVisibleOpen)}
            />

            <textarea
              placeholder="Type the message"
              ref={textareaRef}
              className="footer-header mb-1 font"
              aria-label="First name"
              aria-describedby="basic-addon1"
              type="text"
              rows={10}
              value={text}
              onChange={handleOnChange}
              onKeyDown={handleKeyDown}
            ></textarea>
            <div className="selectedInfo">
              <p>{selectedEmojis}</p>
              {selectedFile && (
                <>
                  {selectedFile.type.startsWith("image/") && ( // Check if the file is an image
                    <img
                      src={filePreview}
                      alt="Selected File"
                      style={{ maxWidth: "100px", maxHeight: "100px" }}
                    />
                  )}
                  {!selectedFile.type.startsWith("image/") && ( // Display file name if not an image
                    <InputGroup.Text>{selectedFile.name}</InputGroup.Text>
                  )}
                </>
              )}
            </div>
            <InputGroup.Text
              onClick={handleIconClick}
              style={{ cursor: "pointer" }}
            >
              <IoIosAttach size={20} />
            </InputGroup.Text>
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </InputGroup>
          <button className="chatSubmitButton" onClick={handleSendMessage}>
            <LuSendHorizonal size={20} />
          </button>
        </div>

        <div className={isVisibleOpen ? "d-block" : "d-none"}>
          <Picker
            data={data}
            previewPosition="none"
            onEmojiSelect={handleEmojiSelect}
          />
        </div>
      </>
      <ImagePreviewModal
        isOpen={openImage}
        onClose={() => setOpenImage(false)}
        imageSrc={imageToOpen}
        title="Image Preview"
      />
    </>
  );
};

export default MessageChat;
