/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import "../../styles/myprofile.css";
import styled from "styled-components";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import DownArrow from "../../images/Downarrow.svg";
import "./service.Popup.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const customStyle = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    height: "fit-content",
    minHeight: "48px",
    border: "none",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    fontweight: "normal",
    // fontStyle: "italic",
    color: "#362A41",
  }),
  menu: (provided) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    "&:hover": {
      color: "#362A41", // Add hover text color
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 6px)",
      marginLeft: "5px",
    },
  }),
  // Add more styles as needed
};

const ButtonLink = styled.div`
  width: 106px;
  height: 48px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
  border: 1px solid rgba(221, 223, 228, 0.5);
  cursor: pointer;
  display: flex;
  gap: 5px;
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 14.4px */
  text-transform: capitalize;
  img {
    width: 16px;
    height: 16px;
  }
  @media (max-width: 600px) {
    width: 100%;
    margin-top: 10px;
  }
`;

const JobPreference = styled.div`
  // color: black;
  background: #f4e8ff;
  padding: 5px 10px;
  height: 30px;
  border-radius: 5px;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
`;
const Label = styled.div`
  font-size: 14px;
  color: #4f4953;
  line-height: 17px;
  font-family: Inter-Medium, sans-serif;
  font-weight: normal;
  margin: 25px 0 5px 0;
  span {
    color: #ff4873;
    margin-left: 2px;
  }
`;
const DataDiv = styled.div`
  display: flex;
  gap: 10px;
  margin-left: 10px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
`;
const durationOptions = [{ value: "30 minutes", label: "30 minutes" }];
const ServicePopup = ({
  onPopup,
  id,
  onCloseAll,
  previewInterviewId,
  previewId,
  onClick,
}) => {
  const [user, setUser] = useState();
  const [skill, setSkill] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [title, setTitle] = useState("");
  const [duration, setDuration] = useState("");
  const [service, setService] = useState("");
  const [description, setDescription] = useState("");
  const [isService, setIsService] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [dataId, setIdData] = useState(null);

  const [imageURL, setImageURL] = useState([]);
  const [validationErrors, setValidationErrors] = useState({
    interviewTitle: "",
    duration: "",
    service: "",
    skill: "",
    designation: "",
  });

  const processValidFiles = (validFiles, filesInfo) => {
    // eslint-disable-next-line no-unused-vars
    const updatedImageURL = validFiles.map((file, index) => ({
      url: process.env.BASE_FILE_URL + filesInfo[index].Key,
      fileType: file.type,
    }));

    // Use updatedImageURL as needed
  };

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
    setValidationErrors({ ...validationErrors, interviewTitle: "" });
  };

  const handleChangeDuration = (event) => {
    const selectedValue = event.value;
    setDuration(selectedValue);
    setValidationErrors({ ...validationErrors, duration: "" });
  };
  const handleChangeService = (e) => {
    if (!isService) {
      const enteredValue = e.target.value;

      // Check if the entered value is an integer or an empty string
      const isIntegerOrEmpty =
        enteredValue === "" || /^\d+$/.test(enteredValue);

      if (isIntegerOrEmpty) {
        setService(enteredValue === "" ? "" : parseInt(enteredValue, 10));
        setValidationErrors({ ...validationErrors, service: "" });
      } else {
        setValidationErrors({
          ...validationErrors,
          service: "Please enter a valid integer.",
        });
      }
    }
  };

  const handleChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const getUser = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuser`)
      .then((response) => {
        setUser(response.data);
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    getUser();
  }, []);
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleAddInput = () => {
    if (designation.length < 25 && inputValue) {
      setDesignation([...designation, inputValue]);
      setInputValue("");
      setValidationErrors({ designation: "" });
    } else if (designation.length >= 25) {
    }
  };
  const handleRemoveInput = (index) => {
    const updatedValues = [...designation];
    updatedValues.splice(index, 1);
    setDesignation(updatedValues);
  };
  const handleCheckboxChange = () => {
    setIsService(!isService);
    setService("");
  };

  useEffect(() => {
    if (id) {
      axios
        .get(`${process.env.PUBLIC_URL}/xakal/get-one-interview/${id}`)
        .then((response) => {
          const responseData = response.data.interview;
          setIdData(responseData._id);
          setTitle(responseData.interviewTitle);
          setDuration(responseData.duration);
          setService(responseData.inr);
          setDescription(responseData.description);
          setIsService(responseData.inrService);
          if (responseData && responseData.image.length > 0) {
            setImageURL(responseData.image);
          }
          if (responseData && responseData.skill.length > 0) {
            setSkill(responseData.skill);
          }
          if (responseData && responseData.designation.length > 0) {
            setDesignation(responseData.designation);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);
  useEffect(() => {
    if (previewInterviewId) {
      axios
        .get(
          `${process.env.PUBLIC_URL}/xakal/get-one-interview/${previewInterviewId}`
        )
        .then((response) => {
          const responseData = response.data.interview;
          setIdData(responseData._id);
          setTitle(responseData.interviewTitle);
          setDuration(responseData.duration);
          setService(responseData.inr);
          setDescription(responseData.description);
          // setImageURL(responseData.image)
          setIsService(responseData.inrService);

          setImageURL(responseData.image || []);
          if (responseData && responseData.skill.length > 0) {
            setSkill(responseData.skill);
          }
          if (responseData && responseData.designation.length > 0) {
            setDesignation(responseData.designation);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);
  const isFormValid = () => {
    const isValid = title && duration;
    const isOne = designation.length > 0 || inputValue.trim() !== "";
    if (!isValid) {
      setValidationErrors({
        interviewTitle: title ? "" : "Please enter headline",
        duration: duration ? "" : "Please select duration",
        service: service ? "" : "Please enter service fee",
        designation: isOne ? "" : "Please enter the designation",
      });
    }

    return isValid;
  };

  const handleSubmit = async () => {
    try {
      if (!inputValue && designation.length === 0) {
        setValidationErrors({
          designation: "Please enter a Designation",
          interviewTitle: title ? "" : "Please enter headline",
          duration: duration ? "" : "Please select duration",
          service: service ? "" : "Please enter service fee",
        });
        return; // Exit the function if validation fails
      }

      // Check if inputValue is not empty but handleAddInput was not called
      if (inputValue && designation.indexOf(inputValue) === -1) {
        setValidationErrors({
          designation: "Please add the designation ",
        });
        return; // Exit the function if validation fails
      }

      setValidationErrors({
        designation: "",
        interviewTitle: "",
        duration: "",
        service: "",
      });
      if (imageURL.length > 0) {
        const formData = new FormData();

        for (let i = 0; i < imageURL.length; i++) {
          formData.append(`image`, imageURL[i]);
        }

        const uploadResponse = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/files/multi`,
          formData
        );
        const mediaURLs = uploadResponse.data.urls;
        toast.error(uploadResponse.msg);
        const updatedImageURL = mediaURLs.map((url, index) => ({
          url,
          fileType: imageURL[index].type,
        }));

        processValidFiles(imageURL, uploadResponse.data.files);

        if (isFormValid()) {
          const payload = {
            interviewTitle: title,
            designation: designation,
            skill: skill,
            duration: duration,
            inr: service,
            inrService: isService,
            image: updatedImageURL,
            description: description,
            interviewerUserId: user?.userId,
          };

          const response = await axios.post(
            `${process.env.PUBLIC_URL}/xakal/create-interview`,
            payload
          );
          const data = response.data.interview._id;
          onPopup();
          onCloseAll();
          previewId(data.toString());
          setValidationErrors({ designation: "Please enter the Designation" });
        }
      } else {
        if (isFormValid()) {
          const payloadWithoutImage = {
            interviewTitle: title,
            designation: designation,
            skill: skill,
            duration: duration,
            inr: service,
            inrService: isService,
            description: description,
            interviewerUserId: user?.userId,
          };

          const response = await axios.post(
            `${process.env.PUBLIC_URL}/xakal/create-interview`,
            payloadWithoutImage
          );
          const data = response.data.interview._id;
          onPopup();
          onCloseAll();
          previewId(data.toString());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  };

  // Example logic to handle image updates
  const handleUpdate = async () => {
    try {
      // Prepare payload with updated images
      const payload = {
        interviewTitle: title,
        designation: designation,
        skill: skill,
        duration: duration,
        inr: service,
        inrService: isService,
        description: description,
        interviewerUserId: user?.userId,
      };

      // If there are new images, handle them

      const formData = new FormData();
      imageURL.forEach((file) => formData.append("image", file));

      const uploadResponse = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/files/multi`,
        formData
      );
      const mediaURLs = uploadResponse.data.urls;
      const updatedImageURL = mediaURLs.map((url, index) => ({
        url,
        fileType: imageURL[index].type,
      }));

      payload.image = updatedImageURL.filter((image) => image.url);
      // Make API call to update the interview
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/update-interview/${dataId}`,
        payload
      );
      const ids = response.data.interviewUpdate._id;
      onPopup();
      onCloseAll();
      previewId(ids.toString());
    } catch (error) {
      console.error("Error in handleUpdate:", error);
    }
  };

  const [skillData, setSkillData] = useState([]);

  const getSkill = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/skills`)
      .then((response) => {
        const companyValue = response.data.map((e) => ({
          skillIds: e.skillId,
          label: e.label,
          value: e.value,
          ratings: e.ratings,
          averageSkillRating: e.averageSkillRating,
        }));

        setSkillData(companyValue);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleSkillChnage = (selectedOptions) => {
    const formattedSkills = selectedOptions.map((e) => ({
      skillIds: e.skillIds,
      label: e.label,
      value: e.value,
      ratings: e.ratings,
      averageSkillRating: e.averageSkillRating,
    }));

    setSkill(formattedSkills);
  };
  useEffect(() => {
    getSkill();
  }, []);

  const CaretDownIcon = () => {
    return (
      <img
        src={DownArrow}
        alt="img"
        style={{ width: "30px", background: "#F4F4F6", padding: "0" }}
      />
    );
  };
  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  return (
    <div>
      <ToastContainer />
      <div className="modalBody" style={{ paddingTop: "0px" }}>
        <Label>
          Interview Title<span>*</span>
        </Label>
        <InputGroup className="mb-1">
          <Form.Control
            placeholder="Enter your interview title"
            className="font"
            aria-label="Job Title"
            name="interviewTitle"
            aria-describedby="basic-addon1"
            style={{
              borderRadius: "8px",
              background: "#F4F4F6",
              height: "48px",
              color: "#362A41",
              fontweight: "normal",
              // fontStyle: "italic",
            }}
            value={title}
            onChange={handleChangeTitle}
          />
        </InputGroup>
        {validationErrors.interviewTitle && (
          <div className="f12">{validationErrors.interviewTitle}</div>
        )}
        <Label>
          Designations that'll be interviewed
          <span className="text-danger">*</span>
        </Label>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <InputGroup className="">
              <Form.Control
                placeholder="Enter the designations"
                className="font"
                aria-label="Job Title"
                name="designation"
                aria-describedby="basic-addon1"
                style={{
                  borderRadius: "8px",
                  background: "#F4F4F6",
                  height: "48px",
                  position: "relative",
                  width: "250px",
                  fontweight: "normal",
                  // fontStyle: "italic",
                  color: "#362A41",
                }}
                // styles={customStyle}
                value={inputValue}
                onChange={handleInputChange}
              />
            </InputGroup>
          </div>
          <div>
            <ButtonLink
              onClick={handleAddInput}
              style={{
                cursor: "pointer",
                // opacity: inputValue ? 1 : 0.5,
                transition: "opacity 0.3s ease",
                margin: "0px 0 0 10px",
              }}
            >
              {/* <img src={Addcircle} alt="img" /> */}
              Add Designation
            </ButtonLink>
          </div>
        </div>

        {designation.map((input, index) => (
          <div
            key={index}
            style={{
              display: "inline-flex",
              marginRight: "10px",
              marginTop: "10px",
            }}
          >
            <JobPreference>
              {input}
              {input !== "" ? (
                <button
                  onClick={() => handleRemoveInput(index)}
                  style={{ marginLeft: "10px" }}
                >
                  X
                </button>
              ) : (
                ""
              )}
            </JobPreference>
          </div>
        ))}
        {validationErrors.designation && (
          <div className="f12">{validationErrors.designation}</div>
        )}
        <Label>Skills you'll evaluate</Label>
        <div>
          <Select
            className="footer-header font mb-3"
            placeholder="Enter the skills you'll evaluate"
            components={{ Placeholder, DropdownIndicator }}
            isMulti={true}
            name="skill"
            isClearable={true}
            //  defaultValue={skill}
            value={skill}
            onChange={handleSkillChnage}
            options={skillData}
            menuPlacement="top"
            styles={customStyle}
          />
        </div>

        <Label>
          Interview Duration<span>*</span>
        </Label>
        <Select
          value={durationOptions.find((option) => option.value === duration)}
          components={{ Placeholder, DropdownIndicator }}
          onChange={handleChangeDuration}
          options={durationOptions}
          placeholder="Select duration"
          styles={customStyle}
        />
        {validationErrors.duration && (
          <div className="f12">{validationErrors.duration}</div>
        )}

        <Label>
          Interview Fee<span>*</span>
        </Label>
        <InputGroup className="mb-1">
          {isService ? null : (
            <>
              <InputGroup.Text
                style={{
                  borderRadius: "8px 0px 0px 8px",
                  fontFamily: "Inter-Medium,sans-serif",
                  // fontStyle: "italic",
                  fontSize: "12px",
                  fontweight: "normal",
                  color: "#362A41",
                  background: "#F6F6F6",
                  border: "none",
                }}
              >
                INR{" "}
                <span
                  style={{
                    width: "1px",
                    fontweight: "normal",
                    padding: "0px 10px",
                  }}
                >
                  |
                </span>
              </InputGroup.Text>
              <Form.Control
                placeholder="Enter your Interview Fee"
                aria-label="Job Title"
                name="inr"
                aria-describedby="basic-addon1"
                className="font"
                style={{
                  borderRadius: "0px 8px 8px 0px",
                  background: "#F4F4F6",
                  height: "48px",
                  // fontStyle: "italic",
                }}
                value={service}
                onChange={handleChangeService}
              />
            </>
          )}
        </InputGroup>
        {!isService && validationErrors.service && (
          <div className="f12">{validationErrors.service}</div>
        )}

        <DataDiv>
          <div style={{ marginTop: "4px" }}>
            <input
              type="checkbox"
              checked={isService}
              onChange={handleCheckboxChange}
            />
          </div>
          <div>
            <p style={{ fontSize: "14px" }}> Free Service</p>
          </div>
        </DataDiv>

        <Label>Interview Description</Label>
        <InputGroup className="mb-3">
          <Form.Control
            placeholder="Enter the description of your interview"
            aria-label="Job Title"
            name="decription"
            aria-describedby="basic-addon1"
            style={{
              borderRadius: "8px",
              background: "#F4F4F6",
              height: "93px",
              color: "#362A41",
              fontSize: "12px",
              fontweight: "normal",
            }}
            value={description}
            onChange={handleChangeDescription}
          />
        </InputGroup>
        <div
          style={{
            fontFamily: "Inter-Medium,sans-serif",
            fontweight: "normal",
            fontSize: "12px",
            color: "#C6CAD2",
            position: "relative",
            left: "230px",
            bottom: "40px",
          }}
        >
          max 1000 characters
        </div>
      </div>
      <div className="modalFooter">
        <ButtonWrapper>
          <button className="button5" onClick={() => onClick()}>
            Back
          </button>
          {id ? (
            <button
              className="button2"
              style={{ width: "85px", height: "43px", padding: "12px 24px" }}
              onClick={handleUpdate}
            >
              Update
            </button>
          ) : (
            <button
              className="button2"
              style={{ width: "85px", height: "43px", padding: "12px 24px" }}
              onClick={handleSubmit}
            >
              Next
            </button>
          )}
        </ButtonWrapper>
      </div>
    </div>
  );
};

export default ServicePopup;
