import React, { useState } from "react";
import moment from "moment";
import axios from "axios";
import Common from "../../utils/common";
import { ToastContainer, toast } from "react-toastify";
import styled from "styled-components";
import { Country, State, City } from "country-state-city";
import { Col, Row } from "reactstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
const Title = styled.div`
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 16.8px */
  text-transform: capitalize;
`;
const Label = styled.div`
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 16.8px */
  text-transform: capitalize;
  margin: 10px 0 10px 0;
  span {
    color: #ff4873;
    font-size: 16px;
    font-weight: 800;
    margin-left: 5px;
  }
`;
const customStyle = {
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    minHeight: "48px",
    height: "fit-content",
    border: "none",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    color: "#40444F",
    fontweight: "normal",
    fontFamily: "Inter-Medium,sans-serif",
  }),
  menu: (provided, state) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    "&:hover": {
      color: "#362A41", // Add hover text color
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 6px)",
      marginLeft: "5px",
    },
  }),
  // Add more styles as needed
};
const InterviewEdit = ({ closePopup, data, event, close,formData,setFormData,emails,setEmails }) => {

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "country") {
      setFormData({
        ...formData,
        country: value,
        state: "", // Reset state when country changes
        city: "", // Reset city when country changes
      });
    } else if (name === "state") {
      setFormData({
        ...formData,
        state: value,
        city: "", // Reset city when state changes
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

 
  const [emailInput, setEmailInput] = useState();
  const handleEmailChange = (e) => {
    setEmailInput(e.target.value);
  };

  const handleAddEmail = () => {
    if (emailInput.trim() !== "") {
      if (!emails.includes(emailInput)) {
        setEmails([...emails, emailInput]);
      } else {
        toast.error("Email already exists in the list.");
      }
      setEmailInput("");
    }
  };
  // useEffect(() => {
  //   // Set initial email values if 'event' prop has emails
  //   if (event?.eventEmail && event.eventEmail.length > 0) {
  //     setEmails(event.eventEmail);
  //   }
  // }, [event]);
  const handleRemoveEmail = (index) => {
    const updatedEmails = [...emails];
    updatedEmails.splice(index, 1);
    setEmails(updatedEmails);
  };

  // eslint-disable-next-line no-unused-vars
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send data to the server
    const fromDate = moment(
      `${formData?.fromDate} ${formData?.fromTime}`,
      "YYYY-MM-DD HH:mm"
    );
    const toDate = moment(
      `${formData?.toDate} ${formData?.toTime}`,
      "YYYY-MM-DD HH:mm"
    );

    // Validate start and end dates
    // Validate start and end dates
    if (
      // Condition 1: Check if the "from date" is not valid
      !fromDate.isValid() ||
      // Condition 2: Check if the "to date" is not valid
      !toDate.isValid() ||
      // Condition 3: Check if the "to date" is before the "from date"
      toDate.isBefore(fromDate) ||
      // Condition 4: Check if the "from date" and "to date" are not the same day
      !fromDate.isSame(toDate, "day")
    ) {
      // Display different error messages based on the conditions
      if (!fromDate.isValid() || !toDate.isValid()) {
        toast.error("Invalid date format. Please check your input.");
      } else if (toDate.isBefore(fromDate)) {
        toast.error("To date should not be less than From date.");
      } else if (!fromDate.isSame(toDate, "day")) {
        toast.error("From date and to date should be the same day.");
      }

      // Exit the function
      return;
    }

    // Ensure the time range is valid (optional)
    if (fromDate.isSame(toDate) && fromDate.isSameOrAfter(toDate)) {
      toast.error("Invalid time range");
      return;
    }

    // Ensure from date and to date are the same
    if (!fromDate.isSame(toDate, "day")) {
      toast.error("From date and to date should be the same day");
      return;
    }

    const payload = {
      eventTitle: formData.title,
      eventDescription: formData.description,
      eventUrl: formData.url,
      eventVenue: formData.venue,
      eventEmail: emails,
      city: formData.city,
      country: formData.country,
      pincode: formData.pincode,
      state: formData.state,
      addressOne: formData.address1,
      addressTwo: formData.address2,
      guest: [],
      host: [
        {
          userId: Common.loggedIn(),
        },
      ],
    };
    payload["fromEpoch"] = fromDate.unix();
    payload["toEpoch"] = toDate.unix();

    axios
      .put(
        `${process.env.PUBLIC_URL}/xakal/get-calendar-update/${event.main_id}`,
        payload
      )
      .then((res) => {
        axios
          .put(
            `${process.env.PUBLIC_URL}/xakal/apply-interviewed/${event.jobId}/${event.applicantId}`,
            {
              interviewTitle: formData.title,
              emailArray: emails,
              fromDate: fromDate.format("YYYY-MM-DD"),
              fromTime: fromDate.format("HH:mm"),
              toTime: toDate.format("HH:mm"),
              venue: formData.venue,
              eventUrl: formData.url,
              city: formData.city,
              country: formData.country,
              pincode: formData.pincode,
              state: formData.state,
              addressOne: formData.address1,
              addressTwo: formData.address2,
            }
          )
          .then((res) => {
            toast.success("Applicant status updated to Interviewed");
          })
          .catch((error) => {
            console.error("Error updating status to Interviewed", error);
            toast.error("Error updating applicant status to Interviewed");
          });
      });
    closePopup();
  };

  const [urlRadio, setUrlRadio] = useState(true);
  const [urlPerson, setUrlPerson] = useState(false);
  const handleUrlRadio = () => {
    setUrlRadio(true);
    setUrlPerson(false);
  };

  const handlePerson = () => {
    setUrlPerson(true);
    setUrlRadio(false);
  };
  return (
    <div>
      <ToastContainer />
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <div style={{ margin: "20px" }}>
          <Title>
            <label>Interview Title</label>
          </Title>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            style={{
              borderRadius: "5px",
              // border: "1px solid black",
              background: "rgba(54, 42, 65, 0.04)",
              width: "90%",
              height: "40px",
              padding: "20px",
            }}
            required
          />
        </div>

        <div style={{ display: "flex" }}>
          <div style={{ margin: "20px" }}>
            <Title>
              <label>From Date:</label>
            </Title>
            <input
              type="date"
              name="fromDate"
              value={formData.fromDate}
              onChange={handleChange}
              style={{
                borderRadius: "5px",
                // border: "1px solid black",
                background: "rgba(54, 42, 65, 0.04)",
                width: "90%",
                height: "40px",
                padding: "20px",
              }}
              required
            />
          </div>
          <div style={{ margin: "20px" }}>
            <label>From Time:</label>
            <input
              type="time"
              name="fromTime"
              value={formData.fromTime}
              onChange={handleChange}
              style={{
                borderRadius: "5px",
                // border: "1px solid black",
                background: "rgba(54, 42, 65, 0.04)",
                width: "90%",
                height: "40px",
                padding: "20px",
              }}
              required
            />
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ margin: "20px" }}>
            <Title>
              <label>To Date:</label>
            </Title>
            <input
              type="date"
              name="toDate"
              value={formData.toDate}
              onChange={handleChange}
              style={{
                borderRadius: "5px",
                // border: "1px solid black",
                background: "rgba(54, 42, 65, 0.04)",
                width: "90%",
                height: "40px",
                padding: "20px",
              }}
              required
            />
          </div>
          <div style={{ margin: "20px" }}>
            <Title>
              <label>To Time:</label>
            </Title>
            <input
              type="time"
              name="toTime"
              value={formData.toTime}
              onChange={handleChange}
              style={{
                borderRadius: "5px",
                // border: "1px solid black",
                background: "rgba(54, 42, 65, 0.04)",
                width: "90%",
                height: "40px",
                padding: "20px",
              }}
              required
            />
          </div>
        </div>
        <div style={{ margin: "20px" }}>
          <Title>
            <label>Email</label>
          </Title>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="text"
              name="eventEmail"
              value={emailInput}
              onChange={handleEmailChange}
              style={{
                borderRadius: "5px",
                // border: "1px solid black",
                background: "rgba(54, 42, 65, 0.04)",
                width: "70%",
                height: "40px",
                padding: "20px",
              }}
            />
            <button
              type="button"
              onClick={handleAddEmail}
              className="button3"
              style={{
                marginLeft: "20px",
                // padding: "10px",
                // borderRadius: "5px",
                // background: "lightblue",
                cursor: "pointer",
              }}
            >
              Add
            </button>
          </div>
        </div>
        <div>
          {emails?.map((email, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <span>{email}</span>
              <button
                type="button"
                onClick={() => handleRemoveEmail(index)}
                style={{
                  marginLeft: "10px",
                  padding: "5px",
                  borderRadius: "50%",
                  background: "red",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                x
              </button>
            </div>
          ))}
        </div>
        <div style={{ margin: "20px" }}>
          <Title>
            <label>Venue</label>
          </Title>
          <label>In-person Interview</label>
          <input type="radio" onChange={handlePerson} checked={urlPerson} />
          <label>online Interview</label>
          <input type="radio" onChange={handleUrlRadio} checked={urlRadio} />
        </div>
        {urlRadio && (
          <div style={{ margin: "20px" }}>
            <Title>
              <label>Url</label>
            </Title>
            <input
              type="text"
              name="url"
              value={formData.url}
              onChange={handleChange}
              style={{
                borderRadius: "5px",
                // border: "1px solid black",
                background: "rgba(54, 42, 65, 0.04)",
                width: "90%",
                height: "40px",
                padding: "20px",
              }}
              required
            />
          </div>
        )}
        {urlPerson && (
          <>
            <div style={{ display: "flex", gap: "20px" }}>
              <div style={{ width: "50%" }}>
                <Label>Address Line 1</Label>

                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Enter address Line 1"
                    aria-label="Job Title"
                    name="address1"
                    className="font"
                    aria-describedby="basic-addon1"
                    style={{
                      height: "48px",
                      borderRadius: "12px",
                      background: "rgba(54, 42, 65, 0.04)",
                    }}
                    onChange={handleChange}
                  />
                </InputGroup>
              </div>
              <div style={{ width: "50%" }}>
                <Label>Address Line 2</Label>

                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Enter address Line 2"
                    aria-label="Job Title"
                    name="address2"
                    className="font"
                    aria-describedby="basic-addon1"
                    style={{
                      height: "48px",
                      borderRadius: "12px",
                      background: "rgba(54, 42, 65, 0.04)",
                    }}
                    onChange={handleChange}
                  />
                </InputGroup>
              </div>
            </div>
            <Row>
              <Col>
                <Form.Group>
                  <Label>Country</Label>

                  <div>
                    <select
                      required
                      value={formData.country}
                      onChange={handleChange}
                      name="country"
                      className="footer-header font mb-3"
                      styles={customStyle}
                      menuPlacement="top"
                      // padding: "0px 12px", border: "none", height: "48px",
                      // borderRadius: "12px",

                      // onBlur={validateInput}
                    >
                      <option value="">Country</option>
                      {Country &&
                        Country.getAllCountries().map((item) => (
                          <option key={item.name} value={item.isoCode}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Label>State / Provision</Label>

                  <select
                    required
                    value={formData.state}
                    onChange={handleChange}
                    name="state"
                    className="footer-header font mb-3"
                    style={{
                      padding: "12px",
                      border: "none",
                      height: "48px",
                      borderRadius: "12px",
                    }}
                    // onBlur={validateInput}
                  >
                    <option value="">State</option>
                    {State &&
                      formData.country &&
                      State.getStatesOfCountry(formData.country).map((item) => (
                        <option key={item.name} value={item.isoCode}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Label>City / Town</Label>

                  <select
                    required
                    value={formData.city}
                    onChange={handleChange}
                    name="city"
                    className="footer-header font mb-3"
                    style={{
                      padding: "0px 12px",
                      border: "none",
                      height: "48px",
                      borderRadius: "12px",
                    }}
                  >
                    <option value="">City</option>
                    {City &&
                      formData.country &&
                      formData.state &&
                      City.getCitiesOfState(
                        formData.country,
                        formData.state
                      ).map((item) => (
                        <option value={item.name}>{item.name}</option>
                      ))}
                  </select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Label>Zip / Pincode</Label>
                  <Form.Control
                    placeholder="Enter Zip / Pincode"
                    aria-label="Job Title"
                    name="pincode"
                    className="font"
                    aria-describedby="basic-addon1"
                    style={{
                      height: "48px",
                      borderRadius: "12px",
                      background: "rgba(54, 42, 65, 0.04)",
                    }}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </>
        )}
        
      </div>
      
    </div>
  );
};

export default InterviewEdit;
