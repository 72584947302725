/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "./jobs.css";
import DesktopHeader from "../desktopHeader";
import iExp from "../../images/icons/exp.svg";
import noData from "../../images/NoData.svg";
import iSalary from "../../images/icons/salary.svg";
import iLocation from "../../images/icons/location.svg";
import iHome from "../../images/icons/home.svg";
import iClock from "../../images/icons/clock.svg";
import iEdit from "../../images/icons/edit-btn.svg";
import iTrash from "../../images/icons/trash-can-btn.svg";
import Image from "../../images/company.png";
import Plus from "../../images/Normal_State_Plus.svg";
import PlusHover from "../../images/Hover_State_Plus.svg";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import "./buttonStyle.css";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogActions, DialogTitle } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewJob from "./addNewSave";
import JobInfo from "./addDraftUpdate";
import { Amplify, Auth } from "aws-amplify";
import Common from "../../utils/common";
import NewJobs from "./addNewJob";
import JobInfos from "./addJobInformation";
import PreviewJobs from "./previewJob";
import DeleteImg from "../../images/solar_shield-warning-bold-duotone.svg";
import Spinner from "react-bootstrap/Spinner";
import Settings from "../settings";
import DraftPreview from "./draftPreview";
import PrefillNewForm from "./addPrefillNewJob";
import ShimmerLoading from "../interview/shimmerloading";

const Button5 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family:
    Inter-Medium,
    sans-serif;
  color: #2d3139;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
  font-weight: normal;
  font-size: 14px;
  color: #2d3139;
  border: 1px solid #1213171a;
  padding: 12px 22px;
  width: 94px;
  height: 40px;
  // position:relative;
  top: 3px;
  cursor: pointer;
  // &:hover {
  //   background: black;
  //   color: #764f90;
  //   border: 1px solid #764f90;
  // }
`;

const Button9 = styled.div`
  background-color: #362a41;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;
const Button8 = styled.div`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: #362a41;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: #362a41;
    color: white;
    border: 1px solid #362a41;
  }
`;
const Button6 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fd2145;

  font-family:
    Inter-Medium,
    sans-serif;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  color: white;
  padding: 7px 10px;
  /* margin: 15px 0; */
  cursor: pointer;
  // &:hover {
  //   background: black;
  //   color: #764f90;
  //   border: 1px solid #764f90;
  // }
`;

const ContentWrapper = styled.div``;

const Wrapper = styled.div``;
const JobDetail = styled.div``;

const JobTitle = styled.div``;

const CompanyTitle = styled.div``;
const IconTitle = styled.div``;
const DateWrapper = styled.div``;
const DateWrapper1 = styled.div``;

const HrLines = styled.div`
  border: 1px solid #f7f7f8;
  width: 100%;
  margin: 10px 0 0px 0;
`;

const PreferenceWrapper = styled.div``;
const Preference = styled.div``;
const JobPreference = styled.div``;

const HeaderLayout = styled.div``;
const HeaderDownLayout = styled.div``;
const Text = styled.p`
  font-family: Inter-Medium;
  font-size: 24px;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0em;
  position: relative;
  text-align: center;
`;
const DateWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
const NativeLink = styled.div``;
const ButtonLink = styled.div`
cursor:pointer;
`;
const CardWrapper = styled.div``;
Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      //  redirectSignIn: "http://localhost:3000/",
      //   redirectSignOut: "http://localhost:3000/",
      redirectSignIn: "https://qa.thexakal.com/login",
      redirectSignOut: "https://qa.thexakal.com/login",

      responseType: "token",
    },
  },
});
const Jobs = () => {
  const [user, setUsers] = useState("");
  const [success, setSuccess] = useState(false);
  const logout = () => {
    Common.logout();
  };
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        setUsers(null);
      });
  }, []);
  const history = useHistory();
  const [open2, setOpen2] = useState(false);
  const [data, setData] = useState([]);

  const [jobId, setJobId] = useState("");
  const [openJobOnes, setOpenJobOnes] = useState(false);
  const [openJobTwos, setOpenJobTwos] = useState(false);
  const [openJobPreviews, setOpenJobPreviews] = useState(false);
  const [previewIds, setPreviewIds] = useState("");

  const [shouldUpdates, setShouldUpdates] = useState(false);

  const editJob = (id) => {
    setJobId(id);
    setShouldUpdates(true);
    setOpenJobOnes(true);
  };
  const closeJobpageOns = (id) => {
    setOpenJobOnes(false);
    setOpenJobTwos(true);
    setJobId(id);
  };
  const closeJobpageOnstwo = (id) => {
    setOpenJobOnes(false);
    setJobId(id);
  };
  const closeAlls = () => {
    setOpenJobOnes(false);
    getAllDraft();
  };

  const updatePreviews = (id) => {
    setOpenJobTwos(false);
    setOpenJobPreviews(true);
    setPreviewIds(id);
  };

  const updateSavePreviews = (id) => {
    setOpenJobTwos(false);
    // setOpenJobPreviews(true);
    setPreviewIds(id);
  };

  const goToJobOnes = () => {
    setOpenJobOnes(true);
    setOpenJobTwos(false);
  };

  const goToJobtwos = () => {
    setOpenJobTwos(true);
    setOpenJobPreviews(false);
  };
  const [loading, setLoading] = useState(false);
  const getAllDraft = () => {
    setLoading(true);
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-draft`)
      .then((response) => {
        if (response.data) {
          setData(response.data.job);
        }
      })
      .catch((err) => err.error)
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getAllDraft();
  }, []);
  const [jobIdToDelete, setJobIdToDelete] = useState(null);
  const deleteDraftJob = async (jobId) => {
    try {
      // Make a DELETE request to the delete service endpoint
      const response = await axios.delete(
        `${process.env.PUBLIC_URL}/xakal/draft/${jobId}`
      );

      // Update the state to reflect the changes (remove the deleted job from the list)
      setData(data.filter((item) => item._id !== jobId));
      setOpen2(false);
      toast.success("Job Deleted Successfully");
    } catch (error) {
      console.error("Error deleting archived job:", error);
    }
  };
  const location = useLocation();
  const [openSetting, setOpenSetting] = useState(false);
  const [openJobOne, setOpenJobOne] = useState(false);
  const [openJobTwo, setOpenJobTwo] = useState(false);
  const [openJobPreview, setOpenJobPreview] = useState(false);
  const [previewId, setPreviewId] = useState("");
  function onSubmit3() {
    // history.push("./addnew-job");
    setOpenJobOne(true);
  }

  const closeJobpageOn = (id) => {
    setOpenJobOne(false);
    setOpenJobTwo(true);
    setJobId(id);
  };
  const updatePreview = (id) => {
    setOpenJobTwo(false);
    setOpenJobPreview(true);
    setPreviewId(id);
  };

  const goToJobOne = () => {
    setOpenJobOne(true);
    setOpenJobTwo(false);
  };

  const goToJobtwo = () => {
    setOpenJobTwo(true);
    setOpenJobPreview(false);
  };
  const closeAll = () => {
    setOpenJobOne(false);
    getAllDraft();
  };
  const closeAllFirst = () => {
    setOpenJobOnes(false);
    getAllDraft();
  };
  const CloseAll = () => {
    getAllDraft();
    setOpenJobPreview(false);
  };
  const CloseAlls = () => {
    getAllDraft();
    setOpenJobPreviews(false);
  };
  const oneClose = () => {
    getAllDraft();
    setOpenJobTwo(false);
  };
  const [searchKeyword, setSearchKeyword] = useState("");
  const handleInputChange = (event) => {
    setSearchKeyword(event.target.value);
  };
  const [openJobOness, setOpenJobOness] = useState(false);
  const closeJobpageOnss = (id) => {
    setOpenJobOness(false);
    setOpenJobTwo(true);
    setJobId(id);
    setShouldUpdates(true);
  };
  const closeAllss = () => {
    setOpenJobOness(false);
  };
  const BackBtn = () => {
    history.push('./jobs')
  }
  const searchedKey=()=>{}
  const [userRole, setUserRole] = useState(false);
  const prefill = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      )
      .then((response) => {
        if (response && response.data && response.data.aboutOne) {
          setUserRole(response.data.aboutOne.userType.includes("hr"));
        }
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    prefill();
  }, []);
  return (
    <>
      <ToastContainer />

      <HeaderLayout className="header-layout">
        {/* Start of desktop Header  */}
        <DesktopHeader
          title={"Manage Jobs"}
          searchedKey={searchedKey}
          onFieldChange={handleInputChange}
          back={BackBtn} backbtn
        />
        {/* End of desktop Header  */}

        <HeaderDownLayout>
          <div className="job-nav-container job-nav-containers">
            <NativeLink className="job-nav">
              <div
                onClick={() => history.push("./manage-jobs")}
                className="job-nav-link"
              >
                Active Jobs
              </div>
              <div
                onClick={() => history.push("./archievedJobs")}
                className="job-nav-link"
              >
                Archived Jobs
              </div>
              <div
                onClick={() => history.push("/portal/drafts")}
                className={
                  location.pathname === "/portal/drafts"
                    ? "active job-nav-link"
                    : "job-nav-link"
                }
              >
                Draft{" "}
              </div>
            </NativeLink>
{userRole ? <div className="o-desk">
              <ButtonLink className="button2" onClick={onSubmit3}>
                {/* <AiFillPlusCircle size={20} /> */}
                <img src={PlusHover} alt="img" className="plus-icon2" style={{ margin: "0 5px 0 0px" }}/>
                <img src={Plus} alt="img" className="plus-icon" style={{ margin: "0 3px 0 0px" }}/>
                
                Post a Job
              </ButtonLink>
            </div>:""}
            
          </div>
        </HeaderDownLayout>
      </HeaderLayout>

      <div className="job-card-container">
        <>
          {loading ? (
         <div style={{ top: "160px", position: "relative" }}>
         {Array.from({ length: 4 }).map((_, index) => (
           <ShimmerLoading key={index} />
         ))}
       </div>
          ) :
            data &&
              data?.length > 0 ? (
              data
                .filter((i) => {
                  // Add your filtering logic here for 'favourites'
                  return (
                    i.status === "draft" && // Filter by status (e.g., only 'favourites')
                    i?.jobTitle?.toLowerCase().includes(searchKeyword?.toLowerCase()) // Filter by job title
                  );
                })
                .map((i) => {
                  return (
                    <CardWrapper className="job-card-wrapper int-rows">
                      <>
                        <ContentWrapper className="job-content-wrapper">
                          <div className="company-logo-container">
                            {i.companyName && i.companyName.companyLogo ? (
                              <img
                                src={i.companyName && i.companyName.companyLogo}
                                alt="img"
                                className="company-logo"
                              />
                            ) : (
                              <img
                                src={Image}
                                alt="img"
                                className="company-logo"
                              />
                            )}
                          </div>
                          <Wrapper className="job-title-wrapper">
                            <JobTitle
                              // onClick={() => history.push(`./appliedJobs/${i._id}`)}
                              className="job-title"
                              key={i._id}
                            // onClick={() => openPopup(i)}
                            >
                              {i.jobTitle}
                            </JobTitle>
                            <CompanyTitle className="company-title">
                              {i.companyName && i.companyName.value}
                            </CompanyTitle>
                          </Wrapper>

                          <DateWrapper1
                            className="date-toggle-wrapper"
                            style={{ gap: "15px" }}
                          >
                            <img
                              onClick={
                                () => editJob(i._id)
                                // history.push(`/portal/addnew-save/${i._id}`)
                              }
                              src={iEdit}
                              className=""
                              style={{ cursor: "pointer" }}
                              alt="icon"
                            />

                            <img
                              onClick={() => {
                                setJobIdToDelete(i._id);
                                setOpen2(true);
                              }}
                              src={iTrash}
                              className=""
                              style={{ cursor: "pointer" }}
                              alt="icon"
                            />
                          </DateWrapper1>
                        </ContentWrapper>
                        <HrLines />
                        <div>
                          <JobDetail className="job-details">
                            <IconTitle className="icon-title">
                              <img src={iExp} className="jt-icon" alt="icon" />
                              <span>
                                {" "}
                                Exp{" "}
                                {i.experienceRequired2
                                  ? `${i.experienceRequired} - ${i.experienceRequired2} yrs`
                                  : i.experienceRequired
                                    ? `${i.experienceRequired} yrs`
                                    : ""}{" "}
                              </span>
                            </IconTitle>

                            <IconTitle className="icon-title">
                              <img src={iSalary} className="jt-icon" alt="icon" />
                              <span>{`${i.salaryFrom}L - ${i.salaryTo}L`}</span>
                            </IconTitle>
                            <IconTitle className="icon-title">
                              <img
                                src={iLocation}
                                className="jt-icon"
                                alt="icon"
                              />
                              <span>{i.location}</span>
                            </IconTitle>
                            {/* <JobTitle>{i.preference} |</JobTitle> */}
                            <IconTitle className="icon-title">
                              <img src={iHome} className="jt-icon" alt="icon" />
                              <span>{i.workMode}</span>
                            </IconTitle>
                            
                          </JobDetail>
                        </div>

                        <div className="jc-footer">
                          <DateWrapper className="applicant-wrap">
                          </DateWrapper>
                        </div>
                      </>
                    </CardWrapper>
                  );
                })
            ) : (<img src={noData} className="nodata-center" alt="icon" />)}
        </>
      </div>

      <Dialog
        open={openJobOnes}
        PaperProps={{
          style: {
            width: "100%",
            // height: "fit-content",
            borderRadius: "24px",
            maxWidth: "430px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
            position: "relative",
          }}
        ></DialogTitle>
        <DialogContent>
          <NewJob
            closeJobOne={closeJobpageOns}
            closeSave={closeJobpageOnstwo}
            callAll={closeAll}
            jobId={jobId}
            shouldUpdate={shouldUpdates}
            openFirst={() => editJob(jobId)}
            closeAllFirst={closeAllFirst}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openJobTwos}
        PaperProps={{
          style: {
            width: "100%",
            // height: "800px",
            borderRadius: "24px",
            maxWidth: "460px",
          },
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <JobInfo
            jobId={jobId}
            previewJobId={updatePreviews}
            closeAll={closeAlls}
            previewSaveJobId={updateSavePreviews}
            goToJobOne={goToJobOnes}
            closePopups={() => setOpenJobTwos(false)}
            openSaves={oneClose}

          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openJobPreviews}
        PaperProps={{
          style: {
            width: "100%",
            // height: "800px",
            borderRadius: "24px",
            maxWidth: "610px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <DraftPreview
            previewId={previewIds}
            goToJobTwo={goToJobtwos}
            closePreview={CloseAlls}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={open2}
        PaperProps={{
          style: {
            borderRadius: "20px",
            top: "10px",
            transform: "translateY(0%)",
            position: "absolute",
            backgroundColor: "#FFDAE0",
            // height:"56px",
            padding: "5px 10px",
          },
        }}
        halfWidth
        maxWidth="md"
      // className="def"
      >
        <DialogContent
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        >
          <div
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              gap: " 8px",
              background: "white",
            }}
          >
            <img src={DeleteImg} alt="img" style={{ marginLeft: "7px" }} />
          </div>
          <JobTitle
            style={{
              fontFamily: "Inter-Medium,sans-serif",
              fontSize: "14px",
              fontweight: "normal",
              lineHeight: "37px",
              gap: "10px",
              display: "flex",
              // position: "relative",
              top: "-6px",
            }}
          >
            Are you sure you want to delete this job?
          </JobTitle>
          <DialogActions>
            <div
              style={{
                position: "relative",
                display: "flex",
                gap: "10px",
                margin: "0 0px 0 20px",
              }}
            >
              <Button5
                onClick={() => setOpen2(false)}
                style={{ cursor: "pointer" }}
              >
                Cancel
              </Button5>
              <Button6 onClick={() => deleteDraftJob(jobIdToDelete)}>
                Yes, Delete
              </Button6>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        ></DialogTitle>
        <DialogContent>
          <Text>Are you sure you want to logout?</Text>

          <DateWrapperButton>
            <Button8 onClick={() => setSuccess(false)}>No</Button8>
            {user ? (
              <Button9
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </Button9>
            ) : (
              <Button9 onClick={logout}>Yes</Button9>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSetting}
        PaperProps={{
          className: "setting-modal",
        }}
        className="modal-container"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Settings open={() => setOpenSetting(false)} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openJobOne}
        PaperProps={{
          style: {
            width: "100%",
            // height: "800px",
            borderRadius: "24px",
            maxWidth: "430px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
            position: "relative",
          }}
        ></DialogTitle>
        <DialogContent>
          <NewJobs closeJobOne={closeJobpageOn} closeAll={closeAll} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openJobTwo}
        PaperProps={{
          style: {
            width: "100%",
            // height: "800px",
            borderRadius: "24px",
            maxWidth: "460px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <JobInfos
            jobId={jobId}
            previewJobId={updatePreview}
            goToJobOne={goToJobOne}
            openSaves={oneClose}
            goToOnePart={closeJobpageOnss}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openJobPreview}
        PaperProps={{
          style: {
            width: "100%",
            borderRadius: "24px",
            maxWidth: "610px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>

          <PreviewJobs
            previewId={previewId}
            goToJobTwo={goToJobtwo}
            closePreview={CloseAll}
            callAll={CloseAll}
            callAlls={CloseAll}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openJobOness}
        PaperProps={{
          style: {
            width: "100%",
            borderRadius: "24px",
            maxWidth: "430px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
            position: "relative",
          }}
        ></DialogTitle>
        <DialogContent>
          <PrefillNewForm
            closeJobOne={closeJobpageOnss}
            closeAll={closeAllss}
            jobId={jobId}
            shouldUpdate={shouldUpdates}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Jobs;
