/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect, useCallback } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import isValidDomain from "is-valid-domain";
import "./accordian.css";
import { Col, Row } from "reactstrap";
import ReactQuill from "react-quill";
import Addcircle from "../../images/Add Circle.svg";
import "react-quill/dist/quill.snow.css";
import companyLogo from "../../images/company.png";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiSolidPencil } from "react-icons/bi";
import "./aboutmodal.css";
import Spinner from "react-bootstrap/Spinner";
import { components } from "react-select";
import axios from "axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Common from "../../utils/common";
import styled from "styled-components";
import { Country, State, City } from "country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import languages from "languages-list";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Delete from "../../images/DelIcon.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreatableSelect from "react-select/creatable";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import CloseIcon from "../../images/CloseIcon.svg";
import DeleteImg from "../../images/solar_shield-warning-bold-duotone.svg";
import autoAnimate from "@formkit/auto-animate";
import Profile from "../../images/SME PNG.png";
import Profile2 from "../../images/Job Seeker PNG.png";
import Profile3 from "../../images/Recruiter PNG.png";

const customStyle = {
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    border: "none",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    minHeight: "48px",
    height: "fit-content",
    color: "#4f4953",
    fontWeight: "600",
    fontFamily: "Inter-Medium,sans-serif",
  }),
  menu: (provided, state) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    "&:hover": {
      color: "#362A41", // Add hover text color
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 6px)",
      marginLeft: "5px",
    },
  }),
  // Add more styles as needed
};
const JobTitle = styled.div`
  width: auto;
  // height: 22px;
  top: 24px;
  left: 0px;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #40444f;
  margin-left: 10px;

  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 16px;
    // margin: -15px 0 0 0;
  }
`;
const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;

  @media (max-width: 600px) {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    gap: 4px;
  }
`;

const Button4 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: Inter-Medium, sans-serif;
  color: #2d3139;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
  font-weight: normal;
  font-size: 14px;
  color: #2d3139;
  border: 1px solid #1213171a;
  padding: 12px;
  width: 94px;
  height: 40px;
  // position: relative;
  // top: 3px;
  cursor: pointer;
  @media (max-width: 576px) {
    width: 65px;
    height: 30px;
    padding: 6px;
    font-size: 12px;
  }
`;
const Button6 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fd2145;
  // position: relative;
  // top: 3px;
  font-family: Inter-Medium, sans-serif;
  border-radius: 10px;
  font-weight: normal;
  font-size: 14px;
  color: white;
  padding: 12px 16px;

  cursor: pointer;
  @media (max-width: 576px) {
    width: 85px;
    height: 30px;
    padding: 6px;
    font-size: 12px;
  }
`;

const Label = styled.div`
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 16.8px */
  text-transform: capitalize;
  margin: 10px 0 5px 0;
  span {
    color: #ff4873;
    font-size: 16px;
    font-weight: 800;
    margin-left: 0px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  button {
    width: 800px;
    height: 40px;
    margin-top: 10px;
    padding: 7px, 142px, 9px, 131px;
    border-radius: 8px;
    border: 0.5px solid rgba(221, 223, 228, 0.5);
    font-family: Inter-Medium, sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(54, 42, 65, 1);
  }
`;
const Get = styled.div`
  color: #8652b6;
  font-family:
    Visby CF,
    sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  cursor: pointer;
`;
const OtpHead = styled.div`
  color: #362a41;
  font-family:
    Visby CF,
    sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0;
`;
const options = [
  { value: "", label: "Select" },
  { value: "Beginner", label: "Beginner" },
  { value: "Pre-intermediate", label: "Pre-intermediate" },
  { value: "Intermediate", label: "Intermediate" },
  { value: "Upper-Intermediate", label: "Upper-Intermediate" },
  { value: "Advanced", label: "Advanced" },
  { value: "Mastery", label: "Mastery" },
];

const AboutModal = ({
  onClose,
  onClick,
  file,
  capture,
  uploadedImage,
  onFile,
  about,
  onSave,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [userType, setUserType] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [inputList, setInputList] = useState([
    { language: "", proficiency: "", read: false, write: false, speak: false },
  ]);
  const [inputList2, setInputList2] = useState([
    { workProfile: "", urlLink: "" },
  ]);
  // Format current month with leading zero if needed
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verified, setVerified] = useState();
  const [verfifySpinner, setVerifySpiner] = useState(false);
  const minDate = "1940-01-01";
  const [showVerifyButton, setShowVerifyButton] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [inputList3, setInputList3] = useState([{ resume: "" }]);
  const [resumeList, setResumeList] = useState([]);
  const [coreSkill, setCoreSkill] = useState([]);
  const [softSkill, setSoftSkill] = useState([]);
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [openGuardian, setGuardian] = useState(false);
  const [error3, setError3] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    avatar: "",
    bio: "",
    country: "",
    city: "",
    coreSkill: "",
    softSkill: "",
    state: "",
    language: "",
    proficiency: "",
    dob: "",
    title: "",
    gender: "",
    pincode: "",
    about: "",
    workProfile: "",
    urlLink: "",
    resume: "",
    alls: "",
  });
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [errors, setErrors] = useState(null);
  const [valid, setValid] = useState(false);
  const [verify, setVerify] = useState(false);
  const [enable, setEnable] = useState(false);
  const [enable2, setEnable2] = useState(false);
  const [enable3, setEnable3] = useState(true);
  const [enable4, setEnable4] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [spinner, setSpiner] = useState(false);
  const [count, setCount] = useState(5);
  const [disable, setDisable] = useState(true);
  const [message, setMessage] = useState(null);
  const [error2, setError2] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [proficiencyInfo, setProficiencyInfo] = useState(null);

  const [input, setInput] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    title: "",
    avatar: "",
    bio: "",
    country: "",
    city: "",
    // skill: "",
    coreSkill: "",
    softSkill: "",
    state: "",
    dob: "",
    gender: "",
    pincode: "",
    about: "",
    workProfile: "",
    urlLink: "",
    resume: "",
    alls: "",
  });
  const [parentInformation, setParentInformation] = useState({
    guardian: false,
    fatherName: "",
    occupationFather: "",
    annualIncomeFather: "",
    emailFatherId: "",
    motherName: "",
    occupationMother: "",
    annualIncomeMother: "",
    emailMotherId: "",
  });
  const [guardianInformation, setGuardianInformation] = useState({
    guardianName: "",
    occupationGuardian: "",
    annualIncomeGuardian: "",
    emailGuardianId: "",
    guardianRelation: "",
  });
  const [primaryAddress, setPrimaryAddress] = useState({
    addressOne: "",
    addressTwo: "",
    city: "",
    country: "",
    state: "",
    pincode: "",
    all: "",
  });
  const [primaryAddressError, setPrimaryAddressError] = useState({
    addressOne: "",
    addressTwo: "",
    city: "",
    country: "",
    state: "",
    pincode: "",
    all: "",
  });
  const [permananetAddressError, setPermamanetAddressError] = useState({
    addressOne: "",
    addressTwo: "",
    city: "",
    country: "",
    state: "",
    pincode: "",
  });
  const [permanentAddress, setPermanentAddress] = useState({
    addressOne: "",
    addressTwo: "",
    city: "",
    country: "",
    state: "",
    pincode: "",
  });
  // const currentYear = new Date().getFullYear();
  const [textAreaCount2, setTextAreaCount2] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [fatherNumber, setFatherNumber] = useState("");
  const [motherNumber, setMotherNumber] = useState("");
  const [guardianNumber, setGuardianNumber] = useState("");
  const [religion, setReligion] = useState("");
  const [loadingOtp, setLoadingOtp] = useState(false);

  const handleFatherPhoneChange = (value) => {
    setFatherNumber(value);
  };
  const handleMotherPhoneChange = (value) => {
    setMotherNumber(value);
  };
  const handleGuardianPhoneChange = (value) => {
    setGuardianNumber(value);
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMessageOne, setErrorMessageOne] = useState(null);
  const [errorMessageOneGuardian, setErrorMessageOneGuardian] = useState(null);

  const handleParentChange = (e) => {
    const { name, value } = e.target;
    let finalValue = value;

    if (name === "fatherName" || name === "motherName") {
      finalValue = value.replace(/[^a-z ]/gi, "");
    } else if (name === "annualIncomeFather" || name === "annualIncomeMother") {
      finalValue = value.replace(/[^0-9]/gi, "");
    } else if (name === "emailFatherId") {
      if (isValidEmail(value)) {
        setErrorMessage(null);
      } else {
        setErrorMessage("Invalid email id");
      }
    } else if (name === "emailMotherId") {
      if (isValidEmail(value)) {
        setErrorMessageOne(null);
      } else {
        setErrorMessageOne("Invalid email id");
      }
    } else {
    }

    setParentInformation((prevInfo) => ({
      ...prevInfo,
      [name]: finalValue,
    }));
  };
  //mobile handleChange
  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
    setDirty(true);
  };
  const handleGuardianChange = (e) => {
    const { name, value } = e.target;
    let finalValue = value;

    if (name === "guardianName" || name === "guardianRelation") {
      finalValue = value.replace(/[^a-z ]/gi, "");
    } else if (name === "annualIncomeGuardian") {
      finalValue = value.replace(/[^0-9]/gi, "");
    } else if (name === "emailGuardianId") {
      if (isValidEmail(value)) {
        setErrorMessageOneGuardian(null);
      } else {
        setErrorMessageOneGuardian("Invalid email id");
      }
    } else {
    }

    setGuardianInformation((prevInfo) => ({
      ...prevInfo,
      [name]: finalValue,
    }));
  };
  const handleMiddle = (e) => {
    const value = e.target.value;

    const onlyLettersRegex = /^[a-zA-Z]+$/;

    if (value === "" || onlyLettersRegex.test(value)) {
      setMiddleName(value);
    }
  };

  const handleModalChange = (event) => {
    const { name, value } = event.target;

    const onlyLettersRegex = /^[a-zA-Z]+$/;

    if (name === "firstName" || name === "lastName") {
      // Allow backspacing even if the current value doesn't match the regex
      if (value !== "" && !onlyLettersRegex.test(value)) {
        return;
      }
    }

    setInput((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));

    setDirty(true);
    setError3({
      alls: "",
    });
  };
  const handleModalBioChange = (event) => {
    setInput((prevInput) => ({
      ...prevInput,
      bio: event.target.value,
    }));
    setDirty(true);

    if (event.target.name === "bio") {
      setTextAreaCount(event.target.value.length);
    }
  };

//   const quillRef = useRef();
//   const handleJobDescChange = useCallback(
//     (content, delta, source, editor) => {
//       const value = editor.getText(); // Get the plain text content

//       if (value.length <= 1000) {
//         setInput({ ...input, about: content });
//         setDirty(true);
//         setTextAreaCount2(value.length);
//       } else {
//         console.error("Input exceeds 1000 characters");
//       }
//     },
//     [input]
//   );

//   useEffect(() => {
//     const quill = quillRef.current.getEditor();
//     if (quill) {
//       const updateTextAreaCount = () => {
//         setTextAreaCount2(quill.getLength());
//       };
//       quill.on("text-change", updateTextAreaCount);
//       return () => {
//         quill.off("text-change", updateTextAreaCount);
//       };
//     }
//   }, []);
  const validateInput = (e) => {
    let { name, value } = e.target;
    setError3((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "firstName":
          const regex = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = "First name is mandatory";
          } else if (!regex) {
            stateObj[name] = "Please enter valid name.";
          }
          break;

        case "lastName":
          const regex1 = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Last name is mandatory";
          } else if (!regex1) {
            stateObj[name] = "Please enter valid last name";
          }
          break;

        case "gender":
          const regex2 = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = " Gender is a mandatory filed !";
          } else if (!regex2) {
            stateObj[name] = "Please enter valid detail.";
          }
          break;
        case "bio":
          const regexbio = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = "HeadLine is mandatory";
          } else if (!regexbio) {
            stateObj[name] = "Please enter valid last name.";
          }
          break;
        case "dob":
          if (value === "") {
            stateObj[name] = "Please select date of birth";
          }

          break;

        case "country":
          if (value === "") {
            stateObj[name] = "Please select country";
          }
          break;

        case "state":
          if (value === "") {
            stateObj[name] = "Please select state";
          }
          break;

        case "city":
          if (value === "") {
            stateObj[name] = "Please select city";
          }
          break;

        case "pincode":
          const regex3 = /^[0-9]{6,6}$/.test(value);
          if (value === "") {
            stateObj[name] = "Zip / pincode is a mandatory";
          } else if (!regex3) {
            stateObj[name] = "Please enter valid pincode.";
          }
          break;

        case "coreSkill":
          if (coreSkill.length === 0 || coreSkill === undefined) {
            stateObj[name] = "Please select university";
          }
          break;

        case "softSkill":
          // const regex1 = /^[a-zA-Z]+$/.test(value);
          if (value === "" || value === undefined) {
            stateObj[name] = "Please select degree";
          }

          break;

        case "language":
          if (value === "" || value === undefined) {
            stateObj[name] = "Please select language";
          }

          break;

        case "proficiency":
          if (value === "" || value === undefined) {
            stateObj[name] = "Please select your proficiency";
          }
          break;

        case "workProfile":
          if (value === "") {
            stateObj[name] = "Please enter work profile name";
          }
          break;

        case "urlLink":
          if (value === "") {
            stateObj[name] = "Please enter URL link";
          } else if (
            !isValidDomain(getDomainFromURL(value), { allowSubdomains: false })
          ) {
            stateObj[name] = "Please enter a valid domain";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const [closemodal, setCloseModal] = useState(false);
  const [textAreaCount, setTextAreaCount] = useState("");

  const handleInputChange = (e, index) => {
    handleModalChange(e);
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    setDirty(true);
  };
  const handleProficiencyChange = (e, index) => {
    const { name, value } = e.target;

    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    setDirty(true);
  };
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { language: "", proficiency: "" }]);
  };
  const handleInputChange2 = (e, index) => {
    handleModalChange(e);
    const { name, value } = e.target;
    const list = [...inputList2];
    list[index][name] = value;
    setInputList2(list);
    setDirty(true);
  };
  const handleInputChange2s = (e, index) => {
    handleModalChange(e);
    const { name, value } = e.target;
    const list = [...inputList2];
    list[index][name] = value;
    setInputList2(list);
    setDirty(true);
  };

  const handleRemoveClick2 = (index) => {
    const list = [...inputList2];
    list.splice(index, 1);
    setInputList2(list);
  };

  const getDomainFromURL = (url) => {
    const match = url.match(/^(?:https?:\/\/)?(?:www\d?\.)?([^/]+)/i);
    if (
      match != null &&
      match.length > 1 &&
      typeof match[1] === "string" &&
      match[1].length > 0
    ) {
      return match[1];
    } else {
      return null;
    }
  };

  const handleAddClick2 = () => {
    setInputList2([...inputList2, { workProfile: "", urlLink: "" }]);
  };

  const handleInputChange3 = (e, index) => {
    handleModalChange(e);
    const { files } = e.target;
    const list = [...inputList3];

    const maxFileSize = 12 * 1024 * 1024;
    if (files && files.length > 0) {
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ]; // Allow only PDF and Word files
      const fileType = files[0].type;

      if (!allowedTypes.includes(fileType)) {
        toast.error("Invalid file type. Only PDF and Word files are allowed.");
        return;
      }

      if (files[0].size > maxFileSize) {
        toast.error("File size exceeds the maximum limit of 12MB.");
        return;
      }
    }
    const fileNameDisplayElement = document.getElementById(
      `fileNamesDisplay${index}`
    );
    if (fileNameDisplayElement) {
      fileNameDisplayElement.textContent =
        files && files.length > 0 ? files[0].name : "";
    }
    if (!files || files.length === 0) {
      list[index].resume = prefilledValue.inputList3[index].resume;
      list[index].fileName = prefilledValue.inputList3[index].fileName;
    } else {
      const resume = files[0];
      let previousData = resumeList;
      previousData.push(resume);
      setInputList3(previousData);
      setInputList3(list);
    }

    // Update the input list state
    setDirty(true);
  };

  const [displayedFileNames, setDisplayedFileNames] = useState([""]);

  const handleInputChangeFile3 = (e, index) => {
    e.preventDefault();
    handleModalChange(e);
    const { files } = e.target;
    const list = [...inputList3];

    const maxFileSize = 12 * 1024 * 1024;
    if (files && files.length > 0) {
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ]; // Allow only PDF and Word files
      const fileType = files[0].type;

      if (!allowedTypes.includes(fileType)) {
        toast.error("Invalid file type. Only PDF and Word files are allowed.");
        return;
      }

      if (files[0].size > maxFileSize) {
        toast.error("File size exceeds the maximum limit of 12MB.");
        return;
      }
    }

    setDisplayedFileNames((prevNames) => {
      const newNames = [...prevNames];
      newNames[index] = files && files.length > 0 ? files[0].name : "";
      return newNames;
    });

    // Update only the current item in the list
    if (!files || files.length === 0) {
      list[index].resume = prefilledValue?.inputList3[index]?.resume;
      list[index].fileName = prefilledValue?.inputList3[index]?.fileName;
    } else {
      const resume = files[0];
      let previousData = resumeList;
      previousData.push(resume);
      setInputList3(previousData);
      setInputList3(list);
    }

    setDirty(true);
  };

  const handleRemoveClick3 = (index) => {
    const list = [...inputList3];
    list.splice(index, 1);
    setInputList3(list);

    //   updated code
    let previousData = resumeList;
    previousData.splice(index, 1);
    setResumeList(previousData);
  };

  const handleAddClick3 = () => {
    setInputList3([...inputList3, { resume: "" }]);
  };

  const handleDropdown1 = (selectedOptions) => {
    const formattedSkills = selectedOptions.map((option) => ({
      skillIds: option.skillIds,
      label: option.label,
      value: option.value,
      ratings: option.ratings,
      averageSkillRating: option.averageSkillRating,
      candidateId: Common.loggedIn(),
    }));

    if (selectedOptions && selectedOptions.length <= 100) {
      setSoftSkill(formattedSkills);
      setOptionsOne([]);
      setSoftSkillCreate("");
    }
  };

  const handleDropdown2 = (selectedOptions) => {
    const formattedSkills = selectedOptions.map((e) => ({
      skillIds: e.skillIds,
      label: e.label,
      value: e.value,
      ratings: e.ratings,
      averageSkillRating: e.averageSkillRating,
      candidateId: Common.loggedIn(),
    }));
    if (selectedOptions && selectedOptions.length <= 100) {
      setCoreSkill(formattedSkills);
      setSkillCreate("");
      setOptionsOneSkill([]);
    }
    // Not sure where setDirty comes from in your code
  };
  const [mobileNumber, setMobileNumber] = useState("");
  const [verifiedNumber, setVerifiedNumber] = useState();
  const [phoneNumberTouched, setPhoneTouched] = useState(false);
  const [prefilledValue, setPreffiledValue] = useState(null);
  const [openParent, setOpenParent] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [openForm, setOpenForm] = useState(false);
  const [trueValue, setTrue] = useState(false);

  const prefill = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      )
      .then((response) => {
        if (response && response.data && response.data.aboutOne) {
          const responseData = response.data && response.data.aboutOne;
          setGuardianNumber(response.data.aboutOne.guardianPhoneNumber);
          const number = `+${responseData.fatherPhoneNumber}`;
          setFatherNumber(number);
          const numberMother = `+${responseData.motherPhoneNumber}`;
          setMotherNumber(numberMother);
          const dataPrimary = responseData.primaryAddress;
          const dataPermanent = responseData.permanentAddress;
          setPreffiledValue(responseData);
          setPrimaryAddress(dataPrimary);
          setPermanentAddress(dataPermanent);
          const dataCore = responseData.coreSkill;
          setCoreSkill(dataCore);
          const dataSkill = responseData.softSkill;
          setSoftSkill(dataSkill);
          const rawPhoneNumber = responseData.phoneNumber;
          const formattedPhoneNumber = `+${rawPhoneNumber}`; // Format it as E.164
          const verify = responseData.mobileNumberVerified === true;
          setMobileNumber(rawPhoneNumber);
          setPhoneNumber(formattedPhoneNumber);
          setVerifiedNumber(verify && rawPhoneNumber);
          setInput(response.data.aboutOne);
          if (response.data.aboutOne.inputList.length) {
            setInputList(response.data.aboutOne.inputList);
          } else {
            setInputList([
              {
                language: "",
                proficiency: "",
                read: false,
                write: false,
                speak: false,
              },
            ]);
          }
          if (response.data.aboutOne.userType.length) {
            setUserType(response.data.aboutOne.userType);
          } else {
            setUserType([]);
          }
          if (response.data.aboutOne.inputList2.length) {
            setInputList2(response.data.aboutOne.inputList2);
          } else {
            setInputList2([{ workProfile: "", urlLink: "" }]);
          }
          if (response.data.aboutOne.inputList3.length) {
            setInputList3(response.data.aboutOne.inputList3);
          } else {
            setInputList3([{ resume: "" }]);
          }
          setMiddleName(responseData.middleName);
          setParentInformation({
            ...parentInformation,
            fatherName: responseData.fatherName,
            occupationFather: responseData.occupationFather,
            annualIncomeFather: responseData.annualIncomeFather,
            emailFatherId: responseData.emailFatherId,
            motherName: responseData.motherName,
            occupationMother: responseData.occupationMother,
            annualIncomeMother: responseData.annualIncomeMother,
            emailMotherId: responseData.emailMotherId,
          });

          setGuardianInformation({
            ...guardianInformation,
            guardianName: responseData.guardianName,
            occupationGuardian: responseData.occupationGuardian,
            annualIncomeGuardian: responseData.annualIncomeGuardian,
            emailGuardianId: responseData.emailGuardianId,
            guardianRelation: responseData.guardianRelation,
          });
          setTextAreaCount(responseData.bio.length);
          setTextAreaCount2(responseData.about.length);
          setReligion(responseData.religion);
          setSelectedCountry(responseData.country);
          setSelectedState(responseData.state);
          setSelectedCity(responseData.city);
        }
      })
      .catch((err) => err.error);
  };
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = useRef(otp.map(() => React.createRef()));

  const handlePaste = (e, index) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 4); // Get the pasted content
    const updatedOtp = [...otp];

    // Insert the pasted content into the OTP input fields
    for (let i = 0; i < pastedData.length; i++) {
      if (index + i < otp.length) {
        updatedOtp[index + i] = pastedData[i];
      }
    }

    setOtp(updatedOtp);

    // Check if all OTP digits are entered
    const isAllDigitsEntered = updatedOtp.every((digit) => digit !== "");
    if (isAllDigitsEntered) {
      setValid(true);
    }

    // Focus on the next input field if available
    if (index + pastedData.length < otp.length) {
      inputRefs.current[index + pastedData.length].current.focus();
    }
  };

  const handleOtpChange = (event, index) => {
    const updatedOtp = [...otp];

    // Handle backspace
    if (event.target.value === "" && index > 0) {
      // Clear the current digit only when backspacing
      updatedOtp[index] = "";
      setOtp(updatedOtp);
      setError2(null);
      // Focus on the previous input field
      inputRefs.current[index - 1].current.focus();
      return;
    }

    // Update the current digit
    updatedOtp[index] = event.target.value.slice(0, 1);

    // Update the OTP state
    setOtp(updatedOtp);

    // Clear the error message when any input changes
    setErrors(null);
    setError2(null);
    setShowVerifyButton(false);

    // Check if all OTP digits are entered
    const isAllDigitsEntered = updatedOtp.every((digit) => digit !== "");
    if (isAllDigitsEntered) {
      setShowVerifyButton(true);
    }

    // Check if OTP length is equal to 4
    const fullOtp = updatedOtp.join("");
    if (fullOtp.length === 4) {
      setShowVerifyButton(true);
    } else {
      setShowVerifyButton(false);
    }

    // Focus on the next input field if available
    if (index < otp.length - 1 && event.target.value !== "") {
      inputRefs.current[index + 1].current.focus();
    }
  };
  useEffect(() => {
    prefill();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      )
      .then((response) => {
        if (response && response.data && response.data.aboutOne) {
          const responseData = response.data && response.data.aboutOne;
          setInput(responseData);
          setInput({
            ...input,
            userId: responseData.userId,
            about: responseData.about,
          });
          if (!input.bio) {
            setInput((prevInput) => ({
              ...prevInput,
              bio: responseData.bio,
            }));
          }
          setGuardianNumber(response.data.aboutOne.guardianPhoneNumber);
          const number = `+${responseData.fatherPhoneNumber}`;
          setFatherNumber(number);
          const numberMother = `+${responseData.motherPhoneNumber}`;
          setMotherNumber(numberMother);
          const dataPrimary = responseData.primaryAddress;
          const dataPermanent = responseData.permanentAddress;
          const hasGuardianInfo =
            response.data.aboutOne.guardianName &&
            response.data.aboutOne.occupationGuardian &&
            response.data.aboutOne.annualIncomeGuardian &&
            response.data.aboutOne.emailGuardianId &&
            response.data.aboutOne.guardianRelation &&
            response.data.aboutOne.guardianPhoneNumber;
          if (hasGuardianInfo) {
            setGuardian(true); // Automatically check the guardian checkbox
            setOpenParent(false); // Close parental information
            setOpenForm(true); // Open the form (if needed)
          } else {
            setGuardian(false);
            setOpenParent(true);
            setOpenForm(true);
          }
          setTrue(hasGuardianInfo);
          setPreffiledValue(responseData);
          setPrimaryAddress(dataPrimary);
          setPermanentAddress(dataPermanent);
          const dataCore = responseData.coreSkill;
          setCoreSkill(dataCore);
          const dataSkill = responseData.softSkill;
          setSoftSkill(dataSkill);

          setInput(response.data.aboutOne);
          setMiddleName(responseData.middleName);
          setParentInformation({
            ...parentInformation,
            fatherName: responseData.fatherName,
            occupationFather: responseData.occupationFather,
            annualIncomeFather: responseData.annualIncomeFather,
            emailFatherId: responseData.emailFatherId,
            motherName: responseData.motherName,
            occupationMother: responseData.occupationMother,
            annualIncomeMother: responseData.annualIncomeMother,
            emailMotherId: responseData.emailMotherId,
          });

          setGuardianInformation({
            ...guardianInformation,
            guardianName: responseData.guardianName,
            occupationGuardian: responseData.occupationGuardian,
            annualIncomeGuardian: responseData.annualIncomeGuardian,
            emailGuardianId: responseData.emailGuardianId,
            guardianRelation: responseData.guardianRelation,
          });
          setTextAreaCount(responseData.bio.length);
          setTextAreaCount2(responseData.about.length);
          setReligion(responseData.religion);
          setSelectedCountry(responseData.country);
          setSelectedState(responseData.state);
          setSelectedCity(responseData.city);

          if (responseData.inputList2 && responseData.inputList2.length > 0) {
            setInputList2(responseData.inputList2);
          }
        }
      })
      .catch((err) => err.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [countError, setCountError] = useState();
  const verifySubmit = async () => {
    submitAbout();
  };
  const submitAbout = async () => {
    const resumePayload = [];
    if (resumeList.length > 0) {
      await Promise.all(
        resumeList.map(async (resume) => {
          let resumeUrl;
          let formData = new FormData();
          formData.append("image", resume);

          try {
            const res = await axios.post(
              `${process.env.PUBLIC_URL}/xakal/files/`,
              formData
            );
            resumeUrl = res.data.url;
          } catch (error) {
            console.error("Error uploading resume:", error);
          }

          if (resumeUrl) {
            resumePayload.push({ resume: resumeUrl, fileName: resume.name });
          } else {
          }
        })
      );
    } else {
      resumePayload.push(
        ...prefilledValue.inputList3.filter(
          (e) => e.resume !== "" && e.resume !== undefined
        )
      );
    }
    const error = ["", undefined];
    let hasError = false;
    const errorMessages = {
      firstName: error.includes(input.firstName)
        ? "First name is mandatory"
        : "",
      lastName: error.includes(input.lastName) ? "Last name is mandatory" : "",
      gender: error.includes(input.gender) ? "Gender is mandatory!" : "",
      primaryCity: error.includes(primaryAddress?.city)
        ? "City / Town is mandatory"
        : "",
      primaryCountry: error.includes(primaryAddress?.country)
        ? "Country is mandatory"
        : "",
      primaryState: error.includes(primaryAddress?.state)
        ? "State / Province is mandatory"
        : "",
      primaryPincode: error.includes(primaryAddress?.pincode)
        ? "Zip / pincode is mandatory"
        : "",
      permanentCity: error.includes(permanentAddress?.city)
        ? "City / Town is mandatory"
        : "",
      permanentCountry: error.includes(permanentAddress?.country)
        ? "Country is mandatory"
        : "",
      permanentState: error.includes(permanentAddress?.state)
        ? "State / Province is mandatory"
        : "",
      permanentPincode: error.includes(permanentAddress?.pincode)
        ? "Zip / pincode is mandatory"
        : "",
      coreSkill: !coreSkill.length ? "Select at least one skill" : "",
    };

    hasError = Object.values(errorMessages).some((msg) => msg);
    const errorSections = {
      input: !!(
        errorMessages.firstName ||
        errorMessages.lastName ||
        errorMessages.gender
      ),
      primaryAddress: !!(
        errorMessages.primaryCity ||
        errorMessages.primaryCountry ||
        errorMessages.primaryState ||
        errorMessages.primaryPincode
      ),
    };

    // Count the sections with errors
    const errorCount = Object.values(errorSections).filter(Boolean).length;
    if (hasError) {
      setError3({
        firstName: errorMessages.firstName,
        lastName: errorMessages.lastName,
        gender: errorMessages.gender,
        alls: "Please fill out all the necessary fields in this section to save your details",
      });

      setPrimaryAddressError({
        city: errorMessages.primaryCity,
        country: errorMessages.primaryCountry,
        state: errorMessages.primaryState,
        pincode: errorMessages.primaryPincode,
        all: "Please fill out all the necessary fields in this section to save your details",
      });

      setPermamanetAddressError({
        city: errorMessages.permanentCity,
        country: errorMessages.permanentCountry,
        state: errorMessages.permanentState,
        pincode: errorMessages.permanentPincode,
      });
      setCountError(`[${errorCount}]Please provide the mandatory fields`);
    } else {
      setError3({
        firstName: " ",
        lastName: " ",
        gender: " ",
        bio: "",
      });
      setPrimaryAddressError({
        city: "",
        country: "",
        state: "",
        pincode: "",
      });
      setPermamanetAddressError({
        city: "",
        country: "",
        state: "",
        pincode: "",
      });

      if (file instanceof Blob) {
        const reader = new FileReader();

        reader.onload = async (event) => {
          // Create a FormData object with the base64-encoded image
          let formData = new FormData();
          formData.append("image", file);

          await axios
            .post(`${process.env.PUBLIC_URL}/xakal/files/`, formData)
            .then(async (res) => {
              if (res.data) {
                const params = {
                  firstName: input.firstName,
                  middleName: middleName,
                  lastName: input.lastName,
                  email: input.email,
                  avatar: uploadedImage ?? input.avatar, // Use the base64-encoded string for the avatar
                  bio: input.bio,
                  dob: input.dob,
                  title: input.title,
                  gender: input.gender,
                  userType: userType,
                  phoneNumber: verified ? phoneNumber : undefined,
                  about: input.about,

                  coreSkill: coreSkill,
                  softSkill: softSkill,
                  proficiencyInfo: proficiencyInfo,
                  inputList: inputList,
                  inputList2: inputList2,
                  inputList3: resumePayload ?? prefilledValue,
                  fatherName: parentInformation.fatherName,
                  occupationFather: parentInformation.occupationFather,
                  emailFatherId: parentInformation.emailFatherId,
                  annualIncomeFather: parentInformation.annualIncomeFather,
                  motherName: parentInformation.motherName,
                  occupationMother: parentInformation.occupationMother,
                  emailMotherId: parentInformation.emailMotherId,
                  annualIncomeMother: parentInformation.annualIncomeMother,
                  guardianName: guardianInformation.guardianName,
                  occupationGuardian: guardianInformation.occupationGuardian,
                  emailGuardianId: guardianInformation.emailGuardianId,
                  annualIncomeGuardian:
                    guardianInformation.annualIncomeGuardian,
                  guardianRelation: guardianInformation.guardianRelation,
                  fatherPhoneNumber: fatherNumber,
                  motherPhoneNumber: motherNumber,

                  guardianPhoneNumber: guardianNumber,
                  primaryAddress: primaryAddress,
                  permanentAddress: isSameAddress
                    ? primaryAddress
                    : permanentAddress,
                  religion: religion,
                };
                await axios
                  .put(
                    `${
                      process.env.PUBLIC_URL
                    }/xakal/update-about/${Common.loggedIn()}`,
                    params
                  )
                  .then((response) => {
                    // if (verified) {
                    if (response.data.success) {
                      setInputList([...inputList]);
                      setInputList2([...inputList2]);
                      setInputList3([...inputList3]);
                      setSelectedCountry("");
                      setSelectedState("");
                      setParentInformation({ ...parentInformation });
                      setGuardianInformation({ ...guardianInformation });
                      setPrimaryAddress({ ...primaryAddress });
                      setPermanentAddress({ ...permanentAddress });
                    }
                    onClose();
                    onSave();
                  })
                  .catch((error) => {
                    console.error("Error updating item:", error);
                  });
              }
            })
            .catch(() => {});
        };

        // Read the image file as a data URL
        reader.readAsDataURL(file);
      } else {
        const params = {
          firstName: input.firstName,
          middleName: middleName,
          lastName: input.lastName,
          email: input.email,
          avatar: uploadedImage ?? input.avatar,
          bio: input.bio,
          dob: input.dob,
          title: input.title,
          gender: input.gender,
          phoneNumber: verified ? phoneNumber : undefined,
          about: input.about,
          coreSkill: coreSkill,
          softSkill: softSkill,
          proficiencyInfo: proficiencyInfo,
          inputList: inputList,
          inputList2: inputList2,
          inputList3: resumePayload ?? prefilledValue,
          fatherName: parentInformation.fatherName,
          occupationFather: parentInformation.occupationFather,
          emailFatherId: parentInformation.emailFatherId,
          annualIncomeFather: parentInformation.annualIncomeFather,
          motherName: parentInformation.motherName,
          occupationMother: parentInformation.occupationMother,
          emailMotherId: parentInformation.emailMotherId,
          annualIncomeMother: parentInformation.annualIncomeMother,
          guardianName: guardianInformation.guardianName,
          occupationGuardian: guardianInformation.occupationGuardian,
          emailGuardianId: guardianInformation.emailGuardianId,
          annualIncomeGuardian: guardianInformation.annualIncomeGuardian,
          guardianRelation: guardianInformation.guardianRelation,
          fatherPhoneNumber: fatherNumber,
          motherPhoneNumber: motherNumber,
          primaryAddress: primaryAddress,
          permanentAddress: isSameAddress ? primaryAddress : permanentAddress,
          religion: religion,
          guardianPhoneNumber: guardianNumber,
          userType: userType,
        };

        // else if(!error3){
        axios
          .put(
            `${process.env.PUBLIC_URL}/xakal/update-about/${Common.loggedIn()}`,
            params
          )
          .then((response) => {
            // if (verified) {
            if (response.data.success) {
              setInput({
                ...input,
                avatar: "",
              });
              setInputList([...inputList]);
              setInputList2([...inputList2]);
              setInputList3([...inputList3]);
              setSelectedCountry("");
              setSelectedState("");
              setParentInformation({ ...parentInformation });
              setGuardianInformation({ ...guardianInformation });
              setPrimaryAddress({ ...primaryAddress });
              setPermanentAddress({ ...permanentAddress });
            }
            onClose();
            onSave();
          })
          .catch((error) => {
            console.error("Error updating item:", error);
          });
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const handleCheckboxChange = (e, index) => {
    const { name, checked } = e.target;
    const list = [...inputList];
    list[index][name] = checked;
    setInputList(list);
  };
  // eslint-disable-next-line no-unused-vars
  const [selectedCountry, setSelectedCountry] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [selectedState, setSelectedState] = useState("");

  const DiscardChanges = () => {
    setInput({
      ...input,
    });
    setInputList([...inputList]);
    setInputList2([...inputList2]);
    setInputList3([...inputList3]);
    setSelectedCountry("");
    setSelectedState("");
    onClose();
    setPrimaryAddress("");
    setError3("");
  };

  const CloseModalClear = () => {
    if (!dirty) {
      onClose();
    } else {
      setCloseModal(true);
      setError3("");
      setPrimaryAddress("");
    }
  };
  const [skillData, setSkillData] = useState([]);
  const [openResume, setOpenResume] = useState();
  const [resumeId, setResumeId] = useState();
  const [message2, setMessage2] = useState("");

  const resumeOpen = (id) => {
    setResumeId(id);
    setOpenResume(true);
  };
  const [deleteLoading, setDeleteLoading] = useState(false);
  const deleteResume = () => {
    setDeleteLoading(true);
    axios
      .delete(
        `${process.env.PUBLIC_URL}/xakal/about/${input._id}/resume/${resumeId}`
      )
      .then((response) => {
        setOpenResume(false);
        prefill();
        setDeleteLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setDeleteLoading(false);
      });
  };
  const getSkill = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/skills`)
      .then((response) => {
        const companyValue = response.data.map((e) => ({
          skillIds: e.skillId,
          label: e.label,
          value: e.value,
          ratings: e.ratings,
          averageSkillRating: e.averageSkillRating,
        }));

        setSkillData(companyValue);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getSkill();
  }, []);

  const [skillcreate, setSkillCreate] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [rating, setRating] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [userId, setUserId] = useState("");
  useEffect(() => {
    setIsSameAddress(false);
  }, []);
  const createSkill = () => {
    const payload = {
      name: skillcreate,
    };
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/skill`, payload)
      .then((response) => {
        setSkillCreate("");
        setOptionsOneSkill([]);
        getSkill();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const parentRef = useRef();
  useEffect(() => {
    if (parentRef.current) {
      autoAnimate(parentRef.current);
    }
  }, [parentRef]);

  const handleGuardianCheckbox = () => {
    if (openGuardian) {
      setGuardian(false);
      setOpenParent(true);
      setOpenForm(true);
    } else {
      setGuardian(true);
      setOpenForm(true);
      setOpenParent(false);
    }
    setTrue((prevValue) => !prevValue);
  };

  const handlePrimaryAddressChange = (field, value) => {
    setPrimaryAddress({
      ...primaryAddress,
      [field]: value,
    });

    if (field === "pincode" && value.trim() !== "") {
      setPrimaryAddressError("");
    }
    if (field === "city" && value.trim() !== "") {
      setPrimaryAddressError("");
    }
    if (field === "country" && value.trim() !== "") {
      setPrimaryAddressError("");
    }
    if (field === "state" && value.trim() !== "") {
      setPrimaryAddressError("");
    }
  };

  const handlePermanentAddressChange = (field, value) => {
    // Update the permanent address only if the checkbox is unchecked
    if (!isSameAddress) {
      setPermanentAddress((prevAddress) => ({
        ...prevAddress,
        [field]: value,
      }));
    }
  };

  const handleCheckboxsChange = () => {
    setIsSameAddress(!isSameAddress);
    setPermamanetAddressError("");
  };

  useEffect(() => {
    if (isSameAddress) {
      setPermanentAddress({ ...primaryAddress });
    } else {
      setPermanentAddress({
        addressOne: "",
        addressTwo: "",
        city: "",
        country: "",
        state: "",
        pincode: "",
      });
    }
  }, [isSameAddress]);

  const handleCreatable = async (e) => {
    if (e[e?.length - 1]?.__isNew__) {
      await createSkill();
    }
    handleDropdown2(e);
  };
  const [softSkillData, setSoftSkillData] = useState([]);
  const getSoftSkill = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/soft-skills`)
      .then((response) => {
        const companyValue = response.data.map((e) => ({
          skillIds: e.skillId,
          label: e.label,
          value: e.value,
          ratings: e.ratings,
          averageSkillRating: e.averageSkillRating,
        }));

        setSoftSkillData(companyValue);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    getSoftSkill();
  }, []);
  const handleCreatableSoftSkill = async (e) => {
    if (e[e?.length - 1]?.__isNew__) {
      await createSoftSkill();
    }
    handleDropdown1(e);
  };
  const [softSkillCreate, setSoftSkillCreate] = useState("");
  const createSoftSkill = () => {
    const payload = {
      name: softSkillCreate,
      rating: rating,
      userId: [userId],
    };
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/softSkill`, payload)
      .then((response) => {
        setSoftSkillCreate("");
        setOptionsOne([]);
        getSoftSkill();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const [optionDataSkill, setOptionsOneSkill] = useState([]);

  useEffect(() => {
    if (skillcreate.length > 2) {
      setOptionsOneSkill(
        skillData.map((e) => ({
          skillIds: e.skillIds,
          label: e.label,
          value: e.value,
          ratings: e.ratings,
          averageSkillRating: e.averageSkillRating,
          candidateId: Common.loggedIn(),
        }))
      );
    } else {
      setOptionsOneSkill([]);
    }
  }, [skillData, skillcreate]);
  const [optionData, setOptionsOne] = useState([]);

  useEffect(() => {
    if (softSkillCreate.length > 2) {
      setOptionsOne(
        softSkillData.map((e) => ({
          skillIds: e.skillIds,
          label: e.label,
          value: e.value,
          ratings: e.ratings,
          averageSkillRating: e.averageSkillRating,
          candidateId: Common.loggedIn(),
        }))
      );
    } else {
      setOptionsOne([]);
    }
  }, [softSkillData, softSkillCreate]);

  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <></>
      </components.DropdownIndicator>
    );
  };
  const sendOtp = async (e) => {
    e.preventDefault();
    setLoadingOtp(true);
    setMinutes(1);
    setSeconds(0);
    setEnable(false);
    if (phoneNumber === "") {
      alert("Enter Your phoneNumber !");
    } else {
      setSpiner(true);
      setEnabled(true);
      const data = {
        phoneNumber: phoneNumber,
        userId: input?.userId,
      };

      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/send-mobile-otp`,
        data
      );
      if (response.status === 200) {
        setSpiner(false);
      } else {
        // toast.error(response.response.data.error);
      }
    }
  };
  const verifyOtp = async (e) => {
    e.preventDefault();

    if (otp.join("").length !== 4) {
      setError2("Invalid OTP");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/verify-mobile-otp`,
        {
          otp: otp.join(""),
          phoneNumber: phoneNumber,
          userId: input?.userId,
        }
      );

      if (response.data.success === true) {
        setVerify(true);
        setEnabled(false);
        setEnable3(false);
        setPhoneTouched({ phoneNumber: "" });
        // setMessage(response.data.message);
        toast.success(response.data.message);
        setVerifiedNumber(phoneNumber);
        prefillMobile();
        setShowVerifyButton(false);
      } else if (response.data.verified === true) {
        setEnable3(false);
        setEnable4(true);
        setMessage2("Mobile Number Verified");
      } else {
        // Handling incorrect OTP
        if (count > 1) {
          setCount(count - 1);
          if (count === 2) {
            setError2("Only 1 attempt left.");
          } else {
            setError2("Incorrect OTP");
          }
        } else if (count === 1) {
          // Handling maximum attempts reached
          setMinutes(1);
          setSeconds(0);
          setCount(5);
          setError2("Maximum attempts reached. Try again later.");
          setValid(false);
          setDisable(false);
          setTimeout(() => {
            setError2(null);
          }, 1000);
        }
      }
    } catch (error) {
      setErrors(error.response.data.error);
    }
  };

  const resendOtp = async (e) => {
    e.preventDefault();
    setDisable(true);
    setEnabled(true);
    setMinutes(1);
    setSeconds(0);
    setEnable(false);
    if (input.email === "") {
      alert("Enter Your Email !");
    } else if (!input.email.includes("@")) {
      alert("Enter Valid Email !");
    } else {
      // setSpiner(true);
      const data = {
        phoneNumber: phoneNumber,
        userId: input?.userId,
      };

      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/resend-mobile-otp`,
        data
      );
      if (response.status === 200) {
      } else {
      }
    }
  };
  const prefillMobile = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      )
      .then((response) => {
        if (response && response.data && response.data.aboutOne) {
          const responseData = response.data && response.data.aboutOne;

          const verifyFalse = responseData.mobileNumberVerified;
          setVerified(verifyFalse);
        }
      })
      .catch((err) => err.error);
  };
  const userTypeChange = (e, value) => {
    if (e.target?.checked) {
      setUserType([...userType, value]);
    } else {
      // remove from list
      setUserType(userType.filter((people) => people !== value));
    }
  };
  const userTypeChanges = (value) => {
    if (userType.includes(value)) {
      setUserType(userType.filter((type) => type !== value));
    } else {
      setUserType([...userType, value]);
    }
  };
  const [openOne, setOpenOne] = useState(false);
  const handleOpen = () => {
    setOpenOne(!openOne); // Toggle the state value
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <div className="">
        <Dialog
          open={closemodal}
          PaperProps={{
            style: {
              borderRadius: "16px",
              top: "10px",
              transform: "translateY(0%)",
              position: "absolute",
              backgroundColor: "#FFDAE0",
              padding: "5px 8px",
              height: "fit-content",
            },
          }}
          halfWidth
          maxWidth="md"
          // className="def"
        >
          <DialogContent
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontFamily: "Inter-Medium",
              position: "relative",
              left: "5px",
              // height: "56px",
              // top: "-10px",
            }}
          >
            <IconWrapper>
              <img
                src={DeleteImg}
                alt="img"
                style={{ width: "24px", height: "24px" }}
              />
            </IconWrapper>
            <JobTitle>
              Are you sure you want to discard the changes you made?
            </JobTitle>

            <DialogActions>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  gap: "10px",
                  marginLeft: "2rem",
                  marginRight: "10px",
                }}
              >
                <Button4
                  onClick={() => setCloseModal(false)}
                  style={{ cursor: "pointer" }}
                >
                  Go Back
                </Button4>
                <Button6 onClick={DiscardChanges}>Yes, Discard</Button6>
              </div>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <ToastContainer />

        <div className="modalHeader" style={{ padding: "10px 20px" }}>
          <h1 className="mTitle">Resume</h1>
          <img
            src={CloseIcon}
            className="mClose"
            alt="img"
            onClick={CloseModalClear}
            style={{ width: "30px", height: "30px" }}
          />
        </div>

        <div className="modalBody">
          
          <div className="">
          <div className="accordion">
              <div className="accordion-item">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="AboutPopup-head"
                  >
                    Resume
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      {prefilledValue &&
                      prefilledValue?.inputList3 &&
                      prefilledValue?.inputList3.length > 0 ? (
                        prefilledValue?.inputList3.map((x, i) => {
                          return x.resume !== "" ? (
                            <div key={i}>
                              {x.resume !== "" && (
                                <InputGroup
                                  className="footer-header mb-3"
                                  style={{ width: "85%" }}
                                >
                                  <div>
                                    {x.resume ? (
                                      <>
                                        <Form.Control
                                          id={`fileInputText${i}`}
                                          className="footer-header mb-3"
                                          aria-describedby="basic-addon1"
                                          type="text"
                                          value={x.fileName}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        <div id={`fileNamesDisplay${i}`}></div>
                                      </>
                                    )}

                                    <Form.Control
                                      id={`fileInput${i}`}
                                      type="file"
                                      name="resume"
                                      onChange={(e) => handleInputChange3(e, i)}
                                      onBlur={validateInput}
                                      style={{ display: "none" }}
                                    />
                                  </div>

                                  <button
                                    className="button2Resume"
                                    onClick={() =>
                                      document
                                        .getElementById(`fileInput${i}`)
                                        .click()
                                    }
                                  >
                                    Choose File
                                  </button>
                                </InputGroup>
                              )}
                              <span
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                  position: "absolute",
                                  right: "10px",
                                  border: "1px solid #E1E1E4",
                                  borderRadius: "8px",
                                  padding: "5px",
                                  marginTop: "-56px",
                                }}
                                onClick={() => resumeOpen(x._id)}
                              >
                                <img
                                  src={Delete}
                                  alt="img"
                                  style={{ width: "24px", height: "24px" }}
                                />
                              </span>
                            </div>
                          ) : (
                            ""
                          );
                        })
                      ) : (
                        <>
                          {inputList3.map((input, i) => (
                            <div key={i}>
                              <label
                                className="label-name"
                                style={{
                                  marginTop: "20px",
                                  background: "rgba(244, 244, 246, 1)",
                                  padding: "15px",
                                  borderRadius: "8px",
                                  width: "100%",
                                }}
                              >
                                Upload Resume
                                <span
                                  style={{
                                    cursor: "pointer",
                                    padding: "10px",
                                    background: "rgba(225, 225, 228, 1)",
                                    borderRadius: "8px",
                                    position: "absolute",
                                    right: "20px",
                                    marginTop: "-10px",
                                    color: "#362A41",
                                    fontSize: "12px",
                                  }}
                                  onClick={() =>
                                    document
                                      .getElementById(`formFileLg${i}`)
                                      .click()
                                  }
                                >
                                  Choose File
                                </span>
                              </label>
                              <div>
                                <div>
                                  <input
                                    className="footer-header"
                                    style={{
                                      padding: "10px",
                                      background: "#E1E1E4",
                                      borderRadius: "8px",
                                    }}
                                    id={`formFileLg${i}`}
                                    type="file"
                                    name="resume"
                                    onChange={(e) =>
                                      handleInputChangeFile3(e, i)
                                    }
                                    // onBlur={validateInput}
                                  />
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      gap: "12rem",
                                      margin: "10px 0",
                                    }}
                                  >
                                    {" "}
                                    <div id={`fileNameDisplay${i}`}>
                                      {displayedFileNames[i]}
                                    </div>
                                  </div>
                                </div>

                                <div
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  {inputList3.length - 1 === i && (
                                    <button
                                      onClick={handleAddClick3}
                                      className="Add-languageprofile"
                                      disabled={
                                        input?.resume === "" ||
                                        input?.resume === undefined
                                      }
                                    ></button>
                                  )}
                                  {inputList3.length !== 1 && (
                                    <button
                                      className="mr10 Add-language3"
                                      onClick={() => handleRemoveClick3(i)}
                                    >
                                      <img
                                        src={Delete}
                                        alt="img"
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                          border: "1px solid grey",
                                          padding: "5px",
                                          margin: "0 20px 0 20px",
                                        }}
                                      />
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>

            
          </div>
        </div>
        
        <div className="modalFooter">
          <button className="text-danger f12" onClick={() => handleOpen()}>
            {countError}
          </button>
          <button className="button5" onClick={CloseModalClear}>
            Cancel
          </button>
          <button className="button3" onClick={verifySubmit}>
            Save
          </button>
        </div>
      </div>
      <Dialog
        open={openResume}
        PaperProps={{
          style: {
            borderRadius: "16px",
            top: "10px",
            transform: "translateY(0%)",
            position: "absolute",
            backgroundColor: "#FFDAE0",
            padding: "5px 8px",
            height: "fit-content",
          },
        }}
        halfWidth
        maxWidth="md"
      >
        <DialogContent
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
            position: "relative",
            left: "5px",
          }}
        >
          <IconWrapper>
            <img
              src={DeleteImg}
              alt="img"
              style={{ width: "24px", height: "24px" }}
            />
          </IconWrapper>

          <JobTitle>Are you sure you want to delete this resume ?</JobTitle>
          <DialogActions>
            <div
              style={{
                position: "relative",
                display: "flex",
                gap: "10px",
                marginLeft: "2rem",
                marginRight: "10px",
              }}
            >
              <Button4 onClick={() => setOpenResume(false)}>No</Button4>
              <Button6 onClick={deleteResume}>
                {deleteLoading ? <Spinner /> : "Yes, Delete"}
              </Button6>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AboutModal;
