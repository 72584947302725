/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect, useCallback } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import isValidDomain from "is-valid-domain";
import "./accordian.css";
import { Col, Row } from "reactstrap";
import ReactQuill from "react-quill";
import Addcircle from "../../images/Add Circle.svg";
import "react-quill/dist/quill.snow.css";
import companyLogo from "../../images/company.png";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiSolidPencil } from "react-icons/bi";
import "./aboutmodal.css";
import Spinner from "react-bootstrap/Spinner";
import { components } from "react-select";
import axios from "axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Common from "../../utils/common";
import styled from "styled-components";
import { Country, State, City } from "country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import languages from "languages-list";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Delete from "../../images/DelIcon.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreatableSelect from "react-select/creatable";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import CloseIcon from "../../images/CloseIcon.svg";
import DeleteImg from "../../images/solar_shield-warning-bold-duotone.svg";
import autoAnimate from "@formkit/auto-animate";
import Profile from "../../images/SME PNG.png";
import Profile2 from "../../images/Job Seeker PNG.png";
import Profile3 from "../../images/Recruiter PNG.png";
const customStyle = {
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    border: "none",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    minHeight: "48px",
    height: "fit-content",
    color: "#4f4953",
    fontWeight: "600",
    fontFamily: "Inter-Medium,sans-serif",
  }),
  menu: (provided, state) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    "&:hover": {
      color: "#362A41", // Add hover text color
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 6px)",
      marginLeft: "5px",
    },
  }),
  // Add more styles as needed
};
const JobTitle = styled.div`
  width: auto;
  // height: 22px;
  top: 24px;
  left: 0px;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #40444f;
  margin-left: 10px;

  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 16px;
    // margin: -15px 0 0 0;
  }
`;
const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;

  @media (max-width: 600px) {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    gap: 4px;
  }
`;

const Button4 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: Inter-Medium, sans-serif;
  color: #2d3139;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
  font-weight: normal;
  font-size: 14px;
  color: #2d3139;
  border: 1px solid #1213171a;
  padding: 12px;
  width: 94px;
  height: 40px;
  // position: relative;
  // top: 3px;
  cursor: pointer;
  @media (max-width: 576px) {
    width: 65px;
    height: 30px;
    padding: 6px;
    font-size: 12px;
  }
`;
const Button6 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fd2145;
  // position: relative;
  // top: 3px;
  font-family: Inter-Medium, sans-serif;
  border-radius: 10px;
  font-weight: normal;
  font-size: 14px;
  color: white;
  padding: 12px 16px;

  cursor: pointer;
  @media (max-width: 576px) {
    width: 85px;
    height: 30px;
    padding: 6px;
    font-size: 12px;
  }
`;

const Label = styled.div`
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 16.8px */
  text-transform: capitalize;
  margin: 10px 0 5px 0;
  span {
    color: #ff4873;
    font-size: 16px;
    font-weight: 800;
    margin-left: 0px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  button {
    width: 800px;
    height: 40px;
    margin-top: 10px;
    padding: 7px, 142px, 9px, 131px;
    border-radius: 8px;
    border: 0.5px solid rgba(221, 223, 228, 0.5);
    font-family: Inter-Medium, sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(54, 42, 65, 1);
  }
`;
const Get = styled.div`
  color: #8652b6;
  font-family:
    Visby CF,
    sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  cursor: pointer;
`;
const OtpHead = styled.div`
  color: #362a41;
  font-family:
    Visby CF,
    sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0;
  text-align: left;
`;
const options = [
  { value: "", label: "Select" },
  { value: "Beginner", label: "Beginner" },
  { value: "Pre-intermediate", label: "Pre-intermediate" },
  { value: "Intermediate", label: "Intermediate" },
  { value: "Upper-Intermediate", label: "Upper-Intermediate" },
  { value: "Advanced", label: "Advanced" },
  { value: "Mastery", label: "Mastery" },
];

const AboutModal = ({
  onClose,
  onClick,
  file,
  capture,
  uploadedImage,
  onFile,
  about,
  onSave,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [userType, setUserType] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [inputList, setInputList] = useState([
    { language: "", proficiency: "", read: false, write: false, speak: false },
  ]);
  const [inputList2, setInputList2] = useState([
    { workProfile: "", urlLink: "" },
  ]);
  // Format current month with leading zero if needed
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verified, setVerified] = useState();
  const [verfifySpinner, setVerifySpiner] = useState(false);
  const minDate = "1940-01-01";
  const [showVerifyButton, setShowVerifyButton] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [inputList3, setInputList3] = useState([{ resume: "" }]);
  const [resumeList, setResumeList] = useState([]);
  const [coreSkill, setCoreSkill] = useState([]);
  const [softSkill, setSoftSkill] = useState([]);
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [openGuardian, setGuardian] = useState(false);
  const [error3, setError3] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    avatar: "",
    bio: "",
    country: "",
    city: "",
    coreSkill: "",
    softSkill: "",
    state: "",
    language: "",
    proficiency: "",
    dob: "",
    title: "",
    gender: "",
    pincode: "",
    about: "",
    workProfile: "",
    urlLink: "",
    resume: "",
    alls: "",
  });
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [errors, setErrors] = useState(null);
  const [valid, setValid] = useState(false);
  const [verify, setVerify] = useState(false);
  const [enable, setEnable] = useState(false);
  const [enable2, setEnable2] = useState(false);
  const [enable3, setEnable3] = useState(true);
  const [enable4, setEnable4] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [spinner, setSpiner] = useState(false);
  const [count, setCount] = useState(5);
  const [disable, setDisable] = useState(true);
  const [message, setMessage] = useState(null);
  const [error2, setError2] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [proficiencyInfo, setProficiencyInfo] = useState(null);

  const [input, setInput] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    title: "",
    avatar: "",
    bio: "",
    country: "",
    city: "",
    // skill: "",
    coreSkill: "",
    softSkill: "",
    state: "",
    dob: "",
    gender: "",
    pincode: "",
    about: "",
    workProfile: "",
    urlLink: "",
    resume: "",
    alls: "",
  });
  const [parentInformation, setParentInformation] = useState({
    guardian: false,
    fatherName: "",
    occupationFather: "",
    annualIncomeFather: "",
    emailFatherId: "",
    motherName: "",
    occupationMother: "",
    annualIncomeMother: "",
    emailMotherId: "",
  });
  const [guardianInformation, setGuardianInformation] = useState({
    guardianName: "",
    occupationGuardian: "",
    annualIncomeGuardian: "",
    emailGuardianId: "",
    guardianRelation: "",
  });
  const [primaryAddress, setPrimaryAddress] = useState({
    addressOne: "",
    addressTwo: "",
    city: "",
    country: "",
    state: "",
    pincode: "",
    all: "",
  });
  const [primaryAddressError, setPrimaryAddressError] = useState({
    addressOne: "",
    addressTwo: "",
    city: "",
    country: "",
    state: "",
    pincode: "",
    all: "",
  });
  const [permananetAddressError, setPermamanetAddressError] = useState({
    addressOne: "",
    addressTwo: "",
    city: "",
    country: "",
    state: "",
    pincode: "",
  });
  const [permanentAddress, setPermanentAddress] = useState({
    addressOne: "",
    addressTwo: "",
    city: "",
    country: "",
    state: "",
    pincode: "",
  });
  // const currentYear = new Date().getFullYear();
  const [textAreaCount2, setTextAreaCount2] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [fatherNumber, setFatherNumber] = useState("");
  const [motherNumber, setMotherNumber] = useState("");
  const [guardianNumber, setGuardianNumber] = useState("");
  const [religion, setReligion] = useState("");
  const [loadingOtp, setLoadingOtp] = useState(false);

  const handleFatherPhoneChange = (value) => {
    setFatherNumber(value);
  };
  const handleMotherPhoneChange = (value) => {
    setMotherNumber(value);
  };
  const handleGuardianPhoneChange = (value) => {
    setGuardianNumber(value);
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorMessageOne, setErrorMessageOne] = useState(null);
  const [errorMessageOneGuardian, setErrorMessageOneGuardian] = useState(null);

  const handleParentChange = (e) => {
    const { name, value } = e.target;
    let finalValue = value;

    if (name === "fatherName" || name === "motherName") {
      finalValue = value.replace(/[^a-z ]/gi, "");
    } else if (name === "annualIncomeFather" || name === "annualIncomeMother") {
      finalValue = value.replace(/[^0-9]/gi, "");
    } else if (name === "emailFatherId") {
      if (isValidEmail(value)) {
        setErrorMessage(null);
      } else {
        setErrorMessage("Invalid email id");
      }
    } else if (name === "emailMotherId") {
      if (isValidEmail(value)) {
        setErrorMessageOne(null);
      } else {
        setErrorMessageOne("Invalid email id");
      }
    } else {
    }

    setParentInformation((prevInfo) => ({
      ...prevInfo,
      [name]: finalValue,
    }));
  };
  const [verifiedNumber, setVerifiedNumber] = useState();

  //mobile handleChange
  const handlePhoneChange = (value) => {
    if (verifiedNumber === value) {
      setPhoneNumber(value);
      setEnable3(false); // Disable OTP button if number is verified
    } else {
      setPhoneNumber(value);
      setEnable3(true); // Enable OTP button if number is not verified
    }
  };
  const handleGuardianChange = (e) => {
    const { name, value } = e.target;
    let finalValue = value;

    if (name === "guardianName" || name === "guardianRelation") {
      finalValue = value.replace(/[^a-z ]/gi, "");
    } else if (name === "annualIncomeGuardian") {
      finalValue = value.replace(/[^0-9]/gi, "");
    } else if (name === "emailGuardianId") {
      if (isValidEmail(value)) {
        setErrorMessageOneGuardian(null);
      } else {
        setErrorMessageOneGuardian("Invalid email id");
      }
    } else {
    }

    setGuardianInformation((prevInfo) => ({
      ...prevInfo,
      [name]: finalValue,
    }));
  };
  const handleMiddle = (e) => {
    const value = e.target.value;

    const onlyLettersRegex = /^[a-zA-Z\s]+$/;

    if (value === "" || onlyLettersRegex.test(value)) {
      setMiddleName(value);
    }
  };

  const handleModalChange = (event) => {
    const { name, value } = event.target;

    const onlyLettersRegex = /^[a-zA-Z\s]+$/;

    if (name === "firstName" || name === "lastName") {
      // Allow backspacing even if the current value doesn't match the regex
      if (value !== "" && !onlyLettersRegex.test(value)) {
        return;
      }
    }

    setInput((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));

    setDirty(true);
    setError3({
      alls: "",
    });
  };
  const handleModalBioChange = (event) => {
    setInput((prevInput) => ({
      ...prevInput,
      bio: event.target.value,
    }));
    setDirty(true);

    if (event.target.name === "bio") {
      setTextAreaCount(event.target.value.length);
    }
  };

  const quillRef = useRef();
  const handleJobDescChange = useCallback(
    (content, delta, source, editor) => {
      const value = editor.getText(); // Get the plain text content

      if (value.length <= 1000) {
        setInput({ ...input, about: content });
        setDirty(true);
        setTextAreaCount2(value.length);
      } else {
        console.error("Input exceeds 1000 characters");
      }
    },
    [input]
  );

  useEffect(() => {
    const quill = quillRef.current.getEditor();
    if (quill) {
      const updateTextAreaCount = () => {
        setTextAreaCount2(quill.getLength());
      };
      quill.on("text-change", updateTextAreaCount);
      return () => {
        quill.off("text-change", updateTextAreaCount);
      };
    }
  }, []);
  const validateInput = (e) => {
    let { name, value } = e.target;
    setError3((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "firstName":
          const regex = /^[a-zA-Z\s]+$/.test(value);
          if (value === "") {
            stateObj[name] = "First name is mandatory";
          } else if (!regex) {
            stateObj[name] = "Please enter valid name.";
          }
          break;

        case "lastName":
          const regex1 = /^[a-zA-Z\s]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Last name is mandatory";
          } else if (!regex1) {
            stateObj[name] = "Please enter valid last name";
          }
          break;

        case "gender":
          const regex2 = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = " Gender is a mandatory filed !";
          } else if (!regex2) {
            stateObj[name] = "Please enter valid detail.";
          }
          break;
        case "bio":
          const regexbio = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = "HeadLine is mandatory";
          } else if (!regexbio) {
            stateObj[name] = "Please enter valid last name.";
          }
          break;
        case "dob":
          if (value === "") {
            stateObj[name] = "Please select date of birth";
          }

          break;

        case "country":
          if (value === "") {
            stateObj[name] = "Please select country";
          }
          break;

        case "state":
          if (value === "") {
            stateObj[name] = "Please select state";
          }
          break;

        case "city":
          if (value === "") {
            stateObj[name] = "Please select city";
          }
          break;

        case "pincode":
          const regex3 = /^[0-9]{6,6}$/.test(value);
          if (value === "") {
            stateObj[name] = "Zip / pincode is a mandatory";
          } else if (!regex3) {
            stateObj[name] = "Please enter valid pincode.";
          }
          break;

        case "coreSkill":
          if (coreSkill.length === 0 || coreSkill === undefined) {
            stateObj[name] = "Please select university";
          }
          break;

        case "softSkill":
          // const regex1 = /^[a-zA-Z]+$/.test(value);
          if (value === "" || value === undefined) {
            stateObj[name] = "Please select degree";
          }

          break;

        case "language":
          if (value === "" || value === undefined) {
            stateObj[name] = "Please select language";
          }

          break;

        case "proficiency":
          if (value === "" || value === undefined) {
            stateObj[name] = "Please select your proficiency";
          }
          break;

        case "workProfile":
          if (value === "") {
            stateObj[name] = "Please enter work profile name";
          }
          break;

        case "urlLink":
          if (value === "") {
            stateObj[name] = "Please enter URL link";
          } else if (
            !isValidDomain(getDomainFromURL(value), { allowSubdomains: false })
          ) {
            stateObj[name] = "Please enter a valid domain";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const [closemodal, setCloseModal] = useState(false);
  const [textAreaCount, setTextAreaCount] = useState("");

  const handleInputChange = (e, index) => {
    handleModalChange(e);
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    setDirty(true);
  };
  const handleProficiencyChange = (e, index) => {
    const { name, value } = e.target;

    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    setDirty(true);
  };
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { language: "", proficiency: "" }]);
  };
  const handleInputChange2 = (e, index) => {
    handleModalChange(e);
    const { name, value } = e.target;
    const list = [...inputList2];
    list[index][name] = value;
    setInputList2(list);
    setDirty(true);
  };
  const handleInputChange2s = (e, index) => {
    handleModalChange(e);
    const { name, value } = e.target;
    const list = [...inputList2];
    list[index][name] = value;
    setInputList2(list);
    setDirty(true);
  };

  const handleRemoveClick2 = (index) => {
    const list = [...inputList2];
    list.splice(index, 1);
    setInputList2(list);
  };

  const getDomainFromURL = (url) => {
    const match = url.match(/^(?:https?:\/\/)?(?:www\d?\.)?([^/]+)/i);
    if (
      match != null &&
      match.length > 1 &&
      typeof match[1] === "string" &&
      match[1].length > 0
    ) {
      return match[1];
    } else {
      return null;
    }
  };

  const handleAddClick2 = () => {
    setInputList2([...inputList2, { workProfile: "", urlLink: "" }]);
  };

  const handleInputChange3 = (e, index) => {
    handleModalChange(e);
    const { files } = e.target;
    const list = [...inputList3];

    const maxFileSize = 12 * 1024 * 1024;
    if (files && files.length > 0) {
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ]; // Allow only PDF and Word files
      const fileType = files[0].type;

      if (!allowedTypes.includes(fileType)) {
        toast.error("Invalid file type. Only PDF and Word files are allowed.");
        return;
      }

      if (files[0].size > maxFileSize) {
        toast.error("File size exceeds the maximum limit of 12MB.");
        return;
      }
    }
    const fileNameDisplayElement = document.getElementById(
      `fileNamesDisplay${index}`
    );
    if (fileNameDisplayElement) {
      fileNameDisplayElement.textContent =
        files && files.length > 0 ? files[0].name : "";
    }
    if (!files || files.length === 0) {
      list[index].resume = prefilledValue.inputList3[index].resume;
      list[index].fileName = prefilledValue.inputList3[index].fileName;
    } else {
      const resume = files[0];
      let previousData = resumeList;
      previousData.push(resume);
      setInputList3(previousData);
      setInputList3(list);
    }

    // Update the input list state
    setDirty(true);
  };

  const [displayedFileNames, setDisplayedFileNames] = useState([""]);

  const handleInputChangeFile3 = (e, index) => {
    e.preventDefault();
    handleModalChange(e);
    const { files } = e.target;
    const list = [...inputList3];

    const maxFileSize = 12 * 1024 * 1024;
    if (files && files.length > 0) {
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ]; // Allow only PDF and Word files
      const fileType = files[0].type;

      if (!allowedTypes.includes(fileType)) {
        toast.error("Invalid file type. Only PDF and Word files are allowed.");
        return;
      }

      if (files[0].size > maxFileSize) {
        toast.error("File size exceeds the maximum limit of 12MB.");
        return;
      }
    }

    setDisplayedFileNames((prevNames) => {
      const newNames = [...prevNames];
      newNames[index] = files && files.length > 0 ? files[0].name : "";
      return newNames;
    });

    // Update only the current item in the list
    if (!files || files.length === 0) {
      list[index].resume = prefilledValue?.inputList3[index]?.resume;
      list[index].fileName = prefilledValue?.inputList3[index]?.fileName;
    } else {
      const resume = files[0];
      let previousData = resumeList;
      previousData.push(resume);
      setInputList3(previousData);
      setInputList3(list);
    }

    setDirty(true);
  };

  const handleRemoveClick3 = (index) => {
    const list = [...inputList3];
    list.splice(index, 1);
    setInputList3(list);

    //   updated code
    let previousData = resumeList;
    previousData.splice(index, 1);
    setResumeList(previousData);
  };

  const handleAddClick3 = () => {
    setInputList3([...inputList3, { resume: "" }]);
  };

  const handleDropdown1 = (selectedOptions) => {
    const formattedSkills = selectedOptions.map((option) => ({
      skillIds: option.skillIds,
      label: option.label,
      value: option.value,
      ratings: option.ratings,
      averageSkillRating: option.averageSkillRating,
      candidateId: Common.loggedIn(),
    }));

    if (selectedOptions && selectedOptions.length <= 100) {
      setSoftSkill(formattedSkills);
      setOptionsOne([]);
      setSoftSkillCreate("");
    }
  };

  const handleDropdown2 = (selectedOptions) => {
    const formattedSkills = selectedOptions.map((e) => ({
      skillIds: e.skillIds,
      label: e.label,
      value: e.value,
      ratings: e.ratings,
      averageSkillRating: e.averageSkillRating,
      candidateId: Common.loggedIn(),
    }));
    if (selectedOptions && selectedOptions.length <= 100) {
      setCoreSkill(formattedSkills);
      setSkillCreate("");
      setOptionsOneSkill([]);
    }
    // Not sure where setDirty comes from in your code
  };
  const [mobileNumber, setMobileNumber] = useState("");
  const [phoneNumberTouched, setPhoneTouched] = useState(false);
  const [prefilledValue, setPreffiledValue] = useState(null);
  const [openParent, setOpenParent] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [openForm, setOpenForm] = useState(false);
  const [trueValue, setTrue] = useState(false);

  const prefill = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      )
      .then((response) => {
        if (response && response.data && response.data.aboutOne) {
          const responseData = response.data && response.data.aboutOne;
          setGuardianNumber(response.data.aboutOne.guardianPhoneNumber);
          const number = `+${responseData.fatherPhoneNumber}`;
          setFatherNumber(number);
          const numberMother = `+${responseData.motherPhoneNumber}`;
          setMotherNumber(numberMother);
          const dataPrimary = responseData.primaryAddress;
          const dataPermanent = responseData.permanentAddress;
          setPreffiledValue(responseData);
          setPrimaryAddress(dataPrimary);
          setPermanentAddress(dataPermanent);
          const dataCore = responseData.coreSkill;
          setCoreSkill(dataCore);
          const dataSkill = responseData.softSkill;
          setSoftSkill(dataSkill);
          const rawPhoneNumber = responseData.phoneNumber;
          const formattedPhoneNumber = `+${rawPhoneNumber}`; // Format it as E.164
          const verify = responseData.mobileNumberVerified === true;
          setMobileNumber(rawPhoneNumber);
          setPhoneNumber(formattedPhoneNumber);
          setVerifiedNumber(verify && rawPhoneNumber);
          setEnable3(!verify);
          setInput(response.data.aboutOne);
          if (response.data.aboutOne.inputList.length) {
            setInputList(response.data.aboutOne.inputList);
          } else {
            setInputList([
              {
                language: "",
                proficiency: "",
                read: false,
                write: false,
                speak: false,
              },
            ]);
          }
          if (response.data.aboutOne.userType.length) {
            setUserType(response.data.aboutOne.userType);
          } else {
            setUserType([]);
          }
          if (response.data.aboutOne.inputList2.length) {
            setInputList2(response.data.aboutOne.inputList2);
          } else {
            setInputList2([{ workProfile: "", urlLink: "" }]);
          }
          if (response.data.aboutOne.inputList3.length) {
            setInputList3(response.data.aboutOne.inputList3);
          } else {
            setInputList3([{ resume: "" }]);
          }
          setMiddleName(responseData.middleName);
          setParentInformation({
            ...parentInformation,
            fatherName: responseData.fatherName,
            occupationFather: responseData.occupationFather,
            annualIncomeFather: responseData.annualIncomeFather,
            emailFatherId: responseData.emailFatherId,
            motherName: responseData.motherName,
            occupationMother: responseData.occupationMother,
            annualIncomeMother: responseData.annualIncomeMother,
            emailMotherId: responseData.emailMotherId,
          });

          setGuardianInformation({
            ...guardianInformation,
            guardianName: responseData.guardianName,
            occupationGuardian: responseData.occupationGuardian,
            annualIncomeGuardian: responseData.annualIncomeGuardian,
            emailGuardianId: responseData.emailGuardianId,
            guardianRelation: responseData.guardianRelation,
          });
          setTextAreaCount(responseData.bio.length);
          setTextAreaCount2(responseData.about.length);
          setReligion(responseData.religion);
          setSelectedCountry(responseData.country);
          setSelectedState(responseData.state);
          setSelectedCity(responseData.city);
        }
      })
      .catch((err) => err.error);
  };
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = useRef(otp.map(() => React.createRef()));

  const handlePaste = (e, index) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 4); // Get the pasted content
    const updatedOtp = [...otp];

    // Insert the pasted content into the OTP input fields
    for (let i = 0; i < pastedData.length; i++) {
      if (index + i < otp.length) {
        updatedOtp[index + i] = pastedData[i];
      }
    }

    setOtp(updatedOtp);

    // Check if all OTP digits are entered
    const isAllDigitsEntered = updatedOtp.every((digit) => digit !== "");
    if (isAllDigitsEntered) {
      setValid(true);
    }

    // Focus on the next input field if available
    if (index + pastedData.length < otp.length) {
      inputRefs.current[index + pastedData.length].current.focus();
    }
  };

  const handleOtpChange = (event, index) => {
    const updatedOtp = [...otp];

    // Handle backspace
    if (event.target.value === "" && index > 0) {
      // Clear the current digit only when backspacing
      updatedOtp[index] = "";
      setOtp(updatedOtp);
      setError2(null);
      // Focus on the previous input field
      inputRefs.current[index - 1].current.focus();
      return;
    }

    // Update the current digit
    updatedOtp[index] = event.target.value.slice(0, 1);

    // Update the OTP state
    setOtp(updatedOtp);

    // Clear the error message when any input changes
    setErrors(null);
    setError2(null);
    setShowVerifyButton(false);

    // Check if all OTP digits are entered
    const isAllDigitsEntered = updatedOtp.every((digit) => digit !== "");
    if (isAllDigitsEntered) {
      setShowVerifyButton(true);
    }

    // Check if OTP length is equal to 4
    const fullOtp = updatedOtp.join("");
    if (fullOtp.length === 4) {
      setShowVerifyButton(true);
    } else {
      setShowVerifyButton(false);
    }

    // Focus on the next input field if available
    if (index < otp.length - 1 && event.target.value !== "") {
      inputRefs.current[index + 1].current.focus();
    }
  };
  useEffect(() => {
    prefill();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      )
      .then((response) => {
        if (response && response.data && response.data.aboutOne) {
          const responseData = response.data && response.data.aboutOne;
          setInput(responseData);
          setInput({
            ...input,
            userId: responseData.userId,
            about: responseData.about,
          });
          if (!input.bio) {
            setInput((prevInput) => ({
              ...prevInput,
              bio: responseData.bio,
            }));
          }
          setGuardianNumber(response.data.aboutOne.guardianPhoneNumber);
          const number = `+${responseData.fatherPhoneNumber}`;
          setFatherNumber(number);
          const numberMother = `+${responseData.motherPhoneNumber}`;
          setMotherNumber(numberMother);
          const dataPrimary = responseData.primaryAddress;
          const dataPermanent = responseData.permanentAddress;
          const hasGuardianInfo =
            response.data.aboutOne.guardianName &&
            response.data.aboutOne.occupationGuardian &&
            response.data.aboutOne.annualIncomeGuardian &&
            response.data.aboutOne.emailGuardianId &&
            response.data.aboutOne.guardianRelation &&
            response.data.aboutOne.guardianPhoneNumber;
          if (hasGuardianInfo) {
            setGuardian(true); // Automatically check the guardian checkbox
            setOpenParent(false); // Close parental information
            setOpenForm(true); // Open the form (if needed)
          } else {
            setGuardian(false);
            setOpenParent(true);
            setOpenForm(true);
          }
          setTrue(hasGuardianInfo);
          setPreffiledValue(responseData);
          setPrimaryAddress(dataPrimary);
          setPermanentAddress(dataPermanent);
          const dataCore = responseData.coreSkill;
          setCoreSkill(dataCore);
          const dataSkill = responseData.softSkill;
          setSoftSkill(dataSkill);

          setInput(response.data.aboutOne);
          setMiddleName(responseData.middleName);
          setParentInformation({
            ...parentInformation,
            fatherName: responseData.fatherName,
            occupationFather: responseData.occupationFather,
            annualIncomeFather: responseData.annualIncomeFather,
            emailFatherId: responseData.emailFatherId,
            motherName: responseData.motherName,
            occupationMother: responseData.occupationMother,
            annualIncomeMother: responseData.annualIncomeMother,
            emailMotherId: responseData.emailMotherId,
          });

          setGuardianInformation({
            ...guardianInformation,
            guardianName: responseData.guardianName,
            occupationGuardian: responseData.occupationGuardian,
            annualIncomeGuardian: responseData.annualIncomeGuardian,
            emailGuardianId: responseData.emailGuardianId,
            guardianRelation: responseData.guardianRelation,
          });
          setTextAreaCount(responseData.bio.length);
          setTextAreaCount2(responseData.about.length);
          setReligion(responseData.religion);
          setSelectedCountry(responseData.country);
          setSelectedState(responseData.state);
          setSelectedCity(responseData.city);

          if (responseData.inputList2 && responseData.inputList2.length > 0) {
            setInputList2(responseData.inputList2);
          }
        }
      })
      .catch((err) => err.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [countError, setCountError] = useState();
  const verifySubmit = async () => {
    submitAbout();
  };
  const submitAbout = async () => {
    const resumePayload = [];
    if (resumeList.length > 0) {
      await Promise.all(
        resumeList.map(async (resume) => {
          let resumeUrl;
          let formData = new FormData();
          formData.append("image", resume);

          try {
            const res = await axios.post(
              `${process.env.PUBLIC_URL}/xakal/files/`,
              formData
            );
            resumeUrl = res.data.url;
          } catch (error) {
            console.error("Error uploading resume:", error);
          }

          if (resumeUrl) {
            resumePayload.push({ resume: resumeUrl, fileName: resume.name });
          } else {
          }
        })
      );
    } else {
      resumePayload.push(
        ...prefilledValue.inputList3.filter(
          (e) => e.resume !== "" && e.resume !== undefined
        )
      );
    }
    const error = ["", undefined];
    let hasError = false;
    const errorMessages = {
      firstName: error.includes(input.firstName)
        ? "First name is mandatory"
        : "",
      lastName: error.includes(input.lastName) ? "Last name is mandatory" : "",
      gender: error.includes(input.gender) ? "Gender is mandatory!" : "",
      primaryCity: error.includes(primaryAddress?.city)
        ? "City / Town is mandatory"
        : "",
      primaryCountry: error.includes(primaryAddress?.country)
        ? "Country is mandatory"
        : "",
      primaryState: error.includes(primaryAddress?.state)
        ? "State / Province is mandatory"
        : "",
      primaryPincode: error.includes(primaryAddress?.pincode)
        ? "Zip / pincode is mandatory"
        : "",
      permanentCity: error.includes(permanentAddress?.city)
        ? "City / Town is mandatory"
        : "",
      permanentCountry: error.includes(permanentAddress?.country)
        ? "Country is mandatory"
        : "",
      permanentState: error.includes(permanentAddress?.state)
        ? "State / Province is mandatory"
        : "",
      permanentPincode: error.includes(permanentAddress?.pincode)
        ? "Zip / pincode is mandatory"
        : "",
      coreSkill: !coreSkill.length ? "Select at least one skill" : "",
    };

    hasError = Object.values(errorMessages).some((msg) => msg);
    const errorSections = {
      input: !!(
        errorMessages.firstName ||
        errorMessages.lastName ||
        errorMessages.gender
      ),
      primaryAddress: !!(
        errorMessages.primaryCity ||
        errorMessages.primaryCountry ||
        errorMessages.primaryState ||
        errorMessages.primaryPincode
      ),
    };

    // Count the sections with errors
    const errorCount = Object.values(errorSections).filter(Boolean).length;
    if (hasError) {
      setError3({
        firstName: errorMessages.firstName,
        lastName: errorMessages.lastName,
        gender: errorMessages.gender,
        alls: "Please fill out all the necessary fields in this section to save your details",
      });

      setPrimaryAddressError({
        city: errorMessages.primaryCity,
        country: errorMessages.primaryCountry,
        state: errorMessages.primaryState,
        pincode: errorMessages.primaryPincode,
        all: "Please fill out all the necessary fields in this section to save your details",
      });

      setPermamanetAddressError({
        city: errorMessages.permanentCity,
        country: errorMessages.permanentCountry,
        state: errorMessages.permanentState,
        pincode: errorMessages.permanentPincode,
      });
      setCountError(`[${errorCount}]Please provide the mandatory fields`);
    } else {
      setError3({
        firstName: " ",
        lastName: " ",
        gender: " ",
        bio: "",
      });
      setPrimaryAddressError({
        city: "",
        country: "",
        state: "",
        pincode: "",
      });
      setPermamanetAddressError({
        city: "",
        country: "",
        state: "",
        pincode: "",
      });

      if (file instanceof Blob) {
        const reader = new FileReader();

        reader.onload = async (event) => {
          // Create a FormData object with the base64-encoded image
          let formData = new FormData();
          formData.append("image", file);

          await axios
            .post(`${process.env.PUBLIC_URL}/xakal/files/`, formData)
            .then(async (res) => {
              if (res.data) {
                const params = {
                  firstName: input.firstName,
                  middleName: middleName,
                  lastName: input.lastName,
                  email: input.email,
                  avatar: uploadedImage ?? input.avatar, // Use the base64-encoded string for the avatar
                  bio: input.bio,
                  dob: input.dob,
                  title: input.title,
                  gender: input.gender,
                  userType: userType,
                  phoneNumber: verified ? phoneNumber : undefined,
                  about: input.about,

                  coreSkill: coreSkill,
                  softSkill: softSkill,
                  proficiencyInfo: proficiencyInfo,
                  inputList: inputList,
                  inputList2: inputList2,
                  inputList3: resumePayload ?? prefilledValue,
                  fatherName: parentInformation.fatherName,
                  occupationFather: parentInformation.occupationFather,
                  emailFatherId: parentInformation.emailFatherId,
                  annualIncomeFather: parentInformation.annualIncomeFather,
                  motherName: parentInformation.motherName,
                  occupationMother: parentInformation.occupationMother,
                  emailMotherId: parentInformation.emailMotherId,
                  annualIncomeMother: parentInformation.annualIncomeMother,
                  guardianName: guardianInformation.guardianName,
                  occupationGuardian: guardianInformation.occupationGuardian,
                  emailGuardianId: guardianInformation.emailGuardianId,
                  annualIncomeGuardian:
                    guardianInformation.annualIncomeGuardian,
                  guardianRelation: guardianInformation.guardianRelation,
                  fatherPhoneNumber: fatherNumber,
                  motherPhoneNumber: motherNumber,

                  guardianPhoneNumber: guardianNumber,
                  primaryAddress: primaryAddress,
                  permanentAddress: isSameAddress
                    ? primaryAddress
                    : permanentAddress,
                  religion: religion,
                };
                await axios
                  .put(
                    `${
                      process.env.PUBLIC_URL
                    }/xakal/update-about/${Common.loggedIn()}`,
                    params
                  )
                  .then((response) => {
                    // if (verified) {
                    if (response.data.success) {
                      setInputList([...inputList]);
                      setInputList2([...inputList2]);
                      setInputList3([...inputList3]);
                      setSelectedCountry("");
                      setSelectedState("");
                      setParentInformation({ ...parentInformation });
                      setGuardianInformation({ ...guardianInformation });
                      setPrimaryAddress({ ...primaryAddress });
                      setPermanentAddress({ ...permanentAddress });
                    }
                    onClose();
                    onSave();
                  })
                  .catch((error) => {
                    console.error("Error updating item:", error);
                  });
              }
            })
            .catch(() => {});
        };

        // Read the image file as a data URL
        reader.readAsDataURL(file);
      } else {
        const params = {
          firstName: input.firstName,
          middleName: middleName,
          lastName: input.lastName,
          email: input.email,
          avatar: uploadedImage ?? input.avatar,
          bio: input.bio,
          dob: input.dob,
          title: input.title,
          gender: input.gender,
          phoneNumber: verified ? phoneNumber : undefined,
          about: input.about,
          coreSkill: coreSkill,
          softSkill: softSkill,
          proficiencyInfo: proficiencyInfo,
          inputList: inputList,
          inputList2: inputList2,
          inputList3: resumePayload ?? prefilledValue,
          fatherName: parentInformation.fatherName,
          occupationFather: parentInformation.occupationFather,
          emailFatherId: parentInformation.emailFatherId,
          annualIncomeFather: parentInformation.annualIncomeFather,
          motherName: parentInformation.motherName,
          occupationMother: parentInformation.occupationMother,
          emailMotherId: parentInformation.emailMotherId,
          annualIncomeMother: parentInformation.annualIncomeMother,
          guardianName: guardianInformation.guardianName,
          occupationGuardian: guardianInformation.occupationGuardian,
          emailGuardianId: guardianInformation.emailGuardianId,
          annualIncomeGuardian: guardianInformation.annualIncomeGuardian,
          guardianRelation: guardianInformation.guardianRelation,
          fatherPhoneNumber: fatherNumber,
          motherPhoneNumber: motherNumber,
          primaryAddress: primaryAddress,
          permanentAddress: isSameAddress ? primaryAddress : permanentAddress,
          religion: religion,
          guardianPhoneNumber: guardianNumber,
          userType: userType,
        };

        // else if(!error3){
        axios
          .put(
            `${process.env.PUBLIC_URL}/xakal/update-about/${Common.loggedIn()}`,
            params
          )
          .then((response) => {
            // if (verified) {
            if (response.data.success) {
              setInput({
                ...input,
                avatar: "",
              });
              setInputList([...inputList]);
              setInputList2([...inputList2]);
              setInputList3([...inputList3]);
              setSelectedCountry("");
              setSelectedState("");
              setParentInformation({ ...parentInformation });
              setGuardianInformation({ ...guardianInformation });
              setPrimaryAddress({ ...primaryAddress });
              setPermanentAddress({ ...permanentAddress });
            }
            onClose();
            onSave();

          })
          .catch((error) => {
            console.error("Error updating item:", error);
          });
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const handleCheckboxChange = (e, index) => {
    const { name, checked } = e.target;
    const list = [...inputList];
    list[index][name] = checked;
    setInputList(list);
  };
  // eslint-disable-next-line no-unused-vars
  const [selectedCountry, setSelectedCountry] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [selectedState, setSelectedState] = useState("");

  const DiscardChanges = () => {
    setInput({
      ...input,
    });
    setInputList([...inputList]);
    setInputList2([...inputList2]);
    setInputList3([...inputList3]);
    setSelectedCountry("");
    setSelectedState("");
    onClose();
    setPrimaryAddress("");
    setError3("");
  };

  const CloseModalClear = () => {
    if (!dirty) {
      onClose();
    } else {
      setCloseModal(true);
      setError3("");
      setPrimaryAddress("");
    }
  };
  const [skillData, setSkillData] = useState([]);
  const [openResume, setOpenResume] = useState();
  const [resumeId, setResumeId] = useState();
  const [message2, setMessage2] = useState("");

  const resumeOpen = (id) => {
    setResumeId(id);
    setOpenResume(true);
  };
  const [deleteLoading, setDeleteLoading] = useState(false);
  const deleteResume = () => {
    setDeleteLoading(true);
    axios
      .delete(
        `${process.env.PUBLIC_URL}/xakal/about/${input._id}/resume/${resumeId}`
      )
      .then((response) => {
        setOpenResume(false);
        prefill();
        setDeleteLoading(false);
      })
      .catch((err) => {})
      .finally(() => {
        setDeleteLoading(false);
      });
  };
  const getSkill = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/skills`)
      .then((response) => {
        const companyValue = response.data.map((e) => ({
          skillIds: e.skillId,
          label: e.label,
          value: e.value,
          ratings: e.ratings,
          averageSkillRating: e.averageSkillRating,
        }));

        setSkillData(companyValue);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getSkill();
  }, []);

  const [skillcreate, setSkillCreate] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [rating, setRating] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [userId, setUserId] = useState("");
  useEffect(() => {
    setIsSameAddress(false);
  }, []);
  const createSkill = () => {
    const payload = {
      name: skillcreate,
    };
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/skill`, payload)
      .then((response) => {
        setSkillCreate("");
        setOptionsOneSkill([]);
        getSkill();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const parentRef = useRef();
  useEffect(() => {
    if (parentRef.current) {
      autoAnimate(parentRef.current);
    }
  }, [parentRef]);

  const handleGuardianCheckbox = () => {
    if (openGuardian) {
      setGuardian(false);
      setOpenParent(true);
      setOpenForm(true);
    } else {
      setGuardian(true);
      setOpenForm(true);
      setOpenParent(false);
    }
    setTrue((prevValue) => !prevValue);
  };

  const handlePrimaryAddressChange = (field, value) => {
    setPrimaryAddress({
      ...primaryAddress,
      [field]: value,
    });

    if (field === "pincode" && value.trim() !== "") {
      setPrimaryAddressError("");
    }
    if (field === "city" && value.trim() !== "") {
      setPrimaryAddressError("");
    }
    if (field === "country" && value.trim() !== "") {
      setPrimaryAddressError("");
    }
    if (field === "state" && value.trim() !== "") {
      setPrimaryAddressError("");
    }
  };

  const handlePermanentAddressChange = (field, value) => {
    // Update the permanent address only if the checkbox is unchecked
    if (!isSameAddress) {
      setPermanentAddress((prevAddress) => ({
        ...prevAddress,
        [field]: value,
      }));
    }
  };

  const handleCheckboxsChange = () => {
    setIsSameAddress(!isSameAddress);
    setPermamanetAddressError("");
  };

  useEffect(() => {
    if (isSameAddress) {
      setPermanentAddress({ ...primaryAddress });
    } else {
      setPermanentAddress({
        addressOne: "",
        addressTwo: "",
        city: "",
        country: "",
        state: "",
        pincode: "",
      });
    }
  }, [isSameAddress]);

  const handleCreatable = async (e) => {
    if (e[e?.length - 1]?.__isNew__) {
      await createSkill();
    }
    handleDropdown2(e);
  };
  const [softSkillData, setSoftSkillData] = useState([]);
  const getSoftSkill = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/soft-skills`)
      .then((response) => {
        const companyValue = response.data.map((e) => ({
          skillIds: e.skillId,
          label: e.label,
          value: e.value,
          ratings: e.ratings,
          averageSkillRating: e.averageSkillRating,
        }));

        setSoftSkillData(companyValue);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    getSoftSkill();
  }, []);
  const handleCreatableSoftSkill = async (e) => {
    if (e[e?.length - 1]?.__isNew__) {
      await createSoftSkill();
    }
    handleDropdown1(e);
  };
  const [softSkillCreate, setSoftSkillCreate] = useState("");
  const createSoftSkill = () => {
    const payload = {
      name: softSkillCreate,
      rating: rating,
      userId: [userId],
    };
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/softSkill`, payload)
      .then((response) => {
        setSoftSkillCreate("");
        setOptionsOne([]);
        getSoftSkill();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const [optionDataSkill, setOptionsOneSkill] = useState([]);

  useEffect(() => {
    if (skillcreate.length > 2) {
      setOptionsOneSkill(
        skillData.map((e) => ({
          skillIds: e.skillIds,
          label: e.label,
          value: e.value,
          ratings: e.ratings,
          averageSkillRating: e.averageSkillRating,
          candidateId: Common.loggedIn(),
        }))
      );
    } else {
      setOptionsOneSkill([]);
    }
  }, [skillData, skillcreate]);
  const [optionData, setOptionsOne] = useState([]);

  useEffect(() => {
    if (softSkillCreate.length > 2) {
      setOptionsOne(
        softSkillData.map((e) => ({
          skillIds: e.skillIds,
          label: e.label,
          value: e.value,
          ratings: e.ratings,
          averageSkillRating: e.averageSkillRating,
          candidateId: Common.loggedIn(),
        }))
      );
    } else {
      setOptionsOne([]);
    }
  }, [softSkillData, softSkillCreate]);

  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <></>
      </components.DropdownIndicator>
    );
  };
  const sendOtp = async (e) => {
    e.preventDefault();
    setLoadingOtp(true);
    setMinutes(1);
    setSeconds(0);
    setEnable(false);
    if (phoneNumber === "") {
      alert("Enter Your phoneNumber !");
    } else {
      setSpiner(true);
      setEnabled(true);
      const data = {
        phoneNumber: phoneNumber,
        userId: input?.userId,
      };

      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/send-mobile-otp`,
        data
      );
      if (response.status === 200) {
        setSpiner(false);
      } else {
        // toast.error(response.response.data.error);
      }
    }
  };
  const verifyOtp = async (e) => {
    e.preventDefault();

    if (otp.join("").length !== 4) {
      setError2("Invalid OTP");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/verify-mobile-otp`,
        {
          otp: otp.join(""),
          phoneNumber: phoneNumber,
          userId: input?.userId,
        }
      );

      if (response.data.success === true) {
        setVerify(true);
        setEnabled(false);
        setEnable3(false);
        setPhoneTouched({ phoneNumber: "" });
        // setMessage(response.data.message);
        toast.success(response.data.message);
        setVerifiedNumber(phoneNumber);
        prefillMobile();
        setShowVerifyButton(false);
      } else if (response.data.verified === true) {
        setEnable3(false);
        setEnable4(true);
        setMessage2("Mobile Number Verified");
      } else {
        // Handling incorrect OTP
        if (count > 1) {
          setCount(count - 1);
          if (count === 2) {
            setError2("Only 1 attempt left.");
          } else {
            setError2("Incorrect OTP");
          }
        } else if (count === 1) {
          // Handling maximum attempts reached
          setMinutes(1);
          setSeconds(0);
          setCount(5);
          setError2("Maximum attempts reached. Try again later.");
          setValid(false);
          setDisable(false);
          setTimeout(() => {
            setError2(null);
          }, 1000);
        }
      }
    } catch (error) {
      setErrors(error.response.data.error);
    }
  };

  const resendOtp = async (e) => {
    e.preventDefault();
    setDisable(true);
    setEnabled(true);
    setMinutes(1);
    setSeconds(0);
    setEnable(false);
    if (input.email === "") {
      alert("Enter Your Email !");
    } else if (!input.email.includes("@")) {
      alert("Enter Valid Email !");
    } else {
      // setSpiner(true);
      const data = {
        phoneNumber: phoneNumber,
        userId: input?.userId,
      };

      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/resend-mobile-otp`,
        data
      );
      if (response.status === 200) {
      } else {
      }
    }
  };
  const prefillMobile = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      )
      .then((response) => {
        if (response && response.data && response.data.aboutOne) {
          const responseData = response.data && response.data.aboutOne;

          const verifyFalse = responseData.mobileNumberVerified;
          setVerified(verifyFalse);
        }
      })
      .catch((err) => err.error);
  };
  const userTypeChange = (e, value) => {
    if (e.target?.checked) {
      setUserType([...userType, value]);
    } else {
      // remove from list
      setUserType(userType.filter((people) => people !== value));
    }
  };
  const userTypeChanges = (value) => {
    if (userType.includes(value)) {
      setUserType(userType.filter((type) => type !== value));
    } else {
      setUserType([...userType, value]);
    }
  };
  const [openOne, setOpenOne] = useState(false);
  const handleOpen = () => {
    setOpenOne(!openOne); // Toggle the state value
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <div className="">
        <Dialog
          open={closemodal}
          PaperProps={{
            style: {
              borderRadius: "16px",
              top: "10px",
              transform: "translateY(0%)",
              position: "absolute",
              backgroundColor: "#FFDAE0",
              padding: "5px 8px",
              height: "fit-content",
            },
          }}
          halfWidth
          maxWidth="md"
          // className="def"
        >
          <DialogContent
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontFamily: "Inter-Medium",
              position: "relative",
              left: "5px",
              // height: "56px",
              // top: "-10px",
            }}
          >
            <IconWrapper>
              <img
                src={DeleteImg}
                alt="img"
                style={{ width: "24px", height: "24px" }}
              />
            </IconWrapper>
            <JobTitle>
              Are you sure you want to discard the changes you made?
            </JobTitle>

            <DialogActions>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  gap: "10px",
                  marginLeft: "2rem",
                  marginRight: "10px",
                }}
              >
                <Button4
                  onClick={() => setCloseModal(false)}
                  style={{ cursor: "pointer" }}
                >
                  Go Back
                </Button4>
                <Button6 onClick={DiscardChanges}>Yes, Discard</Button6>
              </div>
            </DialogActions>
          </DialogContent>
        </Dialog>
        {/* <div className=""> */}
        <ToastContainer />

        <div className="modalHeader" style={{ padding: "10px 20px" }}>
          <h1 className="mTitle">About</h1>
          {/* <AiOutlineCloseCircle onClick={CloseModalClear} size={20} /> */}
          <img
            src={CloseIcon}
            className="mClose"
            alt="img"
            onClick={CloseModalClear}
            style={{ width: "30px", height: "30px" }}
          />
        </div>

        <div className="modalBody">
          <div
            className="Myprofile-topcontainer"
            style={{
              width: "100%",
              height: "150px",
              borderRadius: "16px",
              justifyContent: "center",
            }}
          >
            <div className="image-container">
              {uploadedImage !== null ||
              (input?.avatar && input?.avatar !== "") ? (
                <>
                  <img
                    src={uploadedImage ?? input?.avatar}
                    alt="BigCo Inc. logo"
                  />
                  <div>
                    <BiSolidPencil
                      size={18}
                      className="icon-plus"
                      onClick={onFile}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <img src={companyLogo} alt="yuo" />
                  <AiOutlinePlusCircle
                    size={18}
                    className="icon-plus"
                    onClick={onClick}
                    style={{ cursor: "pointer" }}
                  />
                </>
              )}
            </div>
          </div>
          <p className="textpass font-face-gm mt-3">
            Your Profile Type<span className="text-danger">*</span>
          </p>

          <div className="m-b-20 onboards-wrapper">
            <div
              className={`onboards-usertype mr-3 ${
                userType.includes("hr") ? "selected" : "unselect"
              }`}
              onClick={() => userTypeChanges("hr")}
            >
              <div className="onboards-input">
                <Checkbox
                  checked={userType.includes("hr")}
                  inputProps={{ "aria-label": "controlled" }}
                  onChange={(e) => userTypeChange(e, "hr")}
                  id="hr"
                  name="hr"
                />
              </div>
              <img src={Profile} alt="img" />

              <label for="hr">
                {" "}
                Are you a recruiter looking for candidates?{" "}
              </label>
            </div>

            <div
              className={`onboards-usertype mr-3 ${
                userType.includes("interviewer") ? "selected" : "unselect"
              }`}
              onClick={() => userTypeChanges("interviewer")}
            >
              <div className="onboards-input">
                <Checkbox
                  checked={userType.includes("interviewer")}
                  inputProps={{ "aria-label": "controlled" }}
                  onChange={(e) => userTypeChange(e, "interviewer")}
                  id="hr"
                  name="hr"
                />
              </div>
              <img src={Profile2} alt="img" />

              <label for="interviewer">
                {" "}
                Are you a SME looking to take interviews and earn?{" "}
              </label>
            </div>

            <div
              className={`onboards-usertype mr-3 ${
                userType.includes("candidate") ? "selected" : "unselect"
              }`}
              onClick={() => userTypeChanges("candidate")}
            >
              <div className="onboards-input">
                <Checkbox
                  checked={userType.includes("candidate")}
                  inputProps={{ "aria-label": "controlled" }}
                  onChange={(e) => userTypeChange(e, "candidate")}
                  id="hr"
                  name="hr"
                />
              </div>
              <img src={Profile3} alt="img" />

              <label for="candidate">
                {" "}
                Are you a candidate looking for jobs?
              </label>
            </div>
          </div>
          <div className="label-name About-head">Headline</div>
          <InputGroup className="mb-1">
            <Form.Control
              placeholder="Add your text"
              className="footer-header font"
              style={{
                width: "50%",
                fontSize: "12px",
                fontFamily: "Inter-Medium,sans-serif",
              }}
              aria-label="Bio / Headline"
              name="bio"
              value={input?.bio}
              onChange={handleModalBioChange}
              onBlur={validateInput}
              aria-describedby="basic-addon1"
              maxLength={250}
            />
            {/* <p style={{fontSize:"10px",color:"#C6CAD2",fontFamily:"Inter-Medium,sans-serif",padding:"20px 10px 10px 0"}}>Max 250 characters</p> */}
          </InputGroup>

          <div>
            <p className="font"> {` ${textAreaCount}/250`} </p>
          </div>

          <hr style={{ width: "100%" }} />

          <div className="">
            <div>
              <div className="accordion">
                <div className="accordion-item">
                  <Accordion activeKey={openOne ? "0" : null}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="AboutPopup-head"
                    >
                      Personal Information{" "}
                      {error3 && (
                        <span className="text-danger f12 margin">
                          {error3.alls}
                        </span>
                      )}
                    </AccordionSummary>

                    <AccordionDetails>
                      <div>
                        <div className="Edu-Popup-wrapper">
                          <div className="input-space">
                            <label className="label-name">Title</label>
                            <div>
                              <select
                                className="footer-header font"
                                isMulti={true}
                                name="title"
                                value={input?.title}
                                isClearable={false}
                                aria-label=".form-select-lg example"
                                onChange={handleModalChange}
                                style={{
                                  width: "100%",
                                  backgroundColor: "#F4F4F6",
                                  border: "none",
                                  paddingLeft: "10px",
                                  // color: "rgba(198, 202, 210)",
                                  fontweight: "normal",
                                }}
                              >
                                <option value="">Select your title</option>
                                <option value="Ms">Ms</option>
                                <option value="Mr">Mr</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Dr">Dr</option>
                              </select>
                            </div>
                          </div>

                          <div className="input-space">
                            <label className="label-name">
                              First Name<span>*</span>
                            </label>
                            <InputGroup className="footer-header mb-1">
                              <Form.Control
                                placeholder="Enter your first name"
                                className="footer-header mb-1 font"
                                aria-label="First name"
                                name="firstName"
                                value={input?.firstName}
                                aria-describedby="basic-addon1"
                                onChange={handleModalChange}
                                onBlur={validateInput}
                              />
                            </InputGroup>
                            {error3.firstName && (
                              <span className="f12">{error3.firstName}</span>
                            )}
                          </div>
                        </div>
                        <div className="Edu-Popup-wrapper">
                          <div className="input-space">
                            <label className="label-name">Middle Name</label>
                            <InputGroup className="footer-header mb-1">
                              <Form.Control
                                className="footer-header mb-1 font"
                                placeholder="Enter your middle name"
                                aria-label="Middle name"
                                name="middleName"
                                value={middleName}
                                aria-describedby="basic-addon1"
                                onChange={handleMiddle}
                                //onBlur={validateInput}
                              />
                            </InputGroup>
                          </div>
                          <div className="input-space">
                            <label className="label-name">
                              Last Name<span>*</span>
                            </label>
                            <InputGroup className="footer-header mb-1">
                              <Form.Control
                                className="footer-header mb-1 font"
                                placeholder="Enter your last name"
                                aria-label="Last Name"
                                aria-describedby="basic-addon1"
                                name="lastName"
                                value={input?.lastName}
                                onChange={handleModalChange}
                                onBlur={validateInput}
                              />
                            </InputGroup>
                            {error3.lastName && (
                              <span className="text-danger f12">
                                {error3.lastName}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="Edu-Popup-wrapper">
                          <div className="input-space">
                            <label className="label-name">
                              Gender<span>*</span>
                            </label>
                            {/* <InputGroup className="mb-3"> */}
                            <select
                              placeholder="Enter type here"
                              className="footer-header mb-1 font"
                              aria-label="Gender"
                              name="gender"
                              value={input?.gender}
                              aria-describedby="basic-addon1"
                              onChange={handleModalChange}
                              // style={{ color: "rgba(54, 42, 65, 1)", weight: "600px",}}
                              onBlur={validateInput}
                              style={{
                                width: "100%",
                                backgroundColor: "#F4F4F6",
                                border: "none",
                              }}
                            >
                              <option value="">Select your gender</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option>Others</option>
                            </select>
                            {/* </InputGroup> */}
                            {error3.gender && (
                              <span className="f12">{error3.gender}</span>
                            )}
                          </div>
                          <div className="input-space">
                            <label className="label-names">Date of Birth</label>

                            <InputGroup className="footer-header mb-1">
                              <Form.Control
                                type="date"
                                className="footer-header mb-1 font"
                                placeholder="DD--MM-YY"
                                aria-label="Date of birth"
                                name="dob"
                                value={input?.dob}
                                aria-describedby="basic-addon1"
                                onChange={handleModalChange}
                                onBlur={validateInput}
                                min={minDate}
                                max={new Date().toISOString().split("T")[0]}
                              />
                            </InputGroup>
                            {error3.dob && (
                              <span className="text-danger f12">
                                {error3.dob}
                              </span>
                            )}
                          </div>
                        </div>
                        <div class="mb-1 mt-3">
                          <label
                            for="exampleFormControlTextarea1"
                            className="label-name"
                          >
                            About yourself
                          </label>

                          <ReactQuill
                            theme="snow" // You can choose different themes like 'snow', 'bubble', etc.
                            value={input?.about}
                            onChange={handleJobDescChange}
                            ref={quillRef}
                            placeholder="Write something about yourselves, your career, experiences, etc..."
                            className="font"
                          />
                        </div>
                        <div className="font">
                          <p> {` ${textAreaCount2}/1000`} </p>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
            <div className="accordion">
              <div className="accordion-item">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="AboutPopup-head"
                  >
                    Mobile Number Verification
                  </AccordionSummary>
                  <AccordionDetails>
                    <div id="openotp">
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div className="Edu-Popup-wrapper">
                          <div>
                            <label className="label-name">Contact Number</label>
                            <InputGroup className="mb-1">
                              <PhoneInput
                                style={{
                                  width: "100%",
                                  backgroundColor: "#F4F4F6",
                                  border: "none",
                                  height: "48px",
                                  fontFamily: "Visby Cf,sans-serif",
                                  fontSize: "14px",
                                  color: "#4F4953",
                                  paddingTop: "4px",
                                }}
                                value={phoneNumber}
                                onChange={handlePhoneChange}
                                inputProps={{
                                  required: true,
                                }}
                                onBlur={() => setPhoneTouched(true)}
                                className="footer-header font"
                              />
                            </InputGroup>
                            {phoneNumberTouched.phoneNumber &&
                              phoneNumberTouched.phoneNumber && (
                                <span className="text-danger f12">
                                  {phoneNumberTouched.phoneNumber}
                                </span>
                              )}
                            {verifiedNumber === phoneNumber ? (
                              "Mobile No. Verified"
                            ) : enable3 ? (
                              <div style={{ marginBottom: "20px" }}>
                                <Get onClick={sendOtp}>
                                  {spinner ? <Spinner /> : "Get OTP"}
                                </Get>
                              </div>
                            ) : null}
                            {enabled ? (
                              <div>
                                <div className="">
                                  <div className="">
                                    <OtpHead className="otp mb-2">
                                      Enter the OTP sent to {phoneNumber}
                                    </OtpHead>
                                  </div>

                                  <div className="container-login100-form-btn">
                                    <div
                                      className="otp-group"
                                      style={{ marginLeft: "-90px" }}
                                    >
                                      {otp.map((digit, idx) => (
                                        <input
                                          key={idx}
                                          type="text"
                                          inputMode="numeric"
                                          autoComplete="one-time-code"
                                          pattern="\d"
                                          className="otp-inputs"
                                          value={digit}
                                          id={`otp-input-${idx}`}
                                          ref={inputRefs.current[idx]}
                                          onChange={(e) =>
                                            handleOtpChange(e, idx)
                                          }
                                          onPaste={(e) => handlePaste(e, idx)}
                                          disabled={!disable && seconds}
                                        />
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <p
                                  style={{
                                    color: "#FF5969",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    // position: "relative",
                                    // left: "130px",
                                    // marginTop: "-35px",
                                  }}
                                >
                                  {errors}
                                </p>

                                {error2 ? (
                                  <div style={{ display: "flex", gap: "30px" }}>
                                    <p
                                      style={{ color: "#FF5969" }}
                                      className="f12"
                                    >
                                      {error2}
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {seconds > 0 || minutes > 0 ? (
                                  <p
                                    style={{
                                      color: "#362A41",
                                      fontFamily: "Visby CF,sans-serif",
                                      fontWeight: "700",
                                      fontSize: "14px",
                                      textAlign: "left",
                                    }}
                                  >
                                    Time Remaining:{" "}
                                    {minutes < 10 ? `0${minutes}` : minutes}:
                                    {seconds < 10 ? `0${seconds}` : seconds}
                                  </p>
                                ) : (
                                  <p
                                    className="mt-2"
                                    style={{
                                      color: "#4F4953",
                                      fontFamily: "Visby CF,sans-serif",
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Didn't recieve the code yet?{" "}
                                    <span
                                      style={{
                                        color: "#8652B6",
                                        cursor: "pointer",
                                        fontFamily: "Visby CF,sans-serif",
                                        fontWeight: "700",
                                        fontSize: "14px",
                                      }}
                                      onClick={resendOtp}
                                    >
                                      Resend
                                    </span>
                                  </p>
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                            {showVerifyButton && (
                              <div className="container-login100-form-btn">
                                <button
                                  className="button3"
                                  onClick={verifyOtp}
                                  style={{
                                    opacity: showVerifyButton ? "100%" : "50%",
                                  }}
                                >
                                  VERIFY
                                  {verfifySpinner ? (
                                    <span>
                                      <Spinner />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion-item">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="AboutPopup-head"
                  >
                    Links
                  </AccordionSummary>
                  <AccordionDetails>
                    {inputList2 &&
                      inputList2.length > 0 &&
                      inputList2.map((input, i) => {
                        return (
                          <div>
                            <div className="Edu-Popup-wrapper">
                              <div className="input-space">
                                <label className="label-name">
                                  Work Profile or Social Profile Name
                                </label>
                                <InputGroup className="footer-header mb-3">
                                  <Form.Control
                                    placeholder="Enter your profile name"
                                    className="footer-header font"
                                    aria-label="Gender"
                                    name="workProfile"
                                    value={input.workProfile}
                                    aria-describedby="basic-addon1"
                                    // onChange={handleModalChange}

                                    onChange={(e) => handleInputChange2(e, i)}
                                    onBlur={validateInput}
                                    isMulti={true}
                                    defaultValue="select the value"
                                    isClearable={false}
                                  />
                                </InputGroup>
                                {inputList2.length - 1 === i && (
                                  <span className="f12">
                                    {error3.workProfile}
                                  </span>
                                )}
                              </div>
                              <div className="input-space">
                                <label className="label-name">URL</label>
                                <InputGroup className="footer-header mb-3">
                                  <Form.Control
                                    placeholder="Enter your url"
                                    className="footer-header font"
                                    aria-label="Gender"
                                    aria-describedby="basic-addon1"
                                    name="urlLink"
                                    value={input.urlLink}
                                    // onChange={handleModalChange}
                                    onChange={(e) => handleInputChange2s(e, i)}
                                    onBlur={validateInput}
                                    isMulti={true}
                                    defaultValue="select the value"
                                    isClearable={false}
                                  />
                                </InputGroup>
                                {inputList2.length - 1 === i && (
                                  <span className="f12">{error3.urlLink}</span>
                                )}

                                {inputList2.length !== 1 && (
                                  <button
                                    className=""
                                    onClick={() => handleRemoveClick2(i)}
                                  >
                                    <img
                                      src={Delete}
                                      alt="img"
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                        border: "1px solid grey",
                                        padding: "5px",
                                        margin: "0 20px 0 370px",
                                        borderRadius: "8px",
                                      }}
                                    />
                                  </button>
                                )}
                              </div>
                            </div>
                            {inputList2.length - 1 === i && (
                              <ButtonWrapper>
                                <button
                                  className=""
                                  style={{
                                    marginBottom: "20px",
                                    fontSize: "14px",
                                  }}
                                  onClick={handleAddClick2}
                                  disabled={
                                    input.workProfile === "" ||
                                    input.workProfile === undefined
                                  }
                                >
                                  <img src={Addcircle} alt="img" />
                                  Add Links
                                </button>
                              </ButtonWrapper>
                            )}
                          </div>
                        );
                      })}
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          

            <div className="accordion">
              <div className="accordion-item">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="AboutPopup-head"
                  >
                    Parental Information
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordion-content">
                      <>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            margin: "20px 0 20px 0",
                          }}
                        >
                          <div className="parent-check">
                            {" "}
                            <Checkbox
                              inputProps={{ "aria-label": "controlled" }}
                              checked={trueValue}
                              onClick={handleGuardianCheckbox}
                            />
                          </div>
                          <div>
                            <label
                              style={{
                                color: "#362A41",
                                fontweight: "normal",
                                fontSize: "14px",
                              }}
                            >
                              Guardian
                            </label>
                            <div
                              style={{
                                color: "#4F4953",
                                fontweight: "normal",
                                fontSize: "14px",
                              }}
                            >
                              Select to fill guardian information instead of
                              parental information
                            </div>
                          </div>
                        </div>
                        {openParent && (
                          <>
                            <div className="Edu-Popup-wrapper">
                              <div className="input-space">
                                <label className="label-name">
                                  Father's Name
                                </label>
                                <Form.Group className="footer-header mb-3">
                                  <Form.Control
                                    maxLength={50}
                                    pattern="[A-Za-z]"
                                    className="footer-header mb-3 font"
                                    placeholder="Enter your father's name"
                                    name="fatherName"
                                    value={parentInformation.fatherName}
                                    aria-describedby="basic-addon1"
                                    onChange={handleParentChange}
                                    // style={{
                                    //   color: "rgba(198, 202, 210)",
                                    //   fontweight: "normal",
                                    // }}
                                  />
                                </Form.Group>
                              </div>
                              <div className="input-space">
                                <label className="label-name">
                                  Occupation Name
                                </label>
                                <InputGroup className="footer-header mb-3">
                                  <Form.Control
                                    maxLength={50}
                                    className="footer-header mb-3 font"
                                    placeholder="Enter your father's occupation"
                                    aria-describedby="basic-addon1"
                                    name="occupationFather"
                                    value={parentInformation.occupationFather}
                                    onChange={handleParentChange}
                                    style={{
                                      color: "rgba(198, 202, 210)",
                                      fontweight: "normal",
                                    }}
                                  />
                                </InputGroup>
                              </div>
                            </div>
                            <div className="Edu-Popup-wrapper">
                              <div className="input-space">
                                <label className="label-name">
                                  Annual Income
                                </label>
                                <InputGroup className="footer-header mb-3">
                                  <Form.Control
                                    maxLength={10}
                                    className="footer-header mb-3 font"
                                    placeholder="Enter your father's annual income"
                                    name="annualIncomeFather"
                                    value={parentInformation.annualIncomeFather}
                                    aria-describedby="basic-addon1"
                                    onChange={handleParentChange}
                                    style={{
                                      color: "rgba(198, 202, 210)",
                                      fontweight: "normal",
                                    }}
                                  />
                                </InputGroup>
                              </div>
                              <div className="input-space">
                                <label className="label-name">Email Id</label>
                                <InputGroup className="footer-header mb-3">
                                  <Form.Control
                                    className="footer-header mb-3 font"
                                    placeholder="Enter your father's mail id"
                                    aria-describedby="basic-addon1"
                                    name="emailFatherId"
                                    value={parentInformation.emailFatherId}
                                    onChange={handleParentChange}
                                    style={{
                                      color: "rgba(198, 202, 210)",
                                      fontweight: "normal",
                                    }}
                                  />
                                </InputGroup>
                                <p className="f12">{errorMessage}</p>
                              </div>
                            </div>
                            <div className="Edu-Popup-wrapper">
                              <div className="input-space">
                                <label className="label-name">
                                  Contact Number
                                </label>
                                <InputGroup className="footer-header mb-3">
                                  <PhoneInput
                                    style={{
                                      // width: "100%",
                                      backgroundColor: "#F4F4F6",
                                      border: "none",
                                      height: "48px",
                                      width: "48px",
                                      paddingTop: "7px",
                                      color: "rgba(198, 202, 210)",
                                      fontweight: "normal",
                                    }}
                                    country={"us"}
                                    value={fatherNumber}
                                    onChange={handleFatherPhoneChange}
                                    inputProps={{
                                      required: true,
                                    }}
                                    className="footer-header"
                                  />
                                </InputGroup>
                              </div>
                            </div>
                            <div className="Edu-Popup-wrapper">
                              <div className="input-space">
                                <label className="label-name">
                                  Mother's Name
                                </label>
                                <InputGroup className="footer-header mb-3">
                                  <Form.Control
                                    className="footer-header mb-3 font"
                                    placeholder="Enter your mother's name"
                                    name="motherName"
                                    value={parentInformation.motherName}
                                    aria-describedby="basic-addon1"
                                    style={{
                                      color: "rgba(198, 202, 210)",
                                      fontweight: "normal",
                                    }}
                                    onChange={handleParentChange}
                                  />
                                </InputGroup>
                              </div>
                              <div className="input-space">
                                <label className="label-name">Occupation</label>
                                <InputGroup className="footer-header mb-3">
                                  <Form.Control
                                    className="footer-header mb-3 font"
                                    placeholder="Enter your mother's occupation"
                                    aria-describedby="basic-addon1"
                                    name="occupationMother"
                                    value={parentInformation.occupationMother}
                                    style={{
                                      color: "rgba(198, 202, 210)",
                                      fontweight: "normal",
                                    }}
                                    onChange={handleParentChange}
                                  />
                                </InputGroup>
                              </div>
                            </div>
                            <div className="Edu-Popup-wrapper">
                              <div className="input-space">
                                <label className="label-name">
                                  Annual Income
                                </label>
                                <InputGroup className="footer-header mb-3">
                                  <Form.Control
                                    className="footer-header mb-3 font"
                                    placeholder="Enter your mother's annual income"
                                    name="annualIncomeMother"
                                    maxLength={10}
                                    value={parentInformation.annualIncomeMother}
                                    aria-describedby="basic-addon1"
                                    style={{
                                      color: "rgba(198, 202, 210)",
                                      fontweight: "normal",
                                    }}
                                    onChange={handleParentChange}
                                  />
                                </InputGroup>
                              </div>
                              <div className="input-space">
                                <label className="label-name">Email Id</label>
                                <InputGroup className="footer-header mb-3">
                                  <Form.Control
                                    className="footer-header mb-3 font"
                                    placeholder="Enter your mother's email"
                                    aria-describedby="basic-addon1"
                                    name="emailMotherId"
                                    value={parentInformation.emailMotherId}
                                    style={{
                                      color: "rgba(198, 202, 210)",
                                      fontweight: "normal",
                                    }}
                                    onChange={handleParentChange}
                                  />
                                </InputGroup>
                                <p className="f12">{errorMessageOne}</p>
                              </div>
                            </div>
                            <div className="Edu-Popup-wrapper">
                              <div className="input-space">
                                <label className="label-name">
                                  Contact Number
                                </label>
                                <InputGroup className="footer-header mb-3">
                                  <PhoneInput
                                    style={{
                                      width: "100%",
                                      backgroundColor: "#F4F4F6",
                                      border: "none",
                                      height: "48px",
                                      paddingTop: "7px",
                                    }}
                                    country={"us"}
                                    value={motherNumber}
                                    onChange={handleMotherPhoneChange}
                                    inputProps={{
                                      required: true,
                                    }}
                                    className="footer-header"
                                  />
                                </InputGroup>
                              </div>
                            </div>
                          </>
                        )}
                      </>

                      <div>
                        {openGuardian && (
                          <>
                            <div className="Edu-Popup-wrapper">
                              <div className="input-space">
                                <label className="label-name">
                                  Guardian's Name
                                </label>
                                <InputGroup className="footer-header mb-3">
                                  <Form.Control
                                    className="footer-header mb-3 font"
                                    maxLength={50}
                                    placeholder="Enter your guardian's name"
                                    name="guardianName"
                                    value={guardianInformation.guardianName}
                                    aria-describedby="basic-addon1"
                                    style={{
                                      color: "rgba(198, 202, 210)",
                                      fontweight: "normal",
                                    }}
                                    onChange={handleGuardianChange}
                                  />
                                </InputGroup>
                              </div>
                              <div className="input-space">
                                <label className="label-name">Occupation</label>
                                <InputGroup className="footer-header mb-3">
                                  <Form.Control
                                    maxLength={50}
                                    className="footer-header mb-3 font"
                                    placeholder="Enter your guardian's occupation"
                                    aria-describedby="basic-addon1"
                                    name="occupationGuardian"
                                    value={
                                      guardianInformation.occupationGuardian
                                    }
                                    style={{
                                      color: "rgba(198, 202, 210)",
                                      fontweight: "normal",
                                    }}
                                    onChange={handleGuardianChange}
                                  />
                                </InputGroup>
                              </div>
                            </div>
                            <div className="Edu-Popup-wrapper">
                              <div className="input-space">
                                <label className="label-name">
                                  Annaul Income
                                </label>
                                <InputGroup className="footer-header mb-3 font">
                                  <Form.Control
                                    maxLength={10}
                                    className="footer-header mb-3 font"
                                    placeholder="Enter your guardian's annual income"
                                    name="annualIncomeGuardian"
                                    value={
                                      guardianInformation.annualIncomeGuardian
                                    }
                                    aria-describedby="basic-addon1"
                                    style={{
                                      color: "rgba(198, 202, 210)",
                                      fontweight: "normal",
                                    }}
                                    onChange={handleGuardianChange}
                                  />
                                </InputGroup>
                              </div>
                              <div className="input-space">
                                <label className="label-name">Email Id</label>
                                <InputGroup className="footer-header mb-3">
                                  <Form.Control
                                    className="footer-header mb-3 font"
                                    placeholder="Enter your guardian's email id"
                                    aria-describedby="basic-addon1"
                                    name="emailGuardianId"
                                    value={guardianInformation.emailGuardianId}
                                    style={{
                                      color: "rgba(198, 202, 210)",
                                      fontweight: "normal",
                                    }}
                                    onChange={handleGuardianChange}
                                  />
                                </InputGroup>
                                <p className="f12ger">
                                  {errorMessageOneGuardian}
                                </p>
                              </div>
                            </div>
                            <div className="Edu-Popup-wrapper">
                              <div className="input-space">
                                <label className="label-name">
                                  Contact Number
                                </label>
                                <InputGroup className="footer-header mb-3">
                                  <PhoneInput
                                    style={{
                                      width: "100%",
                                      backgroundColor: "#F4F4F6",
                                      border: "none",
                                      height: "48px",
                                      fontSize: "12px",
                                      paddingTop: "7px",
                                    }}
                                    country={"us"}
                                    value={guardianNumber}
                                    onChange={handleGuardianPhoneChange}
                                    inputProps={{
                                      required: true,
                                    }}
                                    className="footer-header"
                                  />
                                </InputGroup>
                              </div>
                              <div className="input-space">
                                <label className="label-name">
                                  Relationship
                                </label>
                                <InputGroup className="footer-header mb-3">
                                  <Form.Control
                                    className="footer-header mb-3 font"
                                    placeholder="Enter your guardian's relationship"
                                    aria-describedby="basic-addon1"
                                    name="guardianRelation"
                                    value={guardianInformation.guardianRelation}
                                    style={{
                                      color: "rgba(198, 202, 210)",
                                      fontweight: "normal",
                                    }}
                                    onChange={handleGuardianChange}
                                  />
                                </InputGroup>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <div className="accordion">
              <div className="accordion-item">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    className="AboutPopup-head"
                  >
                    Address{" "}
                    {primaryAddressError && (
                      <span className="text-danger f12 margin">
                        {primaryAddressError.all}
                      </span>
                    )}
                  </AccordionSummary>

                  <AccordionDetails>
                    <div>
                      <div className="Edu-Popup-wrapper"></div>
                      <div>
                        <label
                          className="label-name"
                          style={{ marginTop: "-20px" }}
                        >
                          Present Address
                        </label>
                      </div>
                      <div
                        className="Edu-Popup-wrapper"
                        style={{ marginTop: "-5px" }}
                      >
                        <div className="input-space">
                          <label className="label-name">Address line1</label>
                          <Form.Group>
                            <Form.Control
                              className="footer-header font"
                              placeholder="Enter your address"
                              name="addressOne"
                              maxLength={100}
                              value={primaryAddress?.addressOne}
                              onChange={(e) =>
                                handlePrimaryAddressChange(
                                  "addressOne",
                                  e.target.value
                                )
                              }
                            />
                          </Form.Group>
                        </div>
                        <div className="input-space">
                          <Form.Group>
                            <label className="label-name">Address line2</label>

                            <Form.Control
                              maxLength={100}
                              placeholder="Enter your address"
                              value={primaryAddress?.addressTwo}
                              name="addressTwo"
                              className="footer-header font"
                              onChange={(e) =>
                                handlePrimaryAddressChange(
                                  "addressTwo",
                                  e.target.value
                                )
                              }
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <Row style={{ marginTop: "20px" }}>
                        <Col sm>
                          <Form.Group>
                            <Label>
                              Country<span className="f12ger">*</span>
                            </Label>

                            <div>
                              <select
                                required
                                value={primaryAddress?.country}
                                onChange={(e) =>
                                  handlePrimaryAddressChange(
                                    "country",
                                    e.target.value
                                  )
                                }
                                name="country"
                                className="footer-header mb-1 font"
                                placeholder="Select country"
                                style={{
                                  width: "100%",
                                  backgroundColor: "#F4F4F6",
                                  border: "none",
                                }}
                              >
                                <option value="">Country</option>
                                {Country &&
                                  Country.getAllCountries().map((item) => (
                                    <option
                                      key={item.name}
                                      value={item.isoCode}
                                    >
                                      {item.name}
                                    </option>
                                  ))}
                              </select>
                              {primaryAddressError.country && (
                                <span className="text-danger f12">
                                  {primaryAddressError.country}
                                </span>
                              )}
                            </div>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Label>
                              State / Province
                              <span className="f12ger">*</span>
                            </Label>

                            <select
                              required
                              value={primaryAddress?.state}
                              onChange={(e) =>
                                handlePrimaryAddressChange(
                                  "state",
                                  e.target.value
                                )
                              }
                              name="state"
                              placeholder="Select your state / province"
                              className="footer-header mb-1 font"
                              // style={{ width: "330px", height: "40px" }}
                              onBlur={validateInput}
                              style={{
                                width: "100%",
                                backgroundColor: "#F4F4F6",
                                border: "none",
                              }}
                            >
                              <option value="">State</option>
                              {State &&
                                primaryAddress?.country &&
                                State.getStatesOfCountry(
                                  primaryAddress?.country
                                ).map((item) => (
                                  <option key={item.name} value={item.isoCode}>
                                    {item.name}
                                  </option>
                                ))}
                            </select>
                            {primaryAddressError.state && (
                              <span className="text-danger f12">
                                {primaryAddressError.state}
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "20px" }}>
                        <Col sm>
                          <Form.Group>
                            <Label>
                              City / Town<span className="f12ger">*</span>
                            </Label>
                            <select
                              required
                              value={primaryAddress?.city}
                              placeholder="Select city / town"
                              onChange={(e) =>
                                handlePrimaryAddressChange(
                                  "city",
                                  e.target.value
                                )
                              }
                              name="city"
                              className="footer-header font"
                              style={{
                                width: "100%",
                                backgroundColor: "#F4F4F6",
                                border: "none",
                              }}
                            >
                              <option value="">City</option>
                              {City &&
                                primaryAddress?.country &&
                                primaryAddress?.state &&
                                City.getCitiesOfState(
                                  primaryAddress?.country,
                                  primaryAddress?.state
                                ).map((item) => (
                                  <option value={item.name}>{item.name}</option>
                                ))}
                            </select>
                            {primaryAddressError.city && (
                              <span className="text-danger f12">
                                {primaryAddressError.city}
                              </span>
                            )}
                          </Form.Group>
                        </Col>

                        <Col>
                          <Form.Group>
                            <Label>
                              Zip / Pincode
                              <span className="f12ger">*</span>
                            </Label>
                            <Form.Control
                              placeholder="Enter your pincode"
                              aria-label="Job Title"
                              maxLength={10}
                              value={primaryAddress?.pincode}
                              aria-describedby="basic-addon1"
                              style={{
                                borderRadius: "5px",
                                background: "rgba(54, 42, 65, 0.04)",
                                height: "48px",
                              }}
                              className="font footer-header"
                              name="pincode"
                              onChange={(e) =>
                                handlePrimaryAddressChange(
                                  "pincode",
                                  e.target.value
                                )
                              }
                            />
                            {primaryAddressError.pincode && (
                              <span className="text-danger f12">
                                {primaryAddressError.pincode}
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm>
                          <div
                            style={{
                              position: "relative",
                              marginTop: "25px",
                            }}
                          >
                            <label className="label-name">
                              Permanent Address
                            </label>
                          </div>
                        </Col>
                        <Col>
                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              gap: "10px",
                            }}
                          >
                            <Checkbox
                              inputProps={{ "aria-label": "controlled" }}
                              onChange={handleCheckboxsChange}
                              checked={isSameAddress}
                            />

                            <p
                              style={{
                                color: "#4F4953",
                                fontFamily: "Inter-Medium,sans-serif",
                                fontweight: "normal",
                                fontSize: "14px",
                                marginTop: "15px",
                              }}
                            >
                              Keep present address as your permanent address
                            </p>
                          </div>
                        </Col>
                      </Row>

                      <div
                        className="Edu-Popup-wrapper"
                        style={{ marginTop: "-10px" }}
                      >
                        <div className="input-space">
                          <label className="label-name">Address line1</label>
                          <Form.Group className="footer-header mb-1">
                            <Form.Control
                              className="footer-header mb-3 font"
                              placeholder="Enter your address"
                              maxLength={100}
                              value={
                                isSameAddress
                                  ? primaryAddress?.addressOne
                                  : permanentAddress?.addressOne
                              }
                              name="addressOne"
                              style={{
                                color: "rgba(198, 202, 210)",
                                fontweight: "normal",
                              }}
                              onChange={(e) =>
                                handlePermanentAddressChange(
                                  "addressOne",
                                  e.target.value
                                )
                              }
                              disabled={isSameAddress}
                            />
                          </Form.Group>
                        </div>
                        <div className="input-space">
                          <label className="label-name">Address line2</label>
                          <Form.Group className="footer-header mb-1">
                            <Form.Control
                              className="footer-header mb-3 font"
                              placeholder="Enter your address"
                              value={
                                isSameAddress
                                  ? primaryAddress?.addressTwo
                                  : permanentAddress?.addressTwo
                              }
                              aria-describedby="basic-addon1"
                              name="addressTwo"
                              maxLength={100}
                              style={{
                                color: "rgba(198, 202, 210)",
                                fontweight: "normal",
                              }}
                              onChange={(e) =>
                                handlePermanentAddressChange(
                                  "addressTwo",
                                  e.target.value
                                )
                              }
                              disabled={isSameAddress}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <Row>
                        <Col sm>
                          <Form.Group>
                            <Label>
                              Country<span className="f12ger">*</span>
                            </Label>

                            <div>
                              <select
                                required
                                value={
                                  isSameAddress
                                    ? primaryAddress?.country
                                    : permanentAddress?.country
                                }
                                name="country"
                                className="footer-header mb-1 font"
                                style={{
                                  height: "48px",
                                  border: "none",
                                }}
                                onChange={(e) =>
                                  handlePermanentAddressChange(
                                    "country",
                                    e.target.value
                                  )
                                }
                                disabled={isSameAddress}
                              >
                                <option value="">Select country</option>
                                {Country &&
                                  Country.getAllCountries().map((item) => (
                                    <option
                                      key={item.name}
                                      value={item.isoCode}
                                    >
                                      {item.name}
                                    </option>
                                  ))}
                              </select>
                              {permananetAddressError?.country && (
                                <span className="text-danger f12">
                                  {permananetAddressError?.country}
                                </span>
                              )}
                            </div>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Label>
                              State / Province
                              <span className="f12ger">*</span>
                            </Label>

                            <select
                              required
                              value={
                                isSameAddress
                                  ? primaryAddress?.state
                                  : permanentAddress?.state
                              }
                              onChange={(e) =>
                                handlePermanentAddressChange(
                                  "state",
                                  e.target.value
                                )
                              }
                              name="state"
                              className="footer-header mb-1 font"
                              style={{ height: "48px", border: "none" }}
                              disabled={isSameAddress}
                            >
                              <option value="">
                                Select your state / province
                              </option>
                              {State &&
                                (isSameAddress
                                  ? State.getStatesOfCountry(
                                      primaryAddress?.country
                                    )
                                  : State.getStatesOfCountry(
                                      permanentAddress?.country
                                    )
                                ).map((item) => (
                                  <option key={item.name} value={item.isoCode}>
                                    {item.name}
                                  </option>
                                ))}
                            </select>
                            {permananetAddressError?.state && (
                              <span className="text-danger f12">
                                {permananetAddressError?.state}
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "10px" }}>
                        <Col sm>
                          <Form.Group>
                            <Label>
                              City / Town<span className="f12ger">*</span>
                            </Label>

                            <select
                              components={{ Placeholder, DropdownIndicator }}
                              required
                              value={
                                isSameAddress
                                  ? primaryAddress?.city
                                  : permanentAddress?.city
                              }
                              onChange={(e) =>
                                handlePermanentAddressChange(
                                  "city",
                                  e.target.value
                                )
                              }
                              name="city"
                              className="footer-header mb-1 font"
                              style={{ height: "48px", border: "none" }}
                              disabled={isSameAddress}
                            >
                              <option value="">Select city / town</option>
                              {City &&
                                (isSameAddress
                                  ? City.getCitiesOfState(
                                      primaryAddress?.country,
                                      primaryAddress?.state
                                    )
                                  : City.getCitiesOfState(
                                      permanentAddress?.country,
                                      permanentAddress?.state
                                    )
                                ).map((item) => (
                                  <option value={item.name} key={item.name}>
                                    {item.name}
                                  </option>
                                ))}
                            </select>
                            {permananetAddressError?.city && (
                              <span className="text-danger f12">
                                {permananetAddressError?.city}
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Label>
                              Zip / Pincode
                              <span className="f12ger">*</span>
                            </Label>
                            <Form.Control
                              placeholder="Enter your pincode"
                              className="footer-header font"
                              aria-label="Job Title"
                              name="pincode"
                              maxLength={10}
                              value={
                                isSameAddress
                                  ? primaryAddress?.pincode
                                  : permanentAddress?.pincode
                              }
                              aria-describedby="basic-addon1"
                              style={{
                                borderRadius: "5px",
                                background: "rgba(54, 42, 65, 0.04)",
                                height: "48px",
                              }}
                              onChange={(e) =>
                                handlePermanentAddressChange(
                                  "pincode",
                                  e.target.value
                                )
                              }
                              disabled={isSameAddress}
                            />
                            {permananetAddressError?.pincode && (
                              <span className="text-danger f12">
                                {permananetAddressError?.pincode}
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        <div className="modalFooter">
          <button className="text-danger f12" onClick={() => handleOpen()}>
            {countError}
          </button>
          <button className="button5" onClick={CloseModalClear}>
            Cancel
          </button>
          <button className="button3" onClick={verifySubmit}>
            Save
          </button>
        </div>
      </div>
      <Dialog
        open={openResume}
        PaperProps={{
          style: {
            borderRadius: "16px",
            top: "10px",
            transform: "translateY(0%)",
            position: "absolute",
            backgroundColor: "#FFDAE0",
            padding: "5px 8px",
            height: "fit-content",
          },
        }}
        halfWidth
        maxWidth="md"
      >
        <DialogContent
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
            position: "relative",
            left: "5px",
          }}
        >
          <IconWrapper>
            <img
              src={DeleteImg}
              alt="img"
              style={{ width: "24px", height: "24px" }}
            />
          </IconWrapper>

          <JobTitle>Are you sure you want to delete this resume ?</JobTitle>
          <DialogActions>
            <div
              style={{
                position: "relative",
                display: "flex",
                gap: "10px",
                marginLeft: "2rem",
                marginRight: "10px",
              }}
            >
              <Button4 onClick={() => setOpenResume(false)}>No</Button4>
              <Button6 onClick={deleteResume}>
                {deleteLoading ? <Spinner /> : "Yes, Delete"}
              </Button6>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AboutModal;
