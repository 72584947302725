import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DesktopHeader from "../desktopHeader";
import axios from "axios";
import { exportAsExcel, exportAsCSV } from "./exportFile";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDateRangePicker } from "@mui/x-date-pickers-pro/DesktopDateRangePicker";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import Common from "../../utils/common";
import { DataGrid } from "@mui/x-data-grid";
import Filter from "../../images/Filters.svg";
import Download from "../../images/Download Minimalistic.svg";
import Excel from "../../images/mdi_microsoft-excel.svg";
import CSV from "../../images/CSV.svg";
import BankTransactionPdf from "./bankTransactionPage";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const theme = createTheme({
  components: {
    MuiDesktopDateRangePicker: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeLarge": {
            display: "none", // Hide the watermark icon
          },
          "& .MuiTypography-root.MuiTypography-body1": {
            display: "none", // Hide the "MUI X Missing license key" text
          },
        },
      },
    },
  },
});

const ExportButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 200px;
  right: 50px;
  background: #fff;
  padding: 5px;
  border-radius: 8px;
  z-index: 3;
  img {
    margin-right: 5px;
  }
`;

const ExportButton = styled.button`
  border: 1px solid #e3e2e6;
  background-color: #fff;
  color: #362a41;
  font-size: 16px;
  height: 35px;
  padding: 8px 16px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 8px;
  margin-bottom: 10px;
  &:hover {
    background-color: #fff;
    color: #362a41;
    box-shadow: 0 2px 2px #362a41;
  }
  img {
    margin-right: 5px;
    width: 16px;
    height: 16px;
  }
  &.active {
    background-color: blue;
  }

  &.disabled {
    background-color: grey;
    cursor: default;
  }
`;
const ExportsButton = styled.button`
  background-color: transperent;
  color: #362a41;
  font-size: 14px;
  font-weight: 700;
  height: 35px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 8px;
  padding: 5px 10px;
  &:hover {
    background-color: #f4f4f6;
    color: #362a41;
    box-shadow: 0 2px 2px #362a41;
  }
  img {
    margin-right: 5px;
    width: 16px;
    height: 16px;
  }
`;
const ResetButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const DateBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2rem 3rem 2rem 5rem;
`;
const StatusOne = styled.div`
  position: relative;
  display: flex;
  gap: 40px;
  justify-content: space-between;
  img {
    width: 29px;
    height: 25px;
    cursor: pointer;
  }
`;
const HeaderDownLayout = styled.div``;
const BankTransaction = () => {
  const [bank, setBank] = useState([]);
  const [book, setBook] = useState();

  // eslint-disable-next-line no-unused-vars
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const itemsPerPage = 10;

  const getAllTransaction = async () => {
    try {
      const res = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-all-transacation`
      );
      setBank(res.data.finall);
    } catch (err) {}
  };

  const formatDates = (dateStr) => {
    if (!dateStr) return "";

    const [dayStr, monthStr, yearStr] = dateStr.split("/");

    const day = parseInt(dayStr);
    const year = parseInt(yearStr);

    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }

    const monthsMap = {
      1: "Jan",
      2: "Feb",
      3: "Mar",
      4: "Apr",
      5: "May",
      6: "Jun",
      7: "Jul",
      8: "Aug",
      9: "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };

    const monthAbbreviation = monthsMap[parseInt(monthStr)];

    const formattedDate = `${day}${suffix} ${monthAbbreviation} ${year}`;

    return formattedDate;
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };
  const fromattedDate = (date) => {
    const currentDate = new Date(date);
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // Month is zero-based, so we add 1
    const year = currentDate.getFullYear().toString().slice(-2); // Get last two digits of the year

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  const filteredItems = bank.filter((item) => {
    const itemCreatedAt = item.timestamps?.createdAt?.split(",")[0];
    if (fromDate !== null && toDate !== null) {
      const fromDateFormatted = fromattedDate(fromDate);
      const toDateFormatted = fromattedDate(toDate);

      return (
        itemCreatedAt &&
        itemCreatedAt >= fromDateFormatted &&
        itemCreatedAt <= toDateFormatted
      );
    } else if (fromDate !== null) {
      const fromDateFormatted = fromattedDate(fromDate);
      return itemCreatedAt && itemCreatedAt >= fromDateFormatted;
    } else if (toDate !== null) {
      const toDateFormatted = fromattedDate(toDate);
      return itemCreatedAt && itemCreatedAt <= toDateFormatted;
    } else {
      return true;
    }
  });

  const resetAllFilters = () => {
    setFromDate(null);
    setToDate(null);
  };

  const handleExport = (format) => {
    const dataToExport = filteredItems.map((item) => ({
      "Transaction Name": `Interview Booking (${item.serviceName})`,
      Date: formatDates(item.timestamps?.createdAt),
      "Interview ID": item.interviewCodeId,
      "Booking ID": item.slotBookingId,
      "Transaction ID": item.transactionCodeId,
      Price: item.totalAmount ? item.totalAmount : "-",
      Status: item.paid === "paid" ? "Completed" : "Failed",
    }));
    if (format === "xlsx") {
      exportAsExcel(dataToExport, "Bank_Transactions.xlsx");
    } else if (format === "csv") {
      exportAsCSV(dataToExport, "Bank_Transactions.csv");
    }
  };
  // eslint-disable-next-line no-unused-vars
  const [accountDetail, setAccountDetail] = useState();
  const getOneAccount = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/account-get/${Common.loggedIn()}`)
      .then((response) => {
        setAccountDetail(response.data.account[0]);
      })
      .catch((err) => {});
  };

  const [download, setDownload] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const openDownload = () => {
    setDownload(!download);
  };
  const dateOpen = () => {
    setOpenDate(!openDate);
  };
  useEffect(() => {
    getAllTransaction();
    getOneAccount();
  }, []);
  const columns = [
    {
      field: "serviceName",
      headerName: "Transaction Name",
      width: 300,
      renderCell: (params) => <div>Interview Service ({params.value})</div>,
    },
    {
      field: "timestamps",
      headerName: "Date",
      width: 120,
      renderCell: (params) => <div>{formatDates(params.value?.createdAt)}</div>,
    },
    {
      field: "interviewCodeId",
      headerName: "Interview Id",
      width: 120,
    },
    { field: "slotBookingId", headerName: "Booking Id", width: 120 },
    { field: "transactionCodeId", headerName: "Transaction Id", width: 150 },
    { field: "totalAmount", headerName: "Amount", width: 120 },
    {
      field: "paid",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <StatusOne>
          <div className={params.value === "paid" ? "sta-green" : "sta-red"}>
            {params.value === "paid" ? "Completed" : "Failed"}
          </div>
        </StatusOne>
      ),
    },
    {
      width: 120,
      renderCell: (params) => (
        <StatusOne>
          <img
            src={Download}
            alt="img"
            onClick={() => handleDownloadPDF(params.row._id)}
          />
        </StatusOne>
      ),
    },
  ];
  const [generatePdf, setGeneratePdf] = useState(false);
  const handleDownloadPDF = async (id) => {
    setGeneratePdf(true);
    setBook(id);
    setTimeout(() => {
      handleDownloadCertificate();
    }, 300);
  };
  const handleDownloadCertificate = () => {
    // Temporarily set generatePDF to true to render the Certificate component
    setGeneratePdf(true);

    setTimeout(() => {
      const input = document.getElementById("certificate-content");

      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");

        // Determine the orientation based on the canvas dimensions
        const orientation = "portrait";
        const pdf = new jsPDF(orientation);

        // Set the width and height of the PDF document based on orientation
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("transaction.pdf");
        setGeneratePdf(false);

        // Reset generatePDF state after PDF generation
      });
    }, 500); // Adjust timing as needed to ensure rendering completes
  };
  return (
    <div>
      <DesktopHeader
        title={"Bank Transactions History"}
        isSearchActive={false}
      />

      <DateBox>
        <h1 className="tran-head">Transactions</h1>
        <ResetButtonContainer>
          {fromDate === null || toDate === null ? (
            <ExportButton onClick={() => setOpenDate(!openDate)}>
              <img src={Filter} alt="img" />
              Select date range
            </ExportButton>
          ) : (
            <ExportButton>
              <p onClick={dateOpen}>
                {fromattedDate(fromDate)}-{fromattedDate(toDate)}
              </p>
            </ExportButton>
          )}
          <ExportButton onClick={openDownload}>
            <img src={Download} alt="img" />
            Download
          </ExportButton>
        </ResetButtonContainer>
      </DateBox>

      {openDate ? (
        <div className="date-picker-wrap">
          <div className="date-box">
            <div className="date-box-date">Date Filters</div>
            <div className="date-box-clear" onClick={resetAllFilters}>
              Clear All
            </div>
          </div>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDateRangePicker
                className="date-picker"
                startText="From Date"
                endText="To Date"
                value={[fromDate, toDate]}
                placeholder="From"
                onChange={(newValue) => {
                  handleFromDateChange(newValue[0]);
                  handleToDateChange(newValue[1]);
                }}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <input {...startProps} />
                    <input {...endProps} />
                  </React.Fragment>
                )}
                calendars={1}
              />
              <hr />
            </LocalizationProvider>
          </ThemeProvider>
        </div>
      ) : (
        ""
      )}

      {download ? (
        <ExportButtonsContainer>
          <ExportsButton onClick={() => handleExport("xlsx")}>
            <img src={Excel} alt="img" />
            Export as XLSX
          </ExportsButton>
          <ExportsButton onClick={() => handleExport("csv")}>
            <img src={CSV} alt="img" />
            Export as CSV
          </ExportsButton>
        </ExportButtonsContainer>
      ) : (
        ""
      )}

      <div className={download || openDate ? "bank-admins" : "bank-admin"}>
        <HeaderDownLayout>
          <DataGrid
            sx={{
              height: "60vh",
              borderRadius: "10px",
              color: "#49454F",
              fontSize: "14px",
            }}
            md={{ color: "#49454F", fontSize: "14px" }}
            getRowId={(row) => row._id}
            rows={filteredItems}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
          />
          {generatePdf && (
            <div id="certificate-content">
              {" "}
              <BankTransactionPdf id={book} account={accountDetail} />
            </div>
          )}
        </HeaderDownLayout>
      </div>
    </div>
  );
};
export default BankTransaction;
