/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import InputGroup from "react-bootstrap/InputGroup";
import "./mobileVerification.css";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import "../styles/login.css";
import xalogo from "../images/xakallogo.png";
import "../styles/utils.css";
import backArrow from "../images/arrow-box.svg";
const Get = styled.div`
  color: #8652b6;
  font-family:
    Visby CF,
    sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  cursor: pointer;
`;
const OtpHead = styled.div`
  color: #362a41;
  font-family:
    Visby CF,
    sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0;
  text-align: left;
`;
const MobileVerification = () => {
  const { state } = useLocation();
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [errors, setErrors] = useState(null);
  const [valid, setValid] = useState(false);
  const [verify, setVerify] = useState(false);
  const [enable, setEnable] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [enable2, setEnable2] = useState(false);
  const [enable3, setEnable3] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [enable4, setEnable4] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [spinner, setSpiner] = useState(false);
  const [count, setCount] = useState(5);
  const [disable, setDisable] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useState(null);
  const [error2, setError2] = useState(false);
  const [shareId, setShareId] = useState();
  const [shareInterviewId, setShareInterviewId] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userId, setUserId] = useState();
  // eslint-disable-next-line no-unused-vars
  const [phoneNumberTouched, setPhoneTouched] = useState(false);
  const [verifiedNumber, setVerifiedNumber] = useState();
  const [otp, setOtp] = useState(["", "", "", ""]);
  // eslint-disable-next-line no-unused-vars
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [showVerifyButton, setShowVerifyButton] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [verfifySpinner, setVerifySpiner] = useState(false);
  const history = useHistory();
  const inputRefs = useRef(otp.map(() => React.createRef()));
  console.log(userId, "userId");
  const handlePaste = (e, index) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 4); // Get the pasted content
    const updatedOtp = [...otp];

    // Insert the pasted content into the OTP input fields
    for (let i = 0; i < pastedData.length; i++) {
      if (index + i < otp.length) {
        updatedOtp[index + i] = pastedData[i];
      }
    }

    setOtp(updatedOtp);

    // Check if all OTP digits are entered
    const isAllDigitsEntered = updatedOtp.every((digit) => digit !== "");
    if (isAllDigitsEntered) {
      setValid(true);
    }

    // Focus on the next input field if available
    if (index + pastedData.length < otp.length) {
      inputRefs.current[index + pastedData.length].current.focus();
    }
  };

  const handleOtpChange = (event, index) => {
    const updatedOtp = [...otp];

    // Handle backspace
    if (event.target.value === "" && index > 0) {
      // Clear the current digit only when backspacing
      updatedOtp[index] = "";
      setOtp(updatedOtp);
      setError2(null);
      // Focus on the previous input field
      inputRefs.current[index - 1].current.focus();
      return;
    }

    // Update the current digit
    updatedOtp[index] = event.target.value.slice(0, 1);

    // Update the OTP state
    setOtp(updatedOtp);

    // Clear the error message when any input changes
    setErrors(null);
    setError2(null);
    setShowVerifyButton(false);

    // Check if all OTP digits are entered
    const isAllDigitsEntered = updatedOtp.every((digit) => digit !== "");
    if (isAllDigitsEntered) {
      setShowVerifyButton(true);
    }

    // Check if OTP length is equal to 4
    const fullOtp = updatedOtp.join("");
    if (fullOtp.length === 4) {
      setShowVerifyButton(true);
    } else {
      setShowVerifyButton(false);
    }

    // Focus on the next input field if available
    if (index < otp.length - 1 && event.target.value !== "") {
      inputRefs.current[index + 1].current.focus();
    }
  };
  const sendOtp = async (e) => {
    e.preventDefault();
    setLoadingOtp(true);
    setMinutes(1);
    setSeconds(0);
    setEnable(false);
    if (phoneNumber === "") {
      alert("Enter Your phoneNumber !");
    } else {
      setSpiner(true);
      setEnabled(true);
      const data = {
        phoneNumber: phoneNumber,
        userId: userId,
      };

      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/send-mobile-otp`,
        data
      );
      if (response.status === 200) {
        setSpiner(false);
      } else {
        // toast.error(response.response.data.error);
      }
    }
  };
  const verifyOtp = async (e) => {
    e.preventDefault();

    if (otp.join("").length !== 4) {
      setError2("Invalid OTP");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/verify-mobile-otp`,
        {
          otp: otp.join(""),
          phoneNumber: phoneNumber,
          userId: userId,
        }
      );

      if (response.data.success === true) {
        setVerify(true);
        setEnabled(false);
        setEnable3(false);
        setPhoneTouched({ phoneNumber: "" });
        // setMessage(response.data.message);
        toast.success(response.data.message);
        setVerifiedNumber(phoneNumber);
        setShowVerifyButton(false);
        submitMobile();
      } else if (response.data.verified === true) {
        setEnable3(false);
        setEnable4(true);
      } else {
        // Handling incorrect OTP
        if (count > 1) {
          setCount(count - 1);
          if (count === 2) {
            setError2("Only 1 attempt left.");
          } else {
            setError2("Incorrect OTP");
          }
        } else if (count === 1) {
          // Handling maximum attempts reached
          setMinutes(1);
          setSeconds(0);
          setCount(5);
          setError2("Maximum attempts reached. Try again later.");
          setValid(false);
          setDisable(false);
          setTimeout(() => {
            setError2(null);
          }, 1000);
        }
      }
    } catch (error) {
      setErrors(error.response.data.error);
    }
  };

  const resendOtp = async (e) => {
    e.preventDefault();
    setDisable(true);
    setEnabled(true);
    setMinutes(1);
    setSeconds(0);
    setEnable(false);
    if (phoneNumber === "") {
      alert("Enter Your phoneNumber !");
    } else {
      // setSpiner(true);
      const data = {
        phoneNumber: phoneNumber,
        userId: userId,
      };

      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/resend-mobile-otp`,
        data
      );
      if (response.status === 200) {
      } else {
      }
    }
  };
  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
  };
  useEffect(() => {
    if (state && state) {
      setUserId(state && state.id);
      setShareId(state && state.shareId);
      setShareInterviewId(state && state.shareInterviewId);
    }
  }, [state]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });
  const prefill = (userId) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-about-detail/${userId}`)
      .then((response) => {
        if (response && response.data && response.data.aboutOne) {
          const responseData = response.data && response.data.aboutOne;

          const rawPhoneNumber = responseData.phoneNumber;
          const formattedPhoneNumber = `+${rawPhoneNumber}`; // Format it as E.164
          const verify = responseData.mobileNumberVerified === true;
          setPhoneNumber(formattedPhoneNumber);
          setVerifiedNumber(verify && rawPhoneNumber);
        }
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    if (userId) {
      prefill(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const submitMobile = () => {
    const payload = {
      phoneNumber: phoneNumber,
    };
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/update-about/${userId}`, payload)
      .then((response) => {
        if (response && response.data && response.data.success) {
          if (shareId) {
            history.push(`/portal/shares-apply-job/${shareId}`);
          } else if (shareInterviewId) {
            history.push(`/portal/shares-apply-interview/${shareInterviewId}`);
          } else {
            history.push({
              pathname: "/portal/interview/exploreInterview",
            });
          }
        }
      })
      .catch(() => {});
  };
  return (
    <div className="login-wrapper otp-main">
      <ToastContainer />
      <div className="form-wrapper  wrap-login100 p-b-20">
        <div className="margin-logo">
          <div className="logo-holder">
            <img
              src={xalogo}
              className="login-logo signup-xakal-logo"
              alt="XAKAL"
            />
          </div>
        </div>
        <div className="f-pass-container">
          <div className="w-100">
            <div>
              <span className="let-getStarteds">Welcome to the xakal</span>
            </div>

            <p className="para-div">
              To enhance your experience, we'd love to keep you updated with
              important notification and exclusive offers
            </p>
            <div className="para-div">
              <label className="label-name">Contact Number</label>
              <InputGroup className="mb-1">
                <PhoneInput
                  style={{
                    width: "100%",
                    backgroundColor: "#F4F4F6",
                    border: "none",
                    height: "48px",
                    fontFamily: "Visby Cf,sans-serif",
                    fontSize: "14px",
                    color: "#4F4953",
                    paddingTop: "4px",
                  }}
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  inputProps={{
                    required: true,
                  }}
                  onBlur={() => setPhoneTouched(true)}
                  className="footer-header font"
                />
              </InputGroup>
              {verifiedNumber ? (
                "Mobile No verified"
              ) : (
                <div style={{ marginBottom: "20px" }}>
                  <Get onClick={sendOtp}>
                    {spinner ? <Spinner /> : "Get OTP"}
                  </Get>
                </div>
              )}

              {enabled ? (
                <div>
                  <div className="">
                    <div className="">
                      <OtpHead className="otp mb-2">
                        Please enter the OTP that you received via phone call or
                        SMS to {phoneNumber}
                      </OtpHead>
                    </div>

                    <div className="container-login100-form-btn">
                      <div
                        className="otp-group"
                        style={{ marginLeft: "-90px" }}
                      >
                        {otp.map((digit, idx) => (
                          <input
                            key={idx}
                            type="text"
                            inputMode="numeric"
                            autoComplete="one-time-code"
                            pattern="\d"
                            className="otp-inputs"
                            value={digit}
                            id={`otp-input-${idx}`}
                            ref={inputRefs.current[idx]}
                            onChange={(e) => handleOtpChange(e, idx)}
                            onPaste={(e) => handlePaste(e, idx)}
                            disabled={!disable && seconds}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <p
                    style={{
                      color: "#FF5969",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {errors}
                  </p>

                  {error2 ? (
                    <div style={{ display: "flex", gap: "30px" }}>
                      <p style={{ color: "#FF5969" }} className="f12">
                        {error2}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  {seconds > 0 || minutes > 0 ? (
                    <p
                      style={{
                        color: "#362A41",
                        fontFamily: "Visby CF,sans-serif",
                        fontWeight: "700",
                        fontSize: "14px",
                        textAlign: "left",
                      }}
                    >
                      Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                  ) : (
                    <p
                      className="mt-2"
                      style={{
                        color: "#4F4953",
                        fontFamily: "Visby CF,sans-serif",
                        fontWeight: "500",
                        fontSize: "14px",
                      }}
                    >
                      Didn't recieve the code yet?{" "}
                      <span
                        style={{
                          color: "#8652B6",
                          cursor: "pointer",
                          fontFamily: "Visby CF,sans-serif",
                          fontWeight: "700",
                          fontSize: "14px",
                        }}
                        onClick={resendOtp}
                      >
                        Resend
                      </span>
                    </p>
                  )}
                </div>
              ) : (
                ""
              )}
              {showVerifyButton && (
                <div className="container-login100-form-btn">
                  <button
                    className="button3"
                    onClick={verifyOtp}
                    style={{
                      opacity: showVerifyButton ? "100%" : "50%",
                    }}
                  >
                    VERIFY
                    {verfifySpinner ? (
                      <span>
                        <Spinner />
                      </span>
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              )}
            </div>
            <p>
              By providing your phone number, you agree to receive calls and sms
              updated from us. Your privacy is our priority
            </p>
            <p
              onClick={() => history.push("/privacy-policy")}
              className="para-color"
            >
              Privacy Policy
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileVerification;
