/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./conver.css";
import companyLogo from "../../images/profile-image.svg";
const Conversation = ({ userId, conversation }) => {
  const [user, setUser] = useState();
  const [message, setMessage] = useState([]);
  const [newMessagesCount, setNewMessagesCount] = useState(0);
  const friendId = conversation.members.find((m) => m !== userId);
  const id = conversation._id;
  const getUserChat = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-about-detail/${friendId}`)
      .then((res) => {
        console.log(res);
        setUser(res.data.aboutOne);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getUserChat();
  }, []);
  const getAllMessage = (messageChats) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/message/${messageChats}`)
      .then((res) => {
        setMessage(res.data.message);
        // Calculate new messages count
        const newMessages = res.data.message.filter(
          (msg) => !msg.read && msg.sender === friendId
        );
        setNewMessagesCount(newMessages.length);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    getAllMessage(id);
  }, [id]);

  const friendMessages = message.filter((msg) => msg.sender === friendId);
  const lastMessage =
    friendMessages.length > 0
      ? friendMessages[friendMessages.length - 1]
      : null;

  console.log(friendMessages,"friend");
  return (
    <>
      <div className="conver-div">
        <div className="conver-img-left">
          <img
            src={user?.avatar ? user?.avatar : companyLogo}
            className="conver-img"
            alt="conver"
          />
        </div>
        <div className="conver-details">
          <span className="conver-name">
            {`${user?.firstName} ${user?.lastName}`}
          </span>
          {lastMessage && (
            <div className="last-div-count">
              <div className="conver-last-message">
                <p>{lastMessage?.text}</p>
              </div>
              <div>
                {newMessagesCount > 0 && (
                  <span className="new-messages-count">{newMessagesCount}</span>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Conversation;
