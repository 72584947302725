import React from "react";
import { useState, useEffect } from "react";
import "../styles/login.css";
import "../styles/utils.css";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Common from "../utils/common";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import xalogo from "../images/xakallogo.png";
import { Amplify } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import Profile from "../images/SME PNG.png";
import Profile2 from "../images/Job Seeker PNG.png";
import Profile3 from "../images/Recruiter PNG.png";
import Progress from "./progressBar";
const customStyle = {
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    minHeight: "48px",
    height: "fit-content",
    border: "none",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    fontweight: "normal",
    fontFamily: "Inter-Medium,sans-serif",
  }),
  menu: (provided, state) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
    };
  },
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    "&:hover": {
      color: "#362A41", // Add hover text color
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 6px)",
      marginLeft: "5px",
    },
  }),
  // Add more styles as needed
};

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      // redirectSignIn: "http://localhost:3000/",
      // redirectSignOut: "http://localhost:3000/",
      redirectSignIn: "https://qa.thexakal.com/login",
      redirectSignOut: "https://qa.thexakal.com/login",

      responseType: "token",
    },
  },
});

const OnBoarding = (props) => {
  const [input, setInput] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
  });
  const [userType, setUserType] = useState([]);
  const { state } = useLocation();
  const [email, setEmail] = useState();
  const [shareId, setShareId] = useState();
  const [shareInterviewId, setShareInterviewId] = useState();
  useEffect(() => {
    if (state && state) {
      setInput(state);
      setEmail(state.email);
      setShareId(state && state.shareJobId);
      setShareInterviewId(state && state.shareInterviewId);
    }
  }, [state]);

  // useEffect(() => {
  //   setInput(state && state.input);
  // }, []);

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    skill: "",
    overall: "",
  });

  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [skillcreate, setSkillCreate] = useState("");
  const [optionsOne, setOptionsOne] = useState([]);
  const [skillData, setSkillData] = useState([]);
  const onInputChange = (e) => {
    // Allow only letters (characters)
    const updatedValue = e.target.value.replace(/[^A-Za-z\s]/g, "");

    setInput({
      ...input,
      [e.target.name]: updatedValue,
    });
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "firstName":
          const regex = /^[a-zA-Z\s]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter first name";
          } else if (!regex) {
            stateObj[name] = "Please enter valid name";
          }
          break;

        case "lastName":
          const regex2 = /^[a-zA-Z\s]+$/.test(value);
          if (!value) {
            stateObj[name] = "Please enter last name";
          } else if (!regex2) {
            stateObj[name] = "Please enter valid name";
          }
          break;

        case "":
          if (coreSkill.length > 100) {
            stateObj["skill"] = "You can add only 100 skills";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const [coreSkill, setCoreSkill] = useState([]);

  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };

  const handleSkillChange = (selectedOptions) => {
    const formattedSkills = selectedOptions.map((e) => ({
      skillIds: e.skillIds,
      label: e.label,
      value: e.value,
      ratings: e.ratings,
      averageSkillRating: e.averageSkillRating,
      candidateId: Common.loggedIn(),
    }));
    if (selectedOptions && selectedOptions.length <= 100) {
      setCoreSkill(formattedSkills);
      setSkillCreate("");
      setOptionsOne([]);
    }
  };

  const getSkill = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/skills`)
      .then((response) => {
        const companyValue = response.data.map((e) => ({
          skillIds: e.skillId,
          label: e.label,
          value: e.value,
          ratings: e.ratings,
          averageSkillRating: e.averageSkillRating,
        }));
        setSkillData(companyValue);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const createSkill = () => {
    const payload = {
      name: skillcreate,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.PUBLIC_URL}/xakal/skill`, payload)
        .then((response) => {
          const createdSkill = response.data.newSkill;
          setSkillCreate("");
          setOptionsOne([]);
          getSkill();
          resolve(createdSkill); // Resolve the promise with the created skill data
        })
        .catch((error) => {
          console.error(error);
          reject(error); // Reject the promise with the error
        });
    });
  };

  const handleCreatable = async (e) => {
    if (e[e?.length - 1]?.__isNew__) {
      try {
        const createdSkill = await createSkill(); // Wait for the creation of the skill
        const updatedSkill = {
          ...createdSkill,
          skillIds: createdSkill.skillId,
        };
        setCoreSkill([...coreSkill, updatedSkill]);
        handleSkillChange([...coreSkill, updatedSkill]);
      } catch (error) {
        console.error("Error creating skill:", error);
      }
    } else {
      handleSkillChange(e);
    }
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <></>
      </components.DropdownIndicator>
    );
  };

  useEffect(() => {
    getSkill();
  }, []);

  useEffect(() => {
    if (skillcreate.length > 2) {
      setOptionsOne(
        skillData.map((e) => ({
          skillIds: e.skillIds,
          label: e.label,
          value: e.value,
          ratings: e.ratings,
          averageSkillRating: e.averageSkillRating,
          candidateId: Common.loggedIn(),
        }))
      );
    } else {
      setOptionsOne([]);
    }
  }, [skillData, skillcreate]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const history = useHistory();

  const submitRegister = (e) => {
    e.preventDefault();
    const allFieldsFilled = input?.firstName && input?.lastName;
    if (!allFieldsFilled) {
      setError({ overall: "Please fill all fields" });
    } else if (!/^[a-zA-Z\s]+$/.test(input?.firstName)) {
      setError({ overall: "Enter valid first name" });
    } else if (!/^[a-zA-Z\s]+$/.test(input?.lastName)) {
      setError({ overall: "Enter valid last name" });
    } else if (!userType.length) {
      setError({ overall: "Select atleast one user type" });
    } else if (
      (userType.includes("interviewer") || userType.includes("candidate")) &&
      !coreSkill.length
    ) {
      setError({ overall: "Enter atleast one skill" });
    } else {
      const params = {
        firstName: input.firstName,
        middleName: input.middleName,
        lastName: input.lastName,
        email: email,
        coreSkill: coreSkill,
        userType: userType,
      };

      axios
        .put(
          `${process.env.PUBLIC_URL}/xakal/update-about/${Common.loggedIn()}`,
          params
        )
        .then((response) => {
          history.push({
            pathname: "/mobile-verify",
            state: {
              id: Common.loggedIn(),
              shareId: shareId,
              shareInterviewId: shareInterviewId,
            },
          });
        })
        .catch((err) => err.error);
    }
  };
  useEffect(() => {
    document.title = "On-Boarding | Xakal";
  }, []);

  const userTypeChange = (e, value) => {
    if (e.target.checked) {
      setUserType([...userType, value]);
    } else {
      // remove from list
      setUserType(userType.filter((people) => people !== value));
    }
  };
  const userTypeChanges = (value) => {
    if (userType.includes(value)) {
      setUserType(userType.filter((type) => type !== value));
    } else {
      setUserType([...userType, value]);
    }
  };
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const calculateProgress = () => {
      // Calculate progress based on current page and total number of pages
      // For simplicity, let's assume we have 3 pages and progress increases by 33.33% on each page
      const currentPage = window.location.pathname;
      const totalPages = 3;
      const currentPageIndex = [
        "/signup",
        "/otp-verification",
        "/on-boarding",
      ].indexOf(currentPage);
      const newProgress = (currentPageIndex + 1) * (100 / totalPages);
      setProgress(newProgress);
    };

    calculateProgress();
  }, []);

  return (
    <div>
      <ToastContainer />
      <div className="login-wrapper signup-main">
        <div className="form-wrapper wrap-login100 p-b-20">
          <div className="margin-logo">
            <div className="logo-holder">
              <Progress progress={progress} />
              <img
                src={xalogo}
                className="login-logo signup-xakal-logo"
                alt="XAKAL"
              />
            </div>
            <div className="lets-getStarted">On-Boarding</div>
          </div>
          <form className="form-class">
            <div className="">
              <div>
                <p className="textreg font-face-gm">
                  First Name<span className="text-danger">*</span>
                </p>

                <div className={"wrap-input100"}>
                  <input
                    autoComplete="off"
                    className="input101"
                    type="text"
                    name="firstName"
                    placeholder="Enter your first name here"
                    value={input?.firstName}
                    onChange={onInputChange}
                    onBlur={validateInput}
                  />
                </div>
                <div style={{ position: "relative", marginBottom: "1rem" }}>
                  {error.firstName && (
                    <span className="text-danger f12">{error.firstName}</span>
                  )}
                </div>
                <p className="textpass font-face-gm">
                  Last Name<span className="text-danger">*</span>
                </p>
                <div className={"wrap-input100"}>
                  <input
                    autoComplete="off"
                    type="text"
                    className={"input101"}
                    name="lastName"
                    placeholder="Enter your last name here"
                    value={input?.lastName}
                    onChange={onInputChange}
                    onBlur={validateInput}
                  />
                </div>
                <div style={{ position: "relative", marginBottom: "1rem" }}>
                  {error.lastName && (
                    <span className="text-danger f12">{error.lastName}</span>
                  )}
                </div>

                <p className="textpass font-face-gm">
                  Select User Type<span className="text-danger">*</span>
                </p>

                <div className="m-b-20 onboard-wrapper">
                  <div
                    className={`onboard-usertype  ${
                      userType.includes("hr") ? "selected" : "unselect"
                    }`}
                    onClick={() => userTypeChanges("hr")}
                  >
                    <img src={Profile} alt="img" />
                    <div className="onboard-input">
                      <Checkbox
                        checked={input.read}
                        inputProps={{ "aria-label": "controlled" }}
                        id="hr"
                        name="hr"
                        onChange={(e) => {
                          userTypeChange(e, "hr");
                        }}
                        value={userType}
                      />
                    </div>
                    <label for="hr">
                      {" "}
                      Are you a recruiter looking for candidates?{" "}
                    </label>
                  </div>

                  <div
                    className={`onboard-usertype  ${
                      userType.includes("interviewer") ? "selected" : "unselect"
                    }`}
                    onClick={() => userTypeChanges("interviewer")}
                  >
                    <img src={Profile2} alt="img" />
                    <div className="onboard-input">
                      <Checkbox
                        checked={input.read}
                        inputProps={{ "aria-label": "controlled" }}
                        onChange={(e) => {
                          userTypeChange(e, "interviewer");
                        }}
                        value={userType}
                        type="checkbox"
                        id="interviewer"
                        name="interviewer"
                      />
                    </div>
                    <label for="interviewer">
                      {" "}
                      Are you a SME looking to take interviews and earn?{" "}
                    </label>
                  </div>

                  <div
                    className={`onboard-usertype  ${
                      userType.includes("candidate") ? "selected" : "unselect"
                    }`}
                    onClick={() => userTypeChanges("candidate")}
                  >
                    <img src={Profile3} alt="img" />
                    <div className="onboard-input">
                      <Checkbox
                        checked={input.read}
                        inputProps={{ "aria-label": "controlled" }}
                        onChange={(e) => {
                          userTypeChange(e, "candidate");
                        }}
                        value={userType}
                        type="checkbox"
                        id="candidate"
                        name="candidate"
                      />
                    </div>
                    <label for="candidate">
                      {" "}
                      Are you a candidate looking for jobs?
                    </label>
                  </div>
                </div>

                {/* <div className="m-b-20 onboard-wrapper">
                <div className="onboard-usertype">
                  <img src={Profile3} alt="img" />
                  <input type="checkbox" id="" name="uidesign" value="uidesign" className="onboard-input"/>
                  <label for="vehicle1"> UI Researcher</label>
                </div>
                <div className="onboard-usertype">
                  <img src={Profile2} alt="img" />
                  <input type="checkbox" id="" name="uidesign" value="uidesign" className="onboard-input"/>
                  <label for="vehicle1"> UX Writter</label>
                </div>
                <div className="onboard-usertype">
                  <img src={Profile} alt="img" />
                  <input type="checkbox" id="" name="uidesign" value="uidesign" className="onboard-input"/>
                  <label for="vehicle1"> Business Analytics</label>
                </div>
                </div> */}

                <p className="textpass font-face-gm">Skills</p>
                <div className="m-b-20">
                  <CreatableSelect
                    isMulti
                    noOptionsMessage={() => null}
                    isValidNewOption={() => optionsOne.length}
                    styles={customStyle}
                    menuPlacement="top"
                    components={{ Placeholder, DropdownIndicator }}
                    className="font"
                    name="skill"
                    placeholder="Select your skills"
                    onChange={(e) => handleCreatable(e)}
                    onInputChange={(e) => setSkillCreate(e)}
                    options={optionsOne}
                    value={coreSkill}
                    onBlur={validateInput}
                  />
                </div>
                <div style={{ position: "relative", marginBottom: "1rem" }}>
                  {!coreSkill.length && (
                    <span className="text-danger f12">{error.skill}</span>
                  )}
                </div>

                <div style={{ position: "relative", marginBottom: "1rem" }}>
                  {error.overall && (
                    <span className="text-danger f12">{error.overall}</span>
                  )}
                </div>

                <div className="container-login100-form-btn">
                  <button
                    type="submit"
                    className="login100-form-btn"
                    onClick={submitRegister}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OnBoarding;
