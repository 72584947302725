/* eslint-disable react-hooks/exhaustive-deps */
// Clock.js

import React, { useEffect, useState } from "react";
import axios from "axios";
import { CircularProgress, Typography, Box, Dialog } from "@mui/material";
import useCountDown from "react-countdown-hook";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";

const Clock = ({ totalTime, timeRemaining }) => {
  const [timeLeft, actions] = useCountDown(timeRemaining, 1000);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [valueCP, setValueCP] = useState(100);
  const [clockColor, setClockColor] = useState("secondary");
  const location = useLocation();
  const candidateId = location.state && location.state.candidateId;

  const candidate = location.state && location.state.candidate;
  //   const valueCP = (timeLeft / totalTime) * 100;
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState();
  const [openNew, setOpenNew] = useState(false);
  const [scoreId, setScoreId] = useState();
  const [attemptQuestion, setTotalAnswerAttempt] = useState();
  useEffect(() => {
    actions.start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigate = useHistory();

  useEffect(() => {
    const valueCPIn = (timeLeft / totalTime) * 100;

    const timeInSec = timeLeft / 1000;
    const hoursIn = Math.floor(timeInSec / 3600);
    const minutesIn = Math.floor((timeInSec - hoursIn * 3600) / 60);
    const secondsIN = timeInSec - hoursIn * 3600 - minutesIn * 60;

    setValueCP(valueCPIn);
    setHours(hoursIn);
    setMinutes(minutesIn);
    setSeconds(secondsIN);

    if (timeInSec !== 0 && timeInSec % 5 === 0) {
      // console.log(timeInSec);
      localStorage.setItem("timeRemaining", timeInSec);
    }

    if (timeInSec <= 120 && timeInSec !== 0) {
      setClockColor("error");
    }

    if (timeInSec === 1) {
      updateClockToDb(timeInSec);
    }

    if (timeInSec === 1) {
      localStorage.setItem("timeRemaining", timeInSec);
      if (scoreId) {
        openId();
      } else {
        openAnswer();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  const updateClockToDb = (userTimerInBrowser) => {
    const payload = {
      timeLeft: userTimerInBrowser,
    };
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/create-time`, payload)
      .then((res) => {})
      .catch((err) => {
        console.error("Error updating time in database:", err);
      });
  };
  const getResults = () => {
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/get-result-general/${candidateId}`)
      .then((res) => {
        const id = res.data.score._id;
        setScoreId(id);
        setTotalAnswerAttempt(res.data.attemptedQuestions);
      })
      .catch(() => {});
  };
  const openTrue = () => {
    setOpenNew(true);
  };
  const openAnswer = () => {
    if (candidate) {
      openTrue();
      getResults();
    } else {
      setOpen(true);
    }
  };

  const openId = () => {
    if (scoreId) {
      openTrue();
      getOneResult(scoreId);
      getUpdateFirstResult();
    } else {
      setOpen(true);
    }
  };
  const getUpdateResults = () => {
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/update-general-aptitude/${scoreId}`)
      .then((res) => {
        const marks = res.data.aptitudes.marks;
        navigate.push({
          pathname: "/portal/aptitude",
          state: {
            candidate: candidate,
            marks: marks,
          },
        });
      })
      .catch(() => {});
  };
  const getUpdateFirstResult = () => {
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/update-general-aptitude/${scoreId}`)
      .then((res) => {
        setTotalAnswerAttempt(res.data.aptitudes.attemptedQuestion);
      })
      .catch(() => {});
  };
  const getOneResult = (id) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-general-aptitude/${id}`)
      .then((res) => {
        setTotalAnswerAttempt(res.data.aptitudes.attemptedQuestion);
      })
      .catch(() => {});
  };
  useEffect(() => {
    if (scoreId) {
      getOneResult(scoreId);
    }
  }, []);
  const handleFinal = () => {
    // getResult is executed immediately

    // Execute handleAnswerSubmit after a 1-second delay
    setTimeout(() => {
      if (candidate) {
        getUpdateResults();
      }
    }, 1000);
  };
  return (
    <>
      <Box
        sx={{ position: "relative", display: "inline-flex", padding: "2rem" }}
      >
        <CircularProgress
          color={clockColor}
          variant="determinate"
          value={valueCP}
          size={140}
          thickness={4.5}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" component="div" color={clockColor}>
            {hours < 10 ? "0" + hours : hours} :
            {minutes < 10 ? "0" + minutes : minutes} :
            {seconds < 10 ? "0" + seconds : seconds}
          </Typography>
        </Box>
      </Box>
      <Dialog open={openNew}>
        <div className="modalHeader sub-apt">Submit Aptitude</div>
        <div className="modalBody">
          <p>
           Oops its timeout and your answer is submitted automatically.
          </p>
          <div className="mTitle">Quiz Summary</div>
          <div className="mTitle">
            {attemptQuestion}/20 <span>Attempted</span>
          </div>
          <div className="mTitle">
            <div style={{ flexGrow: 1 }}>
              {/* Add your thin progress bar here */}
              <div
                style={{
                  height: "4px", // Adjust height as needed
                  backgroundColor: "#ccc", // Adjust background color as needed
                  width: "100%", // Bar takes full width
                  borderRadius: "2px", // Rounded corners
                  marginRight: "8px", // Spacing between bar and label
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: `${(attemptQuestion / 20) * 100}%`, // Calculate width based on attemptQuestion
                    backgroundColor: "#4caf50",
                    borderRadius: "2px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modalFooter">
          {/* <button className="button5" onClick={() => setOpenNew(false)}>
            Cancel
          </button> */}
          <button className="button3" onClick={handleFinal}>
            Submit
          </button>
        </div>
      </Dialog>
    </>
  );
};

export default Clock;
